import { useDispatch, useSelector } from 'react-redux'
import CloseIcon from '../../../icons/closeicon'
import classes from './SelectedChoice.module.css'
import { productChoicesActions } from '../../../store/productChoices'
import { productDetailsActions } from '../../../store/productDetails'


function removeOpenedDrawer(dataToSearch) {
    dataToSearch.forEach(item => item.drawerOpened = false)
    dataToSearch.forEach(element => {
        element.options.forEach(option => {
            if (option.modifiers) {  
                removeOpenedDrawer(option.modifiers)                 
            }
        });
    });
}
function searchForId(dataToSearch, modifierId) {
    let foundIndex = dataToSearch.findIndex(x => x.id === modifierId); 

    removeOpenedDrawer(dataToSearch)
    if (foundIndex >=0 ) {
        dataToSearch[foundIndex].drawerOpened = true
    } else {        
        dataToSearch.forEach(element => {
            element.options.forEach(option => {
                if (option.modifiers) {
                    searchForId(option.modifiers, modifierId)                   
                }
            });
        });
    }

    return dataToSearch
}

const SelectedChoiceOption = ({option, parent}) => {
    const dispatch = useDispatch()

    const productDetails = useSelector(state => state.productDetails.data)
    let data = JSON.parse(JSON.stringify(productDetails))
    // const productChoices = useSelector(state => state.product.product)
    // const itemIndex = useSelector(state => state.product.openedOptions)
    // const item = productChoices.find(item => item.choice_id == parent.choice_id)
    // const index = productChoices.indexOf(item)

    // const removeChoiceHandler = () => {
    //     console.log('remove choice')
    //     console.log(option)
    //     console.log(parent)
    //     dispatch(productChoicesActions.removeSelectedChoice({
    //         parent_id: parent.choice_id,
    //         id: option.id
    //     }))
    // }
    const goToAccordionItem = () => {    
        const modifiedData = searchForId(data, parent.id)

        dispatch(productDetailsActions.createProduct(modifiedData))
        dispatch(productDetailsActions.builderAccordionTriggerMark())    
        // dispatch(productChoicesActions.setOpenedoption(index))
        // dispatch(productChoicesActions.closeMobileSelectedChoices())
    }
    return <>
        <button onClick={goToAccordionItem} className={classes.choice_picked}>
            <span className={classes.choice_picked_title}>{option.name}</span>
        </button>
        {/* <div className={classes.choice_picked}>
            <span className={classes.choice_picked_title}>{option.name}</span>
            <button onClick={removeChoiceHandler}>
                <CloseIcon />
                <span className='sr-only'>remove choice</span>
            </button>
        </div> */}
    </>
}

export default SelectedChoiceOption