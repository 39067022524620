import { useEffect, useState } from "react"
import { useDispatch, useSelector } from 'react-redux';
import { cartActions } from "../../store/cart";
import classes from './PickupPannel.module.css'

const Curbside = ({curbside}) => {
    const dispatch = useDispatch()
    let vehicleInfo = useSelector(state => state.cart.vehicleInfo)

    let make = vehicleInfo?.vehicleMake
    let model = vehicleInfo?.vehicleModel
    let color = vehicleInfo?.vehicleColor

    // const [isChecked, setIsChecked] = useState(false)
    const cart = useSelector(state => state.cart.cart)

    if (cart && cart.deliverymode === "curbside" && cart.customfields) {
        make = cart.customfields.find(item => item.label==="Make")?.value
        model = cart.customfields.find(item => item.label==="Model")?.value
        color = cart.customfields.find(item => item.label==="Color")?.value
    } 


    const [isCurbside, setIsCurbside] = useState(false)
    const makeItCurbsideHandler = (e) => {
        setIsCurbside(e.target.checked)
        curbside(e.target.checked)
        // setIsCurbside(prev => prev = !prev)
    }

    useEffect(() => {
        if (cart && cart.deliverymode === "curbside") {
            setIsCurbside(true)
        }        
    }, [])
    
    const handleChange = (e) => {   
        dispatch(cartActions.updateVehicleinfo({
            handle: e.target.name,
            value: e.target.value
        }))
    }
    
    return <div className={classes.curbisde_pannel}>
        <div className='form-container form-group-checkbox'>
            {isCurbside && <input type="checkbox" id="changeToPickup" name="changeToPickup" onChange={makeItCurbsideHandler}  defaultChecked={true} />}
            {!isCurbside && <input type="checkbox" id="changeToPickup" name="changeToPickup" onChange={makeItCurbsideHandler}  defaultChecked={false} />}
            <label htmlFor="changeToPickup" className="label-regular-font">Curbside Pickup</label>
        </div>
        {isCurbside && <div className={`form-fields-container ${classes.curbside}`}>
            <div className="form-controller">
                <label className="label" htmlFor="vehicleMake">Vehicle make</label>
                <input id="vehicleMake" defaultValue={make} name="vehicleMake" type="text" placeholder="e.g. Chevrolet, Toyota" onChange={handleChange}/>
            </div>
            <div className="form-controller-group">
                <div className="form-controller form-controller-1">
                    <label className="label" htmlFor="vehicleModel">Vehicle model</label>
                    <input id="vehicleModel" defaultValue={model} name="vehicleModel" type="text" placeholder="e.g. Silverado" onChange={handleChange}/>
                </div>
                <div className="form-controller form-controller-1">
                    <label className="label" htmlFor="vehicleColor">Vehicle color</label>
                    <input id="vehicleColor" defaultValue={color} name="vehicleColor" type="text" placeholder="e.g. Red, Blue" onChange={handleChange}/>
                </div>
            </div>
        </div>}
    </div>
}

export default Curbside