import { useEffect, useState } from "react"
import { getUserRewards } from "../../scripts/fetchApi"
import { getAuthToken } from "../../scripts/auth"
import classes from './AccountLanding.module.css'
import { Link } from "react-router-dom"
import { useDispatch } from "react-redux"
import { variousActions } from "../../store/various"
import RewardsItem from "./RewardsItem"

const Rewards = () => {
    const dispatch = useDispatch()
    const [rewards, setRewards] = useState(null)
    const [loading, setLoading] = useState(true)

    // const token = getAuthToken()
    // const token = sessionStorage.getItem('token')

    // const token = getGenericToken()
    const token = sessionStorage.getItem('token')
    const olotoken = sessionStorage.getItem('olotoken')

    useEffect(() => {
        const getRewards = async () => {
            const rewards = await getUserRewards(token ? token : olotoken, token ? null : 'oloauth')

            
            if (rewards.message) {
                dispatch(variousActions.setError(rewards))     
                setLoading(false)
            } else {
                setLoading(false)
                setRewards(rewards)
            }
        }

        getRewards()
    }, [token])

    return <div className="container-full">
        <div className={classes.wrapper}>
            <div className={classes.back_to_container}>
                <Link to="/account">
                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"><path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/></svg>
                    Back To Account
                </Link>
            </div>
            <h1>Available Rewards</h1>
            
            <div className={classes.favorite_orders}>
                {loading && <div className='preparing_restaurant_loader'></div>}
                {rewards && !loading && rewards.total_points && <>You currently have {rewards.point_balances} redeemable points (out of {rewards.total_points} lifetime points).</>}
                {rewards && !loading && rewards.reward_balances && rewards.reward_balances.length > 0 && <div className={`grid-5`}>
                    {rewards.reward_balances.map(item => (
                        <RewardsItem key={item.reward_id} item={item}/>
                    ))}
                </div>}
                {!rewards && !loading && <div>
                    <p>There are no rewards</p>
                </div>}
            </div>
        </div>        
    </div>
}

export default Rewards