import classes from './ModifierTitle.module.css'
import OptionsHeaderText from '../products_details_v2/OptionsHeaderText'
import ArrowUpIcon from '../../../icons/arrowupicon'
import { useEffect, useRef, useState } from 'react'
import Badge from './Badge'
import { useSelector } from 'react-redux'

const ModifierTitle = ({modifier, setDrawer, setDrawerClosed}) => {
    const optionRef = useRef(null)
    const [drawerChanged, setDrawerChanged] = useState(null)
    const [drawerState, setDrawerState] = useState(true)

    const flag = useSelector(state => state.productDetails.accordionTrigger)

    const openDrawerHandler = () => {
        // setDrawerState(p => p = !p)

        // console.log('drawerState:::: ', drawerState)
        if (!modifier.drawerOpened) {
            setDrawer() 
            setDrawerChanged(Math.random()) 
        } else {
            setDrawerClosed() 
        }
              
    }

    useEffect(() => {
        if (modifier.drawerOpened) {
            optionRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
    }, [drawerChanged, flag])



    return <button className={`option-header ${classes.options_item_header}`} onClick={openDrawerHandler} ref={optionRef}>
         {/* ${(itemIndex === index) && classes.trigger_open} */}
        <div className={classes.options_item_header_text}>
            {modifier.itemSelected && <Badge />}  
                
            {/* id={data.id} */}
            <h3>
                <span className={`header-index ${classes.item_number}`}>1</span> 
                <OptionsHeaderText text={modifier.description}/>
                {modifier.maxselects && !modifier.maxaggregatequantity && <span className={classes.options_item_header_helper}> Select up to {modifier.maxselects}</span>}
                {modifier.maxselects && modifier.maxaggregatequantity && <span className={classes.options_item_header_helper}> Select a total of {modifier.maxselects}</span>} 
            </h3>
            
        </div>
        <span className={classes.options_item_header_arrow}>
            <ArrowUpIcon />
        </span>
    </button>
    // return <div className={`${classes.options_item_header}`}>
    //     <div className={classes.options_item_header_text}>
    //         <h3>
    //             <OptionsHeaderText text={modifier.description}/>
    //             {modifier.maxselects && !modifier.maxaggregatequantity && <span className={classes.options_item_header_helper}> Select up to {modifier.maxselects}</span>}
    //             {modifier.maxselects && modifier.maxaggregatequantity && <span className={classes.options_item_header_helper}> Select a total of {modifier.maxselects}</span>} 
    //         </h3>
            
    //     </div>
    // </div>
}

export default ModifierTitle