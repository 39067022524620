import { useEffect, useState } from "react"
import { useMediaQuery } from "../../../hooks/hooks"
import productTitles from "../../../scripts/abreviatedTitles"

const OptionsHeaderText = ({text}) => {
    const [hasMobileTitle, setHasMobileTitle] = useState(false)
    const [mobileText, setMobileText] = useState('')
    const screen768andlarger = useMediaQuery('md')
    useEffect(() => {
        productTitles.forEach(item => {
            if (item.title === text) {
                setHasMobileTitle(true)
                setMobileText(item.mobile)
            }
        })
    })
    return <>
        {screen768andlarger && text}
        {!screen768andlarger && <>
            {hasMobileTitle && mobileText}
            {!hasMobileTitle && text}
        </>}
    </>
}

export default OptionsHeaderText