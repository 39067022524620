import { useDispatch, useSelector } from "react-redux"
import { cartActions } from "../../../store/cart"
import classes from '../Cart.module.css'
import { useState } from "react"


const OrderStep = () => {
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()

    const sidebarStep = useSelector(state => state.cart.cartSidebarStep)

    const goToNextStep = async (step) => {
        setIsLoading(true)
        dispatch(cartActions.setCartSidebarStep(step))
        setIsLoading(false)
    }


    return <div className={`${classes.cart_timeline_item} ${sidebarStep === "order" && classes.active} ${(sidebarStep === "info" || sidebarStep === "checkout") && classes.complete}`}>
       <button onClick={goToNextStep.bind(this, "order")} disabled={sidebarStep === "order"}>
            <span className={classes.number}>
                <span>
                    {!isLoading && 1}
                    {isLoading && <div className="loader dark-raspberry"></div>}
                </span>
                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" fill="#fff"/></svg>
                
            </span>
            Your Order
        </button>
        
    </div>
}

export default OrderStep