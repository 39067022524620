import { useEffect, useState } from "react";
import TolltipIcon from "../../../icons/tooltipicon"
import classes from "./Option.module.css";
import TailIcon from "../../../icons/tailicon";

const OptionTooltip = ({data}) => {
    const [showTooltip, setShowTooltip] = useState(false)
    const openTooltipHandler = () => {
        setShowTooltip(p => p = !p)
    }

    useEffect(() => {
        if (showTooltip) {
            setTimeout(() => {
                setShowTooltip(false)
            }, 4000);
        }
    }, [showTooltip])

    return <div className={classes.tooltip_container}>
        {showTooltip && <div className={classes.tooltip_modal}>
            <div className={classes.tooltip_modal_inner}>
                <p>{data.value}</p>
            </div>
            <div className={classes.tail_icon}><TailIcon /></div>
        </div>}
        <button className={classes.tooltip_button} onClick={openTooltipHandler}>
            <TolltipIcon />
            <span className="sr-only">open tooltip</span>
        </button>
        
    </div>
}

export default OptionTooltip