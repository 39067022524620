import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { authActions } from "../../store/auth"
import { useNavigate } from "react-router-dom"
import { cartActions } from "../../store/cart"

const AccountWrapper = ({children}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {

        const token = sessionStorage.getItem('token')
        const olotoken = sessionStorage.getItem('olotoken')

        if (!token && !olotoken) {
            sessionStorage.removeItem('currentCart')
            dispatch(cartActions.addCart(null))  
            dispatch(authActions.logout())
            navigate(`/signin`)
        }

    }, [])
    

    return <>{children}</>
}

export default AccountWrapper