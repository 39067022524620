export const setStorageItem = (key, value, ttl) => {
    const now = new Date()
    let item ={}
    if (ttl !== null) {
        item = {
            value: value,
            expiry: now.getTime() + (ttl * 1000),
        }
    } else {
        item = {
            value: value,
        }
    }
    
    
    localStorage.setItem(key, JSON.stringify(item));
}

export const getItemFromStorage = (key) => {
	const itemStr = localStorage.getItem(key)

	// if the item doesn't exist, return null
	if (itemStr === null || itemStr === undefined) {
		return null
	}
	const item = JSON.parse(itemStr)
	const now = new Date()
	// compare the expiry time of the item with the current time

    if (item.expiry) {
        if (now.getTime() > item.expiry) {
            // If the item is expired, delete the item from storage
            // and return null
            localStorage.removeItem(key)
            return null
        }
    }
	
	return item.value
}

export const deleteItemFromStorage = (key) => {
	localStorage.removeItem(key);	
}



export const setSessionStorageItem = (key, value, ttl) => {
    const now = new Date()
    let item ={}
    if (ttl !== null) {
        item = {
            value: value,
            expiry: now.getTime() + (ttl * 1000),
        }
    } else {
        item = {
            value: value,
        }
    }
    
    
    sessionStorage.setItem(key, JSON.stringify(item));
}

export const getItemFromSessionStorage = (key) => {
	const itemStr = sessionStorage.getItem(key)

	// if the item doesn't exist, return null
	if (itemStr === null || itemStr === undefined) {
		return null
	}
	const item = JSON.parse(itemStr)
	const now = new Date()
	// compare the expiry time of the item with the current time

    if (item.expiry) {
        if (now.getTime() > item.expiry) {
            // If the item is expired, delete the item from storage
            // and return null
            sessionStorage.removeItem(key)
            return null
        }
    }	
	return item.value
}

export const deleteItemFromSessionnStorage = (key) => {
	sessionStorage.removeItem(key);	
}