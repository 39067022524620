import { useRef, useState } from "react"
import PromoCode from "./PromoCode"

import classes from './AlternativePayment.module.css'
import Rewards from "./Rewards"
import { useSelector } from "react-redux"
import { CSSTransition } from "react-transition-group"
import CirclePlusIcon from "../../icons/circleplusicon"
import CircleMinusIcon from "../../icons/circleminusicon"

const AlternativePayment = () => {
    const [showMethods, setShowMethods] = useState(false)  
    const nodeRef = useRef(null)

    const user = useSelector(state => state.auth.user)

    const showMethodsHandler = () => {
        setShowMethods(prevState => prevState = !prevState)
    } 


    return <div className={classes.alternative_container}>
        <button onClick={showMethodsHandler} className="btn-underline btn-underline-red btn-underline-static">
            {!showMethods && <CirclePlusIcon />}
            {showMethods && <CircleMinusIcon />}
            Use Promo/Rewards
        </button>
        <CSSTransition
            in={showMethods}
            nodeRef={nodeRef}
            timeout={300}
            classNames="rewards"
            unmountOnExit  
        >
            <div className={classes.alternative_container_inner} ref={nodeRef}>         
                <div className={classes.alternative_container_inner_wrapper}>
                    {user && <Rewards />}
                    <PromoCode />
                </div>   
                
            </div>
        </CSSTransition>
    </div>
}

export default AlternativePayment