import { useEffect, useState, useRef } from 'react'
import classes from './SpecialInstructionsBuilder.module.css'
import { useSelector } from 'react-redux';
import CustomMessage from './CustomMessage';
import CustomDesign from './CustomDesign';

const SpecialInstructionsBuilder = ({specialinstructionsText}) => {
    // const options = useSelector(state => state.product.product)
    const productDetails = useSelector(state => state.productDetails.data)


    const [activeFields, setActiveFields] = useState({
        message: false,
        design: false
    })

    useEffect(() => {
        productDetails.forEach(mod => {
            if (mod.description.toLowerCase().includes("custom")) {
                if (mod.itemSelected) {
                    if (mod.options.length > 0) {
                        if (mod.options[0].itemSelected && mod.options[0].itemSelected === true) {
                            setActiveFields({
                                message: true,
                                design: false
                            })
                        } else if (mod.options[1].itemSelected && mod.options[1].itemSelected === true) {
                            setActiveFields({
                                message: true,
                                design: true
                            })
                        } else {
                            setActiveFields({
                                message: false,
                                design: false
                            })
                        }
                    }
                } else {
                    setActiveFields({
                        message: false,
                        design: false
                    })
                }
            }
        });
    }, [productDetails])


    return <>
        {(activeFields.message || activeFields.design) && <div className={`${classes.options_item} ${classes.options_item_customize}`} >
            <div className={classes.options_item_header}>
                <h3>Cake Customization:</h3>
            </div>
            {(activeFields.message || activeFields.design) && <CustomMessage text={specialinstructionsText.message}/>}
            {activeFields.design && <CustomDesign text={specialinstructionsText.design}/>}
            
        </div>}
    </>
}

export default SpecialInstructionsBuilder