import ProductModifier from "./ProductModifier"

const ProductModifiers = ({modifiers}) => {

    return <div>
        {modifiers && modifiers.map(modifier => (
            <ProductModifier key={modifier.id} modifier={modifier}/>
        ))}
    </div>
}

export default ProductModifiers