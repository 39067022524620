import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getuserInfo } from "../scripts/fetchApi";
import { variousActions } from "../store/various";

import { getItemFromStorage } from "../scripts/localStorage";
import { locationActions } from "../store/location";

export const sizes = {
    xsm: "480px",
    sm: "640px",
    md: "768px",
    md800: "800px",
    md841: "841px",
    md900: "900px",
    md1023: "1023px",
    lg: "1024px",
    xl: "1280px",
    "2xl": "1536px",
};

export const useMediaQuery = (screen) => {
    const [matches, setMatches] = useState(false);

    useEffect(() => {
        const query = `(min-width: ${sizes[screen]})`;
        const media = window.matchMedia(query);
        if (media.matches !== matches) {
            setMatches(media.matches);
        }
        const listener = () => setMatches(media.matches);
        window.addEventListener("resize", listener);
        return () => window.removeEventListener("resize", listener);
    }, [matches, screen]);

    return matches;
};

export const useGetUser = (token) => {
    const dispatch = useDispatch()
    const [user, setUser] = useState(null);

    useEffect(() => {
        (async () => {
            if (!token) {
                // dispatch(variousActions.setError({message: 'Invalid token, please sign in again'}))
                return false
            } else {
                const user = await getuserInfo(token)
                if (user.id) {
                    setUser(user)
                } else {
                    dispatch(variousActions.setError(user))
                }
            }
            
        })()
    }, [dispatch, token])

    return user
}



export const isElementXPercentInViewport = function (el, percentVisible) { 
    let
        rect = el.getBoundingClientRect(),
        windowHeight = (window.innerHeight || document.documentElement.clientHeight);

    return !(
        Math.floor(100 - (((rect.top >= 0 ? 0 : rect.top) / +-rect.height) * 100)) < percentVisible ||
        Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) < percentVisible
    )
};

export const animElements = (elemntsAnim) => {
    if (elemntsAnim.length > 0) {
        elemntsAnim.forEach(item => {
            var el = document.querySelectorAll(item.elem);
            if (el.length > 0) {
                // window.addEventListener('load', (e) => {
                    el.forEach(element => {
                        if (isElementXPercentInViewport(element, item.percentage)) {
                            element.classList.add('in_view');
                        }
                    })
                // });
                window.addEventListener('scroll', (e) => {
                    el.forEach(element => {
                        if (isElementXPercentInViewport(element, item.percentage)) {
                            element.classList.add('in_view');
                        } else {
                            if (item.infinite) {
                                element.classList.remove('in_view');
                            }
                        }
                    })
                });
            }
        });
    }
}


var options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: Infinity,
};


function errors(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
}

export const useGetLocation = () => {
    const [location, setLocation] = useState(false);

    function success(pos) {
        var crd = pos.coords;
        setLocation({
            lat: crd.latitude,
            lng: crd.longitude
        })
    }

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.permissions
            .query({ name: "geolocation" })
            .then(function (result) {
                if (result.state === "granted") {
                    setLocation(navigator.geolocation.getCurrentPosition(success, errors, options));
                } else if (result.state === "prompt") {
                    setLocation(navigator.geolocation.getCurrentPosition(success, errors, options));
                } else if (result.state === "denied") {
                    setLocation({message: 'denied'})
                }
            });
        } else {
            setLocation({message: "Geolocation is not supported by this browser."});
        }
    }, []);

    return location;
};



// geocode logic

function deg2rad(deg) {
    return deg * (Math.PI / 180);
}
export const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
    var R = 6371; // Radius of the earth in km
    var dLat = deg2rad(lat2 - lat1); // deg2rad below
    var dLon = deg2rad(lon2 - lon1);
    var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) *
            Math.cos(deg2rad(lat2)) *
            Math.sin(dLon / 2) *
            Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    // console.log('distance: ', d)
    return d;
}


// geodecode address 
// export const geoCodeThis = (element) => {

//     var radius = 50;
//     // var inputAddress = document.getElementById(element).value;   
//     const inputAddress = element.value; 

//     const geocoder = new window.google.maps.Geocoder();
//     let lat = "";
//     let lng = "";
//     const geo = async () => {
//         await geocoder.geocode(
//             {
//                 // address: "inputAddress " + inputAddress,
//                 address: inputAddress,
//                 componentRestrictions: {
//                     country: "US",
//                 },
//             },
//             function (results, status) {
                
//                 if (status == window.google.maps.GeocoderStatus.OK) {
//                     // console.log('no errors')
//                     lat = results[0].geometry.location.lat();
//                     lng = results[0].geometry.location.lng();
//                 } else {
//                     console.log('We are sorry but we could not locate your inputAddress. Please try a different one.')
//                     // err.style.display = 'block'
//                 }
//             }
//         );

//         return { lat: lat, lng: lng };
//     };

//     (async () => {
//         var latLng = await geo();

//         console.log(latLng);
//     })();
// }


export const UpdateStore = () => {
    const dispatch = useDispatch()
    const storage = getItemFromStorage('currentLocation')
    if (storage) {
        dispatch(locationActions.setLocationItem(storage.currentLocation))
    }
    const menu = getItemFromStorage('currentMenu').menu
    if (menu) {
        dispatch(locationActions.setMenuItem(menu))
    }
    console.log('storage in hooks update store:::', storage)

    return null
}
export const useSetLocation = () => {
    const dispatch = useDispatch()
    const storage = getItemFromStorage('currentLocation')
    const [loc, setLoc] = useState(null)
    console.log('useSetLocation first')
    useEffect(() => {
        console.log('useSetLocation in useEffect')
        if (storage && storage.currentLocation) {
            dispatch(locationActions.setLocationItem(storage.currentLocation))
            setLoc(storage.currentLocation)
        } else {
            setLoc(null)
        }
    }, [])

    return loc
}
export const useSetMenu = () => {
    const dispatch = useDispatch()
    const menu = getItemFromStorage('currentMenu')
    const menuSelector = useSelector(state => state.location.menu)
    if (menuSelector) {
        return menuSelector
    } else {
        if (menu && menu.menu) {
            dispatch(locationActions.setMenuItem(menu.menu))
            return menu.menu
        }
    }
    


    return null
}