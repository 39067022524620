import { useState, useRef } from "react";
import { cartSetDeliveryMethod } from "../../scripts/fetchApi";
import { setSessionStorageItem, setStorageItem } from "../../scripts/localStorage";
import classes from './DeliverySelectionDetails.module.css'
import { useDispatch, useSelector } from "react-redux";
import { cartActions } from "../../store/cart";
import { variousActions } from "../../store/various";
import { locationActions } from "../../store/location";
import DeliveryIcon from "../../icons/deliveryicon";
import ClockIcon from "../../icons/clockicon";
import LocationPin from "../../icons/locationpin";


const DeliverySelectionDetails = ({scheduled, selectedDate, selectedTime, searchedLocations}) => {
    const dispatch = useDispatch()
    const [locationLoading, setLocationLoading] = useState(false);
    const buildingRef = useRef(null)
    const specialInstructionsRef = useRef(null)
    const cart = useSelector(state => state.cart.cart)

    const startDeliveryHandle = async (e) => {
        e.preventDefault()
        setLocationLoading(true)
        

        const deliveryCartInfo = {
            delivery_mode: 'delivery',
            time: scheduled ? 'schedule' : 'asap',
            schedule_date: scheduled ? selectedDate : null,
            schedule_time: scheduled ? selectedTime : null,
            street_address: searchedLocations.street_number + ' ' + searchedLocations.street,
            city: searchedLocations.city,
            zip_code: searchedLocations.postal_code
        } 
        if (buildingRef.current.value) {
            deliveryCartInfo.building = buildingRef.current.value
        }
        if (specialInstructionsRef.current.value) {
            deliveryCartInfo.special_instructions = specialInstructionsRef.current.value
        } 


        const cartDelivery = await cartSetDeliveryMethod(cart.id, deliveryCartInfo)
        if (cartDelivery.id) {
            //success
            // setStorageItem('currentCart', cartDelivery)
            setSessionStorageItem('currentCart', cartDelivery)
            dispatch(cartActions.addCart(cartDelivery))
            setLocationLoading(false)

            // on success close modal 
            dispatch(locationActions.setChangeDelivery({
                close: false,
                value: true
            }))

        } else {
            dispatch(variousActions.setError(cartDelivery))
            setLocationLoading(false)
        }

        setLocationLoading(false)
    }

    return <>
        <form onSubmit={startDeliveryHandle}>
            <div className={`${classes.text_info} ${classes.text_info_header}`}>                
                {scheduled && <div className={classes.text_info_item}>
                    <ClockIcon />
                    <div className={classes.text_info_item_text}>
                        <div className={classes.text_info_item_header}>Delivery Time</div>
                        <span>{(new Date(selectedDate + 'T' + selectedTime).toLocaleString('en-US', {
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                                hour: '2-digit',
                                minute: '2-digit',
                            }))}
                        </span>
                    </div>
                </div>}
                {!scheduled && <div className={classes.text_info_item}>
                    <ClockIcon />
                    <div className={classes.text_info_item_text}>
                        <div className={classes.text_info_item_header}>Delivery Time</div>
                        <span>ASAP</span>
                    </div>
                </div>}
                <div className={classes.text_info_item}>
                    <LocationPin />     
                    <div className={classes.text_info_item_text}>
                        <div className={classes.text_info_item_header}>Delivery Address</div>
                        <span>{searchedLocations.full_address}</span>
                    </div>
                </div>
                <div className={`form-fields-container ${classes.form_fields_container}`}>        
                    <div className='form-controller'>
                        <label htmlFor='locationDetails' className="label">Apt/Suite/Flr (Optional)</label>
                        <input ref={buildingRef} type='text' id='locationDetails' name='locationDetails' placeholder='Apt/Suite/Flr (Optional)'/>
                    </div>
                    <div className='form-controller'>
                        <label className='label' htmlFor='locationInstructions'>Special Instructions (Optional)</label>
                        <textarea ref={specialInstructionsRef} type='text' id='locationInstructions' name='locationInstructions' placeholder='Special Instructions (Optional)'/>
                    </div>
                </div>
                <br />
                <DeliveryIcon />
                <h4>Sweeeeet!</h4>
                <p>We deliver treats  straight to your door!</p>
            </div>
            
            <div className={classes.cta_container}>
                {!locationLoading && <button className='btn btn-100' type="submit">Start Delivery Order</button>}
                {locationLoading && <div className="btn"><span className="loader"></span></div>}
            </div>
        </form>
    </>
}

export default DeliverySelectionDetails