import { useRef, useState } from 'react'
import ProductCard from './ProductCard'

import classes from './SubCategories.module.css'
import SubCategoriesNav from './SubCategoriesNav'

const SubCategories = ({data}) => {
    // const itemsPerPage = 6
    const [catIndex, setCatIndex] = useState(0)
    // const [pageItems, setPageitems] = useState(itemsPerPage)
    // const [page, setPage] = useState(1)
    // const [totalpages, setTotalpages] = useState(0)
    // const [hasPagination, setHasPagination] = useState(false)
    const changeSubSubCategoryHandler = (index) => {
        setCatIndex(index)        
        // setPageitems(itemsPerPage)
        // setPage(1)
        // setTotalpages(Math.ceil(data[index].products.length/itemsPerPage))
        // if (data[index].products.length > itemsPerPage) {
        //     setHasPagination(true)
        // } else {
        //     setHasPagination(false)
        // }
    }

    // const prevPageHandler = () => {
    //     if (pageItems > itemsPerPage) {
    //         setPageitems(prevState => prevState = prevState - itemsPerPage)
    //         setPage(prevState => prevState = prevState - 1)
    //     }
        
    // }
    // const nextPageHandler = () => {
    //     if (pageItems < Math.ceil(data[catIndex].products.length)) {
    //         setPageitems(prevState => prevState = prevState + itemsPerPage)
    //         setPage(prevState => prevState = prevState + 1)
    //     }
    // }

    // let items = data[catIndex].products.slice(pageItems-itemsPerPage, pageItems).map(sub_item => (
    //     <ProductCard key={sub_item.id} data={sub_item} />
    // ))
    let items = data[catIndex].products.map(sub_item => (
        <ProductCard key={sub_item.id} data={sub_item} />
    ))
    // const pageHandler = (index) => {
    //     setPage(index+1)
    //     setPageitems(itemsPerPage*(index+1))
    // }
    // const pages = [];
    // for (let index = 0; index < totalpages; index++) {                        
    //     pages.push(<button key={index} className={`${classes.pagination_page} ${(index+1) === page ? classes.page_active : null}`} onClick={pageHandler.bind(this, index)}>{index + 1}</button>)            
    // }     

    return <>
       
        <SubCategoriesNav data={data} changeSubSubCategoryHandler={changeSubSubCategoryHandler} catIndex={catIndex}/>
        <div className={classes.sub_cat_sub_cat_items}>
            <div className='grid-3'>
                {items}
            </div>
            
        </div>
        {/* {hasPagination && <div className={classes.pagination}>
            <div className={classes.pagination_view}>
                {page}/{totalpages}
            </div>
            <div className={classes.pagination_controls}>
                <button onClick={prevPageHandler} className={`${classes.control_prev}`} disabled={page===1 ? true : false}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="25" viewBox="0 0 15 25" fill="none">
                        <path d="M5.695 9.07147C7.29769 7.74283 8.89614 6.41418 10.5669 5.11211C10.992 4.79855 11.804 5.20777 11.4554 5.54259C10.2225 6.73837 8.93441 7.91024 7.62931 9.07944C6.48149 10.1238 4.30487 11.3541 5.16786 12.6827C5.85655 13.7456 7.35296 14.8086 8.35624 15.7785L11.8507 19.1665C12.4884 19.7804 10.9155 20.349 10.2863 19.7378L6.06488 15.6164C5.04459 14.6226 3.16983 13.3683 3.00828 12.1406C2.86374 10.9555 4.64496 9.94041 5.695 9.07147Z" fill="white"/>
                    </svg>
                    <span className='sr-only'>previous page</span>
                </button>   
                <div className={classes.pagination_pages}>
                    {pages}                    
                </div> 
                <button onClick={nextPageHandler} className={`${classes.control_next}`} disabled={page===totalpages ? true : false}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="25" viewBox="0 0 15 25" fill="none">
                        <path d="M9.305 9.07147C7.70231 7.74283 6.10386 6.41418 4.43315 5.11211C4.00803 4.79855 3.19604 5.20777 3.54464 5.54259C4.77748 6.73837 6.06559 7.91024 7.37069 9.07944C8.51851 10.1238 10.6951 11.3541 9.83214 12.6827C9.14345 13.7456 7.64704 14.8086 6.64376 15.7785L3.14928 19.1665C2.51161 19.7804 4.08454 20.349 4.71371 19.7378L8.93512 15.6164C9.95541 14.6226 11.8302 13.3683 11.9917 12.1406C12.1363 10.9555 10.355 9.94041 9.305 9.07147Z" fill="white"/>
                    </svg>
                    <span className='sr-only'>next page</span>
                </button>
            </div>
        </div>} */}
    </>
}

export default SubCategories