import { createSlice } from "@reduxjs/toolkit";

const initialProductChocies = {
    choices: [],
    product: null,
    openedOptions: 0,
    mobileSelectedChoicesState: false,
    optionsConfirmed: false,
    customFields: null,
    customMessage: null
}


const productChoicesSlice = createSlice({
    name: 'productChoices',
    initialState: initialProductChocies,
    reducers: {
        createproduct(state, data) {
            state.product = data.payload
        },
        addChoice(state, data) {
            const index = state.product.findIndex(object => {
                return object.choice_id == data.payload.id
            })
            if (index !== -1) {
                state.product[index].choice_options = [data.payload.choice]
                state.product[index].selected = true
            }
        },
        removeSubChoices(state, data) {
            let arr = state.product
            for (let i = arr.length-1; i>=0; i--) {
                if (arr[i].parent_id) {
                    if (arr[i].parent_id === data.payload.parent_id) {
                        arr.splice(i, 1)
                    }
                }
            }

            state.product = arr
        },
        removeSubChoicesCheckbox(state, data) {
            // find related id
            const index = state.product.findIndex(object => {
                return object.related_option == data.payload.option_id
            })
            if (index >= 0) { // found an option.
                state.product.splice(index, 1)
            }
            // const index = state.product.findIndex(object => {
            //     return object.choice_id == data.payload.parent_id
            // })
            // let arr = state.product
            // if (index >= 0) { // there is a parent

            //     for (let i = arr.length-1; i>=0; i--) {
            //         if (arr[i].related_option) { //item comes from a related option

            //             if (state.product[index].choice_options.length > 0) {
            //                 const isThereARelatedOption = state.product[index].choice_options.findIndex(object => {
            //                     return object.id == arr[i].related_option
            //                 })

            //                 if (isThereARelatedOption < 0) {
            //                     arr.splice(i, 1)
            //                 }
            //             } else {
            //                 // there are no option so just remove the options
            //                 arr.splice(i, 1)
            //             }
                        
            //         }
            //     }

            // }
            // state.product = arr
        },
        addMultipleChoices(state, data) {
            const index = state.product.findIndex(object => {
                return object.choice_id == data.payload.id
            })
            if (index !== -1) {
                state.product[index].choice_options.push(data.payload.choice)
                state.product[index].selected = true
            }
        },
        removeFromMultipleChoices(state, data) {
            const index = state.product.findIndex(object => {
                return object.choice_id == data.payload.id
            })

            if (index !== -1) {
                
                const subIndex = state.product[index].choice_options.findIndex(object => {
                    return object.id == data.payload.optionId
                })

                if (subIndex !== -1) {
                    state.product[index].choice_options.splice(subIndex, 1)
                    if (state.product[index].choice_options.length === 0) {
                        state.product[index].selected = false
                    }

                    //find related items
                    const relatedoptionIndex = state.product.findIndex(object => {
                        return object.related_option == data.payload.optionId
                    })
                    if (relatedoptionIndex !== -1) {
                        state.product.splice(relatedoptionIndex, 1)
                    }
                }
            }
        },
        appendSubChoice(state, data) {
            //first remove any suboption that matches the parent
            const indexToRemove = state.product.findIndex(object => {
                return object.parent_id == data.payload.parent_id
            })
            
            if (indexToRemove !== -1) {
                state.product.splice(indexToRemove, 1)
            }
            const insertIndex = state.product.findIndex(object => {
                return object.choice_id == data.payload.parent_id
            })
            const index = state.product.findIndex(object => {
                return object.choice_id == data.payload.choice_id
            })

            if (index < 0) {
                // state.product.push(data.payload)
                state.product.splice(insertIndex+1, 0, data.payload)
            }
        },
        appendBulkSubChoice(state, data) {

            let arr = state.product
            //first remove any suboption that matches the parent
            data.payload.optionsToAdd.forEach(element => {
                const indexToRemove = arr.findIndex(object => {
                    return object.parent_id == data.payload.parent_id
                })            
                if (indexToRemove !== -1) {
                    arr.splice(indexToRemove, 1)
                }
            })

            const insertIndex = arr.findIndex(object => {
                return object.choice_id == data.payload.parent_id
            })        
          
            data.payload.optionsToAdd.forEach((element, i) => {
                
                const index = arr.findIndex(object => {
                    return object.choice_id == element.choice_id
                })
    
                if (index < 0) {
                    // state.product.push(data.payload)
                    arr.splice(insertIndex+1+i, 0, element)
                }
            });
            
            state.product = arr
        },
        clearOptions(state) {
            state.product = null
        },
        removeSelectedChoice(state, data) {
            const indexofMainOption = state.product.findIndex(object => {
                return object.choice_id == data.payload.parent_id
            })

            if (indexofMainOption !== -1) {
                const indexToRemove = state.product[indexofMainOption].choice_options.findIndex(object => {
                    return object.id == data.payload.id
                })
                if (indexToRemove !== -1) { 
                    state.product[indexofMainOption].choice_options.splice(indexToRemove, 1) //remove subOption
                }
                if (state.product[indexofMainOption].choice_options.length === 0) {
                    state.product[indexofMainOption].selected = false
                }
            }
        },
        setOpenedoption(state, data) {
            state.openedOptions = data.payload
        }, 
        setMobileSelectedChoicesState(state) {
            state.mobileSelectedChoicesState = !state.mobileSelectedChoicesState
        },  
        closeMobileSelectedChoices(state) {
            state.mobileSelectedChoicesState = false
        },    
        addAggregateChoices(state, data) {
            // find where to add aggregate options 
            const index = state.product.findIndex(object => {
                return object.choice_id == data.payload.parent_id
            })

            // found the parent 
            if (index >= 0) {
                console.log('there is a parent')
                // now check if the item allready is there 
                const childIndex = state.product[index].choice_options.findIndex(object => {
                    return object.id == data.payload.options.id
                })

                // there is an option, update it
                if (childIndex >= 0) {
                    // if quantity === 0, delete choice 
                    if (data.payload.options.quantity === 0) {
                        state.product[index].choice_options.splice(childIndex, 1)
                    }  else { // if not, the modify the item
                        state.product[index].choice_options[childIndex] = {...data.payload.options}
                    }                 
                } else { //there is no option, add it                    
                    state.product[index].choice_options.push(data.payload.options)
                }
                
            }
        },
        confirmOptions(state, data) {
            state.optionsConfirmed = data.payload
        },
        addCustomFieldData(state, data) {
            state.customFields = data.payload
        },
        customMessageText(state, data) {
            state.customMessage = data.payload
        }
    }
})

export const productChoicesActions = productChoicesSlice.actions

export default productChoicesSlice.reducer