import { useEffect, useState } from "react";
import classes from "./NonOloPay.module.css";
import { useSelector } from "react-redux";

import NonOloPayCheckout from "./NonOloPayCheckout";

let checkoutFrame = new window.Olo.CheckoutFrame({
    cardElement: "credit-card-info-div",
    cvvElement: "cvv-info-div",
});


const NonOloPay = () => {

    let cart = useSelector((state) => state.cart.cart);

    const [isLoaded, setIsLoaded] = useState(false);


    useEffect(() => {
        // START GTM DATA
        let prodArray = []
        cart.products.forEach(element => {
            prodArray.push({
                item_id: element.productId,
                item_name: element.name,
                price: element.totalcost, // e.g. 9.99,
                quantity: element.quantity, // e.g. 1
            })
        });
        
        window.dataLayer = window.dataLayer || [];

        
        window.dataLayer.push({
        event: "add_payment_info",
        ecommerce: {
            currency: "USD", // e.g. "USD",
            value: cart.total, // e.g. 7.77,
            coupon: cart.coupon,
            payment_type: "credit card",
            items: prodArray
        }
        });
        // END GTM DATA

        if (!isLoaded) {
            setIsLoaded(true)
            checkoutFrame.initialize({
                brandAccessId: process.env.REACT_APP_BRAND_ACCESS_ID,
                basketGuid: cart.id,
                styles: {
                    cardNumber:
                        `background-color: transparent;
                        border: 0;
                        border-left-width: 0;
                        border-right-width: 0;
                        border-bottom: 0;
                        border-top-width: 0;
                        display: block;
                        font-family: "Brandon Text";
                        font-size: 18px;
                        height: 1.2em;
                        line-height: 1.2em;
                        margin: 0;
                        padding: 0;
                        width: 100%;
                        color: #242c37;
                        `,
                    cvv: `background-color: transparent;
                    border: 0;
                    border-left-width: 0;
                    border-right-width: 0;
                    border-bottom: 0;
                    border-top-width: 0;
                    display: block;
                    font-family: "Brandon Text";
                    height: 1.2em;
                    line-height: 1.2em;
                    margin: 0;
                    padding: 0;
                    width: 100%;
                    font-size: 18px;
                    color: #242c37;
                    `,
                },
                cardNumber: {
                    placeholder: "Credit Card",
                },
                cvv: {
                    placeholder: "Cvv",
                },
            });
        }
        
    }, [])

    return (
        <div>
            <div className={`form-fields-container ${classes.olo_card_form}`}>
                <div className="form-controller-group">
                    <div className={`form-controller ${classes.form_controller_credit_card}`}>
                        <label className="label" htmlFor="card_number">
                            Card Number
                        </label>
                        <div className="credit-card-info-div olo-pay"></div>
                    </div>
                    <div className={`form-controller ${classes.form_controller_cvv}`}>
                        <label className="label" htmlFor="cvv_input">
                            CVV
                        </label>
                        <div className="cvv-info-div olo-pay"></div>
                    </div>
                </div>
                <NonOloPayCheckout checkoutFrame={checkoutFrame}/>
            </div>

        </div>
    );
};

export default NonOloPay;
