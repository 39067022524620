import { useLoaderData, Await } from "react-router-dom"
import { Suspense } from "react"


import RecentOrders from "../components/account/RecentOrders"
import LoadingWrapperAuth from "../components/common/LoadingWrapperAuth"
import AccountWrapper from "../components/common/AccountWrapper"

const Recent = () => {
    const data = useLoaderData() 
     
    return <Suspense fallback={<LoadingWrapperAuth text=""/>}>
        <Await resolve={data.data}>
            {() => <>
                <AccountWrapper>
                    <RecentOrders />
                </AccountWrapper>
            </>}
        </Await>
    </Suspense>
}

export default Recent