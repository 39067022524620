import classes from './FavoriteItem.module.css'
import { useState } from 'react'
import Popup from '../common/Popup'

const FavOrderDetails = ({item}) => {
    const [showDetails, setShowDetails] = useState()

    const viewDetailsHandler = () => {

        setShowDetails(p => p = !p)
    }


    return <>
        <button className='btn btn-small' onClick={viewDetailsHandler}>View Details</button>
        {showDetails && <Popup closeInfoHandler={viewDetailsHandler} transition={showDetails}>
            {item.products && item.products.length > 0 && <div className={classes.item_products}>
                {item.products.map(product => (
                    <div key={product.productid} className={classes.product_item}>
                        <div className={classes.product_name}>{product.quantity}X{product.name}</div>
                        {product.choices && product.choices.length > 0 && <div className='choices'>
                            {product.choices.map(item => (
                                <div key={item.id} className={classes.choice}>
                                    <div className='choice'><span>{item.name}</span>, quantity: {item.quantity}</div>
                                </div>
                            ))}
                        </div>}
                    </div>
                ))}    
            </div>}   
        </Popup>}
    </>
}

export default FavOrderDetails