import { useState } from 'react';
import classes from './Authentication.module.css'
import Signin from './Signin';
import Signup from './Signup';
import { useDispatch } from 'react-redux';
import { cartActions } from '../../store/cart';
import { useNavigate } from 'react-router-dom';
import { authActions } from '../../store/auth';

const Authentication = ({closeSidebar}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [showSignInPannel, setShowSignInPannel] = useState(true);
    const [showSignUnPannel, setShowSignUnPannel] = useState(false);

    const showSignInPannelHandler = () => {
        setShowSignInPannel(true)
        setShowSignUnPannel(false)
    }

    const showSignUpPannelHandler = () => {
        setShowSignInPannel(false)
        setShowSignUnPannel(true)
    }

    const goToMigrate = () => {
        dispatch(authActions.setUserSideBarState())
        navigate('/rewards-migration')
    }
    return <>
        <div className={classes.sidebar_header}>
            <button className={`btn ${!showSignInPannel ? 'btn-border' : ''}`} onClick={showSignInPannelHandler}>Sign In</button>
            <button className={`btn ${!showSignUnPannel ? 'btn-border' : ''}`} onClick={showSignUpPannelHandler}>Join Rewards</button>
        </div>

        <div className={classes.sidebar_content}>
            <div className={classes.sidebar_content_inner}>
                <div className={classes.migrate_disclaimer}>
                    <p>We've combined Great American Cookies and Marble Slab Creamery Rewards! If you have an existing Great American Cookies Rewards account, please log in below.</p>
                    <p>If you have an existing Marble Slab Creamery Rewards account, <button onClick={goToMigrate}>please visit the migration page</button> to complete the account migration process.</p>
                </div>
                {showSignInPannel && <Signin closeSidebar={closeSidebar} noTitle={true}/>}
                {showSignUnPannel && <Signup noTitle={true}/>}
            </div>
        </div>
    </>
}

export default Authentication;