import { useState, useRef } from 'react'

import { setSessionStorageItem, setStorageItem } from '../../scripts/localStorage'
import { useDispatch, useSelector } from 'react-redux'
import { cartActions } from '../../store/cart'
import { variousActions } from '../../store/various'
import classes from './Tip.module.css'
import { setTip } from '../../scripts/fetchApi'
import { formatNumberDecimal } from '../../scripts/helpers'
import TipIcon from '../../icons/tipicon'

const Tip = ({cart}) => {
    const otherTip = useRef(null)
    const dispatch = useDispatch()
    const selectedTip = useSelector(state => state.cart.tipSelection)
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingRemove, setIsLoadingRemove] = useState(false) 
    const [showOtherInput, setShowOtherInput] = useState(false)
    const [activeSelection, setActiveSelection] = useState(null)


    const setTipHandler = async (percent) => {
       
        setIsLoading(true)
        setShowOtherInput(false)
        const total = cart.subtotal + cart.salestax
        const tip = (total * percent) / 100 
        setActiveSelection(percent)


        const cartTip = await setTip(cart.id, { amount: tip.toFixed(2)})

        if (cartTip.id) {
            // setStorageItem('currentCart', cartTip)
            setSessionStorageItem('currentCart', cartTip)
            dispatch(cartActions.setTipSelection(percent))
            dispatch(cartActions.addCart(cartTip))
            // dispatch(cartActions.setCartSidebarState())
        } else {
            //error
            dispatch(variousActions.setError(cartTip))
        }
        setIsLoading(false)
    }

    const setFixedTipHandler = async () => {
        setIsLoading(true)
        const value = Number(otherTip.current.value)
        setActiveSelection('custom');
        dispatch(cartActions.setTipSelection('custom'))
        if (value && value >= 0 && typeof(value) === 'number') {

            const cartTip = await setTip(cart.id, { amount: value.toFixed(2)})
            if (cartTip.id) {
                // setStorageItem('currentCart', cartTip)
                setSessionStorageItem('currentCart', cartTip)
                dispatch(cartActions.addCart(cartTip))
            } else {
                //error
                dispatch(variousActions.setError(cartTip))
            }
        } else {
            dispatch(variousActions.setError({
                message: 'Please set a valid tip amount'
            }))
           
        }
        setIsLoading(false)
    }

    const otherInputhandler = () => {
        setShowOtherInput(prevState => prevState = !prevState)
    }

    const removeTipHandler = async () => {
        setIsLoadingRemove(true)

        const tipRemove = await setTip(cart.id, { amount: 0})

        if (tipRemove.id) {
            // setStorageItem('currentCart', tipRemove)
            setSessionStorageItem('currentCart', tipRemove)
            dispatch(cartActions.setTipSelection(null))
            dispatch(cartActions.addCart(tipRemove))
        } else {
            dispatch(variousActions.setError(tipRemove))
        }
        setIsLoadingRemove(false)
    }


    return <div className={classes.checkout_tip}>
        <div className={classes.checkout_tip_header}>
            
            <div className={classes.checkout_tip_header_title}>
                <TipIcon />
                <span>Tip</span>
            </div>
            <div className={classes.checkout_tip_header_info}>100% of your tip goes to our staff.</div>
        </div>
        <div className={classes.checkout_tip_percent}>
            <div className={classes.checkout_tip_item}>
                <button onClick={setTipHandler.bind(this, 15)} disabled={isLoading && true} className={selectedTip === 15 ? classes.selection_active : ''}>
                    15%                
                </button>
            </div>
            <div className={classes.checkout_tip_item}>
                <button onClick={setTipHandler.bind(this, 20)} disabled={isLoading && true} className={selectedTip === 20 ? classes.selection_active : ''}>
                    20%
                </button>
            </div>
            <div className={classes.checkout_tip_item}>
                <button onClick={setTipHandler.bind(this, 25)} disabled={isLoading && true} className={selectedTip === 25 ? classes.selection_active : ''}>
                    25%
                </button>
            </div>
            <div className={classes.checkout_tip_item}>
                <button onClick={otherInputhandler} className={`${showOtherInput && classes.active} ${selectedTip === 'custom' ? classes.selection_active : ''}`} disabled={isLoading && true} >
                    Custom
                </button>
            </div>       
        </div>
        {showOtherInput && <div className='form-fields-container'>
            <label htmlFor='tipInput' className='label'>Tip in $:</label>
            <div className={classes.other_tip}>
                <div className='form-controller'>
                    
                    <input id='tipInput' name='tipInput' type='number' placeholder='0' ref={otherTip}/>  
                </div>  
                <div className='form-controller'>
                    {!isLoading && <button className='btn' onClick={setFixedTipHandler}>Set Tip</button>}
                    {isLoading && <div className={`btn-loader ${classes.loader}`}><span className="loader"></span></div>}
                </div>
            </div>
        </div>}
        {isLoading && <div><span className="loader dark-raspberry"></span> Setting tip!</div>}
        {cart.tip !== 0 && <div className={classes.checkout_tip_amount}>
            <span>tip amount: ${formatNumberDecimal(cart.tip)}</span>
            {!isLoadingRemove && <button onClick={removeTipHandler} title="remove tip">
                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>
                <span className="sr-only">Remove tip</span>
            </button>}
            {isLoadingRemove && <div className={classes.btn_placeholder}><span className="loader dark-raspberry"></span></div>}
        </div>}
        
    </div>
}

export default Tip