import SelectedPaymentItem from "./SelectedPaymentItem"
import classes from "./CreditCardForm.module.css";
import { useSelector } from "react-redux";

const SelectedPayment = () => {
    const selectedPayment = useSelector(state => state.cart.billing)

    return <div className={classes.selected_payment_container}>
        <h4>Selected Payment Method</h4>
        {selectedPayment && <SelectedPaymentItem key={selectedPayment.paymentMethod.id} item={selectedPayment} />}
    </div>
}

export default SelectedPayment