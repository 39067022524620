import ModifierTitle from "./ModifierTitle"
import Options from "./Options"
import OptionsAggregate from "./OptionsAggregate"

import classes from './ProductModifiers.module.css'

const ProductModifier = ({modifier}) => {

    return <div className={classes.modifier_item}>   
        <ModifierTitle modifier={modifier}/>
        {modifier.options && !modifier.maxaggregatequantity && <Options options={modifier.options} parentModifier={modifier}/>}
        {modifier.options && modifier.maxaggregatequantity && <OptionsAggregate options={modifier.options} parentModifier={modifier}/>}
    </div>
}

export default ProductModifier