import { useEffect, useState, useRef } from 'react'
import classes from './SpecialInstructions.module.css'
import { useSelector } from 'react-redux';

const SpecialInstructions = ({cakeBuilder}) => {
    const textareaRef = useRef(null);
    // const checkTextarea = useRef(null);

    const editOptions = useSelector(state => state.productDetails.edit)
    const pageReload = useSelector(state => state.productDetails.pageReload)
    const [textareaValue, setTextareaValue] = useState("")
    const [isActive, setIsActive] = useState(false)

    const changeStateHandler = (event) => {
        setIsActive(event.target.checked)
    }

    useEffect(() => {
        if (editOptions) {           
            if (editOptions.specialinstructions !== "") {
                setTextareaValue(editOptions.specialinstructions)
                setIsActive(true)
            }

        }
    }, [pageReload])

    return <div className={`${classes.options_item} ${classes.options_item_customize}`} >
        <div className={classes.options_item_header}>
            <h3>Order Customization:</h3>
        </div>
        <div className='form-fields-container'>
            <div className={`form-controller ${classes.form_controller}`}>
                <fieldset>
                    <legend className='sr-only'>Order Customization</legend>
                    {!cakeBuilder && <>
                        <div className={`form-group-checkbox ${classes.form_group}`}>
                            <input type="checkbox" id="orderCustomizationTextarea" name="orderCustomizationTextarea" onChange={changeStateHandler} value={isActive} checked={isActive}/>
                            <label htmlFor="orderCustomizationTextarea">special instructions (<span>optional</span>)</label>
                        </div>
                        <div className={classes.form_group}>
                            <textarea ref={textareaRef} maxLength={200} id='orderCustomizationTextareaInput' name="orderCustomizationTextareaInput" placeholder='Write any special instructions here...' disabled={!isActive} defaultValue={textareaValue}></textarea>
                            <label htmlFor='orderCustomizationTextareaInput' className={classes.instructions}>Enter any special instructions here, such as a custom design description. No price altering substitutions/additions. </label>
                        </div>
                    </>}  
                    {cakeBuilder && <div className={classes.form_group}>
                        <textarea ref={textareaRef} maxLength={200} id='orderCustomizationTextareaInput' name="orderCustomizationTextareaInput" placeholder='Write any special instructions here...' defaultValue={textareaValue}></textarea>
                        <label htmlFor='orderCustomizationTextareaInput' className={classes.instructions}>Enter any special instructions here, such as a custom design description. No price altering substitutions/additions. </label>
                    </div>}                            
                </fieldset>
            </div>
        </div>
    </div>
}

export default SpecialInstructions