import { useDispatch, useSelector } from 'react-redux'
import { locationActions } from '../../store/location'
import classes from './Header.module.css'
import StoreIcon from '../../icons/storeicon'
import CurbsideIcon from '../../icons/curbsideicon'
import DeliveryIcon from '../../icons/deliveryicon'
import { useRef } from 'react'
import { CSSTransition } from 'react-transition-group';
import ChangeSelections from './ChangeSelections'
import ReactDOM from "react-dom";
import PenIcon from '../../icons/penicon'

const HeaderLocationMobile = () => {
    const dispatch = useDispatch()
    const nodeRef = useRef(null)
    const modalOpened = useSelector(state => state.location.changeDeliveryModal)
    const locationStore = useSelector(state => state.location.location)
    const cart = useSelector(state => state.cart.cart)

    const openLocationSidebar = () => {
        dispatch(locationActions.setLocationsSideBarState())   
        if (cart) {
            if (cart.deliverymode === 'pickup' || cart.deliverymode === 'curbside') {
                dispatch(locationActions.setVirtualDeliveryType('pickup')) 
            }
            if (cart.deliverymode === 'dispatch') {
                dispatch(locationActions.setVirtualDeliveryType('delivery'))
            }
        }  else {
            dispatch(locationActions.setVirtualDeliveryType('pickup')) 
        }       
        
    }

    const changeSelectionHandler = () => {
        // setModalOpened(p => p = !p)
        dispatch(locationActions.setChangeDelivery({
            close: false,
            value: true
        }))
    }
    const closeChangeSelectorHandler = () => {
        dispatch(locationActions.setChangeDelivery({
            close: true,
            value: false
        }))
    }  
    return <div className={`${classes.mobile_location_header} `}>        
        {!locationStore && <div className={`${classes.control_box_mobile} ${locationStore ? '' : classes.mobile_location_header_no_loc}`}><button className={`btn-underline ${classes.white}`} onClick={openLocationSidebar}>Start Your Order</button></div>}
        {locationStore && locationStore.id && <div className={classes.control_box_location}>  
            <div className={classes.mobile_location_header_address}>
                <div className={classes.control_box_icon_mobile}>
                    {cart && <>
                        {cart.deliverymode === 'pickup' && <StoreIcon fill="#fff" dimensions={{width: '16px', height: '16px'}}/>}
                        {cart.deliverymode === 'curbside' && <CurbsideIcon fill="#fff"/>}
                        {cart.deliverymode === 'dispatch' && <DeliveryIcon fill="#fff"/>}
                    </>}
                    {!cart && <StoreIcon fill="#fff" dimensions={{width: '16px', height: '16px'}}/>}
                </div>
                {cart && <>
                    {cart.deliverymode === 'pickup' && <span className={classes.mobile_location_header_delivery_type}>{cart.deliverymode}</span>}
                    {cart.deliverymode === 'curbside' && <span className={classes.mobile_location_header_delivery_type}>{cart.deliverymode}</span>}
                    {cart.deliverymode === 'dispatch' && <span className={classes.mobile_location_header_delivery_type} >Delivery to</span>}
                </>}
                {!cart && <span className={classes.mobile_location_header_delivery_type}>Pickup</span>}      

                {cart && cart.deliverymode !== 'dispatch' && <div className={classes.control_box_location_text}>{locationStore.address && locationStore.address.street }</div>}
                {cart && cart.deliverymode === 'dispatch' && <div className={classes.control_box_location_text}>{cart.deliveryaddress.streetaddress}, {cart.deliveryaddress.city}, {cart.deliveryaddress.zipcode}</div>}
                {!cart && <div className={classes.control_box_location_text}>{locationStore.address && locationStore.address.street }</div>}              
                {/* <div className={classes.control_box_location_text}>{locationStore.address && locationStore.address.street }</div> */}
            </div>
            {/* <button className={`btn-underline ${classes.white}`} onClick={openLocationSidebar}>Change</button> */}
            <button className={`btn-underline ${classes.white} btn-flex-5 ${classes.btn_svg_18}`} onClick={changeSelectionHandler}>
                Change <PenIcon stroke='#fff' />
            </button>
        </div>}


        <CSSTransition 
            in={modalOpened} 
            nodeRef={nodeRef} 
            timeout={400}
            classNames="top-drop"
            unmountOnExit>
            <div ref={nodeRef} className={classes.change_selections_container_outer}>
                <ChangeSelections closeChangeSelector={closeChangeSelectorHandler}/>
                {ReactDOM.createPortal(
                    <div className="background-invisible" onClick={closeChangeSelectorHandler}></div>,
                    document.getElementById("backdrop-root")
                )}
            </div>
        </CSSTransition>
    </div>
}

export default HeaderLocationMobile