import classes from './CartUpsells.module.css'
import { useSelector, useDispatch } from 'react-redux'

import { cartActions } from '../../store/cart'
import { addUpsellsToCart } from '../../scripts/fetchApi'
import { setSessionStorageItem, setStorageItem } from '../../scripts/localStorage'
import { variousActions } from '../../store/various'
import { useState } from 'react'

const UpsellsItem = ({item, imgPath}) => {

    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false)


    const cart = useSelector(state => state.cart.cart)
    const addToCartHandler = async () => {
        setLoading(true)
        const addUpsell = await addUpsellsToCart(cart.id, {
            items : [{
                id: item.id,
                quantity: 1
            }]
        })

        if (addUpsell.id) {
            // setStorageItem('currentCart', addUpsell)
            setSessionStorageItem('currentCart', addUpsell)
            dispatch(cartActions.addCart(addUpsell))
        } else {
            dispatch(variousActions.setError(addUpsell))
        }
        setLoading(false)
    }

    return <div className='card_alt_item'>
        {/* <button onClick={addToCartHandler} className={classes.upsells_link} > */}
            <div className={`card_alt_item_inner ${classes.card_alt_item_inner} ${item.image_url ? '' : classes.card_alt_item_inner_no_image}`}>
                <div>
                    {item.images && item.images.length > 0 && <div className='card_alt_item_image'>
                        <img src={`${imgPath}${item.images[0].filename}`} alt={item.name} className='image-contain'/>
                    </div>}
                    
                    <div className='card_alt_title'>{item.name}</div>
                    <div className='card_alt_price'>{item.cost}</div>
                </div>
                <div className='card_alt_btn-container'>
                    {loading && <div className='btn-loader card_button'><span className="loader dark-raspberry"></span></div>}
                    {!loading && <button className='btn card_button' onClick={addToCartHandler}>Add to cart</button>}
                </div>
            </div>
        {/* </button> */}
    </div>
}

export default UpsellsItem