import { useEffect, useState } from 'react'
import placeholderImage from '../../../assets/GAC-placeholder.png'
import CheckMarkIcon from '../../../icons/checkmarkicon'
import { formatNumberDecimal } from "../../../scripts/helpers";

import classes from "./Option.module.css";
import { useSelector } from 'react-redux';
import OptionTooltip from './OptionTooltip';

const OptionAggregate = ({ option, setOption, selecteditemsIncrement, selectedNumbersDecrement, totalSectable, limitReached }) => {
    const [showCounter, setShowCounter] = useState(false)
    const [counter, setCounter] = useState(0)

    const editOptions = useSelector(state => state.productDetails.edit)
    const pageReload = useSelector(state => state.productDetails.pageReload)
    
   
    const showCounterHandler = (e) => {        
        setOption(option.id, 'increment')
        setShowCounter(true)
        setCounter(prevState => prevState = prevState + 1)
        // selecteditemsIncrement(option.id)
    }
    const incremenetCounter = (e) => {
        if (totalSectable>0) {
            setCounter(prevState => prevState = prevState + 1)
            selecteditemsIncrement(option.id)    
        }     
    }

    const decrementCounter = (e) => {
        if (counter > 0) {
            setCounter(prevState => prevState = prevState - 1)
            selectedNumbersDecrement(option.id)
        } 
        if (counter === 1 ) {
            setShowCounter(false)
            setOption(option.id, 'decrement')
        }
    }
    useEffect(() => {
        
        if (editOptions) {
            const selectedOption = editOptions.choices.filter(item => item.optionid === option.id)
            
            if (selectedOption.length > 0) {
                setShowCounter(true)
                setCounter(selectedOption[0].quantity)
            }
        }
    }, [pageReload])

    
    
    return <div className={classes.option_aggregate}>
        <button
            onClick={showCounterHandler}
            className={`${classes.option} ${
                option.itemSelected ? classes.active : ""
            }`}
            // disabled={((limitReached && !option.itemSelected) || showCounter) ? true : false}
            disabled={(limitReached || showCounter) ? true : false}
        >
            <div className={classes.image_container}>
                <div className={classes.image_container_inner}>
                    {option.image && <img src={option.image} alt={option.name}/>}
                    {!option.image && <img src={placeholderImage} alt={option.name}/>}
                </div>
                <div className={classes.check_mark}>
                    <CheckMarkIcon />                    
                </div>
            </div>
            <div className={classes.option_title}>
                {option.name}
            </div>
        </button>
        {option.metadata && option.metadata.filter(item => item.key === "Tooltip").map(item => (
            <OptionTooltip data={item} key={item.key}/>
        ))}

        {showCounter && <div className={classes.increment_decrement_container}>
            <button onClick={decrementCounter}>                
                <svg viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.8125 0.75C11.8125 0.996094 11.5938 1.1875 11.375 1.1875H0.875C0.628906 1.1875 0.4375 0.996094 0.4375 0.777344C0.4375 0.53125 0.628906 0.3125 0.875 0.3125H11.375C11.5938 0.3125 11.8125 0.53125 11.8125 0.75Z" fill="#242C37"/>
                </svg>
                <span className='sr-only'>remove one item</span>
            </button>
            <div className={classes.item_count}>{counter}</div>
            {/* disabled={totalSectable > 0 ? false : true} */}
            <button onClick={incremenetCounter} >
                <svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.8125 5.75C11.8125 5.99609 11.5938 6.1875 11.375 6.1875H6.5625V11C6.5625 11.2461 6.34375 11.4648 6.125 11.4648C5.87891 11.4648 5.6875 11.2461 5.6875 11V6.1875H0.875C0.628906 6.1875 0.4375 5.99609 0.4375 5.77734C0.4375 5.53125 0.628906 5.3125 0.875 5.3125H5.6875V0.5C5.6875 0.28125 5.87891 0.0898438 6.125 0.0898438C6.34375 0.0898438 6.5625 0.28125 6.5625 0.5V5.3125H11.375C11.5938 5.3125 11.8125 5.53125 11.8125 5.75Z" fill="#242C37"/>
                </svg>
                <span className='sr-only'>add one item</span>
            </button>
        </div>}
    </div>
}

export default OptionAggregate