import { Suspense, useEffect } from "react"
import LoadingWrapper from "../components/common/LoadingWrapper"
import { useLoaderData, Await, useParams, useNavigate, useLocation } from "react-router-dom"
import classes from './ProductDetails.module.css'

import background from '../assets/bg-details.png'
import PresentationBox from "../components/products/products_details/PresentationBox"

import ProductOptions from "../components/products/products_details_v3/ProductOptions"
import PageWrapper from "../components/common/PageWrapper"
import CircleArrowLeft from "../icons/circlearrowleft"
import { useSelector } from "react-redux"

import { Link } from 'react-router-dom';
import { locationHomeUrl } from "../scripts/helpers"

const ProductDetails = () => {
    const data = useLoaderData()
    if (window.google_tag_manager) {
        window.google_tag_manager[`${process.env.REACT_APP_GTM}`].dataLayer.reset()
    }

    let locationStore = useSelector(state => state.location.location)


    const params = useParams()
    const navigate = useNavigate();
    const location = useLocation()

    useEffect(() => {
        if (location && location.pathname) {
            if (location.pathname.includes('/menu')) {
                navigate(`/gac/${locationStore.location_slug}/product/${params.productSlug}`)
            }
        }
    }, [location])
    
    return <>
        <Suspense fallback={<LoadingWrapper text="" productPage={true}/>}>
        {/* Baking Cookies and making icecream... */}
            <Await resolve={data.data} errorElement={<div className="container-full">
                <section className={classes.error}>
                    <h1 className="text-center">Page Not Found</h1>
                    <h3 className="text-center">No cookies for you here :( !</h3>
                </section>
            </div>}>
                {() => <>
                    <PageWrapper>
                        <div className="relative">
                            <div className={classes.background}>
                                <img src={background} role="presentation" alt="" className="image-cover" />
                            </div>
                            <div className="container-full">
                                {locationStore && <div className={classes.back_to_menu_container}>
                                    <Link to={locationHomeUrl(locationStore)}>
                                        <CircleArrowLeft /> 
                                        Back To Menu
                                    </Link>
                                </div>}
                                <div className={classes.product_details_container}>
                                    <PresentationBox />
                                    <ProductOptions />
                                </div>
                            </div>
                        </div>
                    </PageWrapper>
                </>}
            </Await>
        </Suspense>
        
    </>
}

export default ProductDetails
