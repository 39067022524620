import ReactDOM from "react-dom";
import MockAccount from "./MockAccount";

const LoadingWrapperAuth = ({text}) => {
    return <>
    <MockAccount />
    {ReactDOM.createPortal(
        <div className='preparing_restaurant'>
            <div className='preparing_restaurant_backdrop'>
                <div className='preparing_restaurant_container'>
                    <div className='preparing_restaurant_loader'></div>
                    <div className='preparing_restaurant_message'>{text}</div>
                </div>
            </div>
        </div>,
        document.getElementById("overlay-root")
    )}
    </>
}

export default LoadingWrapperAuth