import { useEffect, useRef, useState } from "react"

import classes from './SubCategories.module.css'
import ChevronLeftIcon from "../../icons/chevronlefticon"
import ChevronRightIcon from "../../icons/chevronrighticon"

const SubCategoriesNav = ({data, changeSubSubCategoryHandler, catIndex}) => {
    const navRef = useRef(null)
    const containerRef = useRef(null)
    const [scrollControls, setScrollControls] = useState(false)
    const scrollLefthandler = () => {
        navRef.current.scrollLeft -= 300
    }
    const scrollRighthandler = () => {
        navRef.current.scrollLeft += 300
    }

    const setActiveHandler = (event, index) => {
        navRef.current.scrollLeft += (event.target.getBoundingClientRect().left - 60)
        changeSubSubCategoryHandler(index)
    }

    useEffect(() => {
        const navWidth = navRef.current.offsetWidth
        const getWidthDifference = () => {
            if ( navWidth > containerRef.current.offsetWidth) {
                setScrollControls(true)
            } else {
                setScrollControls(false)
            }
        }
        // window.onresize = getWidthDifference
        window.addEventListener("resize", getWidthDifference, true);
        window.addEventListener("load", getWidthDifference, true);

        return function cleanup() {
            window.removeEventListener("resize", getWidthDifference, true);
            window.removeEventListener("load", getWidthDifference, true);
        }
    }, [])

    return <div className={classes.navigation_container} ref={containerRef}>
        <div className={classes.sub_cat_sub_cat} ref={navRef}>
            {data.map((sub, index) => (
                <button key={sub.name + Math.random(10)} className={`${classes.sub_cat_sub_cat_item} ${index===catIndex ? classes.cat_active : null}`} onClick={(event) => { setActiveHandler(event, index) }}>{sub.name}</button>
            ))}    
        </div>
        {scrollControls && <div className={classes.controls}>
            <button onClick={scrollLefthandler} className={classes.button_left}>
                <ChevronLeftIcon />
                <span className="sr-only">scroll left</span>
            </button>
            <button onClick={scrollRighthandler} className={classes.button_right}>
                <ChevronRightIcon />
                <span className="sr-only">scroll rightt</span>
            </button>
        </div>}
    </div>
} 

export default SubCategoriesNav