import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { locationActions } from "../../store/location"
import { authActions } from "../../store/auth"

const LocationsFakePage = ({locationPage}) => {
    const dispatch = useDispatch()

    useEffect(() => {
        if (locationPage) {
            dispatch(locationActions.forceLocationsSideBarStateOpen())
            
            // setTimeout(() => {
            //     console.log('location page!!!!!')
            //     dispatch(locationActions.setLocationsSideBarState()) 
            // }, 500);
        }
    }, [])

    return null
}

export default LocationsFakePage