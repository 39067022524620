import { Link } from "react-router-dom"
import { useDispatch } from "react-redux"
import { variousActions } from "../../store/various"

import classes from './AccountLanding.module.css'

import { useEffect, useState } from "react"
import { getUserRecentOrders } from "../../scripts/fetchApi"
import RecentItem from "./RecentItem"
import { getGenericToken } from "../../scripts/helpers"

const RecentOrders = () => {
    const dispatch = useDispatch()
    const [recent, setrecent] = useState()
    // const token = localStorage.getItem('token')
    // const token = sessionStorage.getItem('token')
    const token = getGenericToken()

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const getFav = async () => {
            const recent = await getUserRecentOrders(token)

            if (recent.message) {
                dispatch(variousActions.setError(recent))     
                setLoading(false)
            } else {
                setLoading(false)
                setrecent(recent)
            }
        }

        getFav()

    }, [token])



    return <div className="container-full">

        <div className={classes.wrapper}>
            <div className={classes.back_to_container}>
                <Link to="/account">
                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"><path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/></svg>
                    Back To Account
                </Link>
            </div>
            <h1>Recent Orders</h1>
            
            <div className={classes.favorite_orders}>
                {loading && <div className='preparing_restaurant_loader'></div>}
                {recent && !loading && recent.length > 0 && <div className={`grid-3`}>
                    {recent.map(item => (
                        <RecentItem key={item.id} item={item}/>
                    ))}
                </div>}
                {!recent && !loading && <div>
                    <p>There are no recent</p>
                </div>}
            </div>
        </div>
    </div>
}

export default RecentOrders