import { useEffect } from 'react'
import Signin from '../authentication/Signin'
import classes from './AccountLanding.module.css'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getGenericToken } from '../../scripts/helpers'

function isMSC(location) {
    if (location) {
        if (location.data && location.data.attributes && location.data.attributes.includes("MSC")) {            
            return true
        } 
    }

    return false
}

function isRewardsNonParticipating(location) {
    if (location) {
        if (location.data && location.data.attributes && location.data.attributes.includes("Rewards Non-Participating")) {            
            return true
        } 
    }

    return false
}

const SignIn = () => {
    const navigate = useNavigate()
    // const token = localStorage.getItem('token')
    // const token = sessionStorage.getItem('token')

    const token = getGenericToken()

    const user = useSelector(state => state.auth.user)

    // hide account routes for a while
    let locationStore = useSelector(state => state.location.location)

    useEffect(() => {
        // isMSC(locationStore) || 
        if (isRewardsNonParticipating(locationStore)) {
            navigate('/')
        } 
        if (token) {
            navigate('/account')
        }
        if (user) {
            navigate('/account')
        }

    }, [token, navigate, user, locationStore])

    const navigateToAccountHandler = () => {
        navigate('/account')
    }


    return <div className="container-full">
        <div className={classes.wrapper}>
            <h1 className='text-center'>Sign in to access your account page</h1>
            <div className={classes.form_wrapper}>
                <Signin closeSidebar={navigateToAccountHandler}/>
            </div>
        </div>
    </div>
}

export default SignIn