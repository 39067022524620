
import classes from './SelectedChoice.module.css'
import SelectedChoiceOption from './SelectedChoiceOption'

const SelectedChoice = ({data}) => {
    

    return <div className={classes.choice_basic}>
        <div className={classes.choice_basic_inner}>
        <div className={classes.choice_title}>
            {data.modName === "Upgrade Your Cookie Cake" ? data.modName : data.modName.replace(/\b(?:Select|Choose|Cookie Cake)/g,'').replace(/[:?]/g, '')}</div>
            {data.selectedoptions && data.selectedoptions.length > 0 && <div className={classes.picked_sub_options}>
                {data.selectedoptions.map(option => (
                    <SelectedChoiceOption key={option.id} option={option} parent={data}/>
                ))}    
            </div>}            
        </div>
    </div>
}

export default SelectedChoice