import { useState } from "react"
import MigrateToken from "./MigrateToken"
import { GetUserViaPunchMobileAP, RequestMigration } from "../../scripts/fetchApi"
import { useDispatch, useSelector } from "react-redux"
import { variousActions } from "../../store/various"


import classes from './Migrate.module.css'
import { getItemFromStorage } from "../../scripts/localStorage"


const CompleteMigrate = () => {
    const dispatch = useDispatch()
    const [nextStep, setNextStep] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const tokens = useSelector(state => state.auth.tokens)

    const storageTokens = getItemFromStorage('tokens')



    const sendTokenToEmail = async () => {

        setIsLoading(true)

        let migrate = await RequestMigration(storageTokens.authtoken, {
            access_token: storageTokens.access_token
        })



        if (migrate.message) {
            dispatch(variousActions.setError(migrate))
        } else {
            setNextStep(true)
        }
        setIsLoading(false)

    }

    return <div className={classes.min_container}>
        <div>
            {!nextStep && <>
                <div className={classes.text_container}>
                    <p>To complete your migration proccess hit the button bellow. This will send a token to your email to be verified on the next step.</p>
                </div>
                
                <div className={classes.btn_container}>
                    {!isLoading && <button className="btn" onClick={sendTokenToEmail}>Send Token</button>}
                    {isLoading && <div className="btn"><span className="loader"></span></div>}
                </div>
            </>}
            {nextStep && <MigrateToken />}
        </div>
    </div>
}

export default CompleteMigrate