import { useSelector } from 'react-redux'
import classes from './Cart.module.css'
import LocationPin from '../../icons/locationpin'
import ChangeCartDeliveryTypeButton from './ChangeCartDeliveryTypeButton'

const OrderLocationInfo = () => {
    const cart = useSelector(state => state.cart.cart)
    const hasLocation = useSelector(state => state.location.location)

    return <div className={classes.checkout_location_info}>
        <div className={classes.checkout_location_date_info_title}>
            <LocationPin />
            {cart.deliverymode === 'pickup' && <span>{cart.deliverymode + ' from'}</span>}
            {cart.deliverymode === 'curbside' && <span>{cart.deliverymode + ' from'}</span>}
            {cart.deliverymode === 'dispatch' && <span>Delivery to</span>}
        </div>
        <div className={classes.checkout_location_date_info_more}>
            {cart.deliverymode !== 'dispatch' && <>
                {hasLocation.address.street} <br />
                {hasLocation.address.city}, {hasLocation.address.state} {hasLocation.address.zip} 
            </>}
            {cart.deliverymode === 'dispatch' && <>
                {cart.deliveryaddress.streetaddress} {cart.deliveryaddress.zipcode} {cart.deliveryaddress.city} 
            </>}
        </div>
        <div className={classes.checkout_location_date_info_cta} >
            <ChangeCartDeliveryTypeButton>Change Order Type</ChangeCartDeliveryTypeButton>
        </div>
        {/* <div className={classes.checkout_location_date_info_cta}>
            <button>Change Order Type</button>
        </div> */}
    </div>
}

export default OrderLocationInfo

