import { createSlice } from "@reduxjs/toolkit";


const initialCartState = {
    items: [],
    newItems: [],
    multipleChoices: []
    // subOptions: [],
}

const virtualCartSlice = createSlice({
    name: 'virtualCart',
    initialState: initialCartState,
    reducers: {
        addnewItem(state, data) {
            state.newItems = data.payload
        },
        addMultipleChoice(state, data) {
            // state.multipleChoices = []
            state.multipleChoices = data.payload
        },
        addItem(state, item) {
            const index = state.items.findIndex(object => {
                return object.id == item.payload.id
            })
          
            if (index < 0) {
                state.items.push(item.payload)
            } else {
                // state.items[index].price = item.payload.price
                const indexOptions = state.items[index].options.findIndex(object => {
                    return object.id == item.payload.options[0].id
                })
               
                if (indexOptions < 0) {
                    // state.items[index].options.push(item.payload.options)
                    state.items[index].options = [...state.items[index].options, ...item.payload.options]
                } else {
                    state.items[index].options[indexOptions].price = item.payload.options[0].price
                    state.items[index].options[indexOptions].optionID = item.payload.options[0].optionID
                    state.items[index].options[indexOptions].subOptions = []
                }
                 
            }
        }, 
        addSubOption(state, data) {
            const index = state.items.findIndex(object => {
                return object.id == data.payload.id
            })

            const indexOptions = state.items[index].options.findIndex(object => {
                return object.optionID == data.payload.options[0].id
            })
            if (data.payload.options[0].type === 'checkbox') {
                state.items[index].options[indexOptions].subOptions.push({
                    id: data.payload.options[0].sub[0].id,
                    price: data.payload.options[0].sub[0].price,
                    name: data.payload.options[0].sub[0].name,
                }) 
            } else {
                state.items[index].options[indexOptions].subOptions = []
                state.items[index].options[indexOptions].subOptions.push({
                    id: data.payload.options[0].sub[0].id,
                    price: data.payload.options[0].sub[0].price,
                    name: data.payload.options[0].sub[0].name,
                })
            }
                       
        },
        removeSubOption(state, data) {
            const index = state.items.findIndex(object => {
                return object.id == data.payload.id
            })
            const indexOptions = state.items[index].options.findIndex(object => {
                return object.optionID == data.payload.options[0].id
            })
            const indexSubOption = state.items[index].options[indexOptions].subOptions.findIndex(object => {
                return object.id == data.payload.options[0].sub[0].id
            })
            state.items[index].options[indexOptions].subOptions.splice(indexSubOption, 1)
        },
        clearVirtualCart(state) {
            state.items = []
        }
    }
})

export const virtualCartActions = virtualCartSlice.actions

export default virtualCartSlice.reducer