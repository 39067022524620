import { useEffect, useState } from 'react'

import classes from './DateTimeAdvanced.module.css'
import DateTimeAdvanced from './DateTimeAdvanced'
import { useSelector } from 'react-redux'

import ConfirmSelection from './ConfirmSelection'

const AsapScheduleSelect = ({isCurbside}) => {

    const [scheduled, setScheduled] = useState(false)  
    const cart = useSelector(state => state.cart.cart)


    useEffect(() => {
        if (cart && cart.timemode === "advance") {
            setScheduled(true)
        } 
    }, [])
    const handleChange = (e) => {
        
        if (e.target.value === 'schedule') {
            setScheduled(true)
        } else {
            setScheduled(false)
        }        

    }

    return <>
        <div className='form-controller'>
            <fieldset>
                <legend className={`label sr-only`}>Order Time</legend>
                <div className={classes.radio_input_container}>
                    <div className={`${classes.radio_input_item}`}>
                        <input type="radio" id="orderTime_asap" name="orderTime" value="asap" checked={!scheduled} onChange={handleChange}/>
                        <label htmlFor="orderTime_asap">Asap</label>
                    </div>
                    <div className={`${classes.radio_input_item}`}>
                        <input type="radio" id="orderTime_scheduled" name="orderTime" value="schedule" checked={scheduled} onChange={handleChange}/>
                        <label htmlFor="orderTime_scheduled">Scheduled</label>
                    </div>
                </div>
            </fieldset>
        </div>
        {!scheduled && <ConfirmSelection timemode={'asap'} isCurbside={isCurbside}/>}
        {scheduled && <DateTimeAdvanced isCurbside={isCurbside}/>}
    </>
}

export default AsapScheduleSelect