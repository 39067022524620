import { createSlice } from "@reduxjs/toolkit";

const initialLocationState = {
    islocationSidebarOpened: false,
    virtualDeliveryType: 'pickup',
    location: null,
    menu: null,
    order: {
        type: 'asap',
        date: null,
        time: null
    },
    changeDeliveryModal: false,
    closedStoreWarning: false,
    foundLocations: null,
    mapSelectedLocation: {
        id: null,
        scroll: false
    }
}

const locationSlice = createSlice({
    name: 'location',
    initialState: initialLocationState,
    reducers: {
        setLocationsSideBarState(state) {
            state.islocationSidebarOpened = !state.islocationSidebarOpened
        },
        forceLocationsSideBarStateOpen(state) {
            state.islocationSidebarOpened = true
        },
        forceLocationsSideBarStateClosed(state) {
            state.islocationSidebarOpened = false
        },
        setLocationItem(state, item) {
            state.location = item.payload   
        },
        setMenuItem(state, item) {
            state.menu = item.payload
        },
        setVirtualDeliveryType(state, type) {
            state.virtualDeliveryType = type.payload
        },
        setOrderType(state, data) {
            state.order.type = data.payload
        },
        setOrderDate(state, data) {
            state.order.date = data.payload
        },
        setOrderTime(state, data) {
            state.order.time = data.payload
        },
        setChangeDelivery(state, data) {
            if (data.payload.close) {
                state.changeDeliveryModal = data.payload.value
            } else {
                state.changeDeliveryModal = !state.changeDeliveryModal
            }
            
        },
        setClosedStoreWarning(store, data) {
            store.closedStoreWarning = data.payload
        }, 
        setFoundLocations(store, data) {
            store.foundLocations = data.payload
        },
        setMapSelectedLocation(store, data) {
            store.mapSelectedLocation.id = data.payload.id
            store.mapSelectedLocation.scroll = data.payload.scroll
        }
    }
})

export const locationActions = locationSlice.actions

export default locationSlice.reducer