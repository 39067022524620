import { useRef, useState } from "react"
import Popup from '../common/Popup'
import { getGenericToken, isEmpty } from "../../scripts/helpers"
import { setFavoriteOrder } from "../../scripts/fetchApi"
import { getAuthToken } from "../../scripts/auth"
import { useDispatch } from "react-redux"
import { variousActions } from "../../store/various"

const AddToFavoriteOrders = ({orderID}) => {
    const dispatch = useDispatch()
    const nameRef = useRef(null)
    const [showModal, setShowModal] = useState(false)
    const [hasErrors, setHasErrors] = useState(false)
    const [isLoading, setIsloading] = useState(false)

    // const token = getAuthToken()

    // const token = sessionStorage.getItem('token')

    const token = getGenericToken()

    const openModalHandler = () => {
        setShowModal(prevState => prevState = !prevState)
    }

    const addTofavoritesHandler = async (event) => {
        setIsloading(true)
        event.preventDefault()

        const name = nameRef.current.value

        if (!isEmpty(name)) {
            setHasErrors(false)
            const reqBody = {
                order_id: orderID,
                name: name,
                default: 0
            }
            const fav = await setFavoriteOrder(token, reqBody)

            if (fav.message) {
                dispatch(variousActions.setError(fav))
            } else {
                dispatch(variousActions.setError({
                    message: 'Order successfully added to favorites!',
                    type: "success"
                }))
                openModalHandler()
            }
        } else {
            setHasErrors(true)
        }
        
        setIsloading(false)
    }


    return <>
        <button className='btn btn-small' onClick={openModalHandler} title="add this order to favorites">
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M225.8 468.2l-2.5-2.3L48.1 303.2C17.4 274.7 0 234.7 0 192.8v-3.3c0-70.4 50-130.8 119.2-144C158.6 37.9 198.9 47 231 69.6c9 6.4 17.4 13.8 25 22.3c4.2-4.8 8.7-9.2 13.5-13.3c3.7-3.2 7.5-6.2 11.5-9c0 0 0 0 0 0C313.1 47 353.4 37.9 392.8 45.4C462 58.6 512 119.1 512 189.5v3.3c0 41.9-17.4 81.9-48.1 110.4L288.7 465.9l-2.5 2.3c-8.2 7.6-19 11.9-30.2 11.9s-22-4.2-30.2-11.9zM239.1 145c-.4-.3-.7-.7-1-1.1l-17.8-20c0 0-.1-.1-.1-.1c0 0 0 0 0 0c-23.1-25.9-58-37.7-92-31.2C81.6 101.5 48 142.1 48 189.5v3.3c0 28.5 11.9 55.8 32.8 75.2L256 430.7 431.2 268c20.9-19.4 32.8-46.7 32.8-75.2v-3.3c0-47.3-33.6-88-80.1-96.9c-34-6.5-69 5.4-92 31.2c0 0 0 0-.1 .1s0 0-.1 .1l-17.8 20c-.3 .4-.7 .7-1 1.1c-4.5 4.5-10.6 7-16.9 7s-12.4-2.5-16.9-7z"/></svg>
            <span className="sr-only">add to favorites</span>
        </button>
        {showModal && <Popup closeInfoHandler={openModalHandler} transition={showModal}>
            <h3>Just a step to better remember your order</h3>
            <form onSubmit={addTofavoritesHandler}>
                <div className="form-fields-container">
                    <div className="form-controller">
                        <label className="label">Set a name for this order</label>
                        <input ref={nameRef} type="text" id="favorite_order_name" name="favorite_order_name" placeholder="My favorite" required className={`${hasErrors ? 'error' : null}`}/>
                        {hasErrors && <div className='error-message'>Please enter a name for your order</div>}
                    </div>
                    <div className="form-controller">
                        {!isLoading && <button className="btn">Add to favorites!</button>}
                        {isLoading && <div className="btn"><span className="loader"></span></div>}
                    </div>
                </div>
            </form>
        </Popup>}
    </>
}

export default AddToFavoriteOrders