import { useSelector } from 'react-redux';
import ProductModifiers from './ProductModifiers';
import AddToCart from './AddToCart';
import ConfirmAllSelections from './ConfirmAllSelections';
import SpecialInstructions from './SpecialInstructions';
import { useEffect } from 'react';
import { useMediaQuery } from '../../../hooks/hooks';
import classes from './ProductContainer.module.css'
import SelectedOptionsContainer from './SelectedOptionsContainer';

const ProductContainer = ({specialinstructionsText}) => {
    const mod = useSelector(state => state.productDetails.data)

    const screen640andlarger = useMediaQuery('sm')

    useEffect(() => {
        //add the index number for options
        const h = document.querySelectorAll('.option-header')
        if (h.length) {
            h.forEach((item, index) => {
                item.querySelector('.header-index').innerHTML = index+1 + '. '
            })
        }
    }, [mod]) 

    return <>
        {mod && <>
            <ProductModifiers modifiers={mod}/>
            <SpecialInstructions specialinstructionsText={specialinstructionsText}/>
            {screen640andlarger && <ConfirmAllSelections />}
            {!screen640andlarger && <div className={classes.mobile_footer_container}>
                <SelectedOptionsContainer />  
                <ConfirmAllSelections />  
            </div>}
            {/* <ConfirmAllSelections /> */}
            <AddToCart />
        </>}  
    </>
}

export default ProductContainer