import ModifierTitle from "./ModifierTitle"
import Options from "./Options"
import OptionsAggregate from "./OptionsAggregate"

import classes from './ProductModifiers.module.css'
import { useDispatch, useSelector } from "react-redux"
import { productDetailsActions } from "../../../store/productDetails"


function removeOpenedDrawer(dataToSearch) {
    dataToSearch.forEach(item => item.drawerOpened = false)
    dataToSearch.forEach(element => {
        element.options.forEach(option => {
            if (option.modifiers) {  
                removeOpenedDrawer(option.modifiers)                 
            }
        });
    });
}
function searchForId(dataToSearch, modifierId) {
    let foundIndex = dataToSearch.findIndex(x => x.id === modifierId); 

    removeOpenedDrawer(dataToSearch)
    if (foundIndex >=0 ) {
        dataToSearch[foundIndex].drawerOpened = true
    } else {        
        dataToSearch.forEach(element => {
            element.options.forEach(option => {
                if (option.modifiers) {
                    searchForId(option.modifiers, modifierId)                   
                }
            });
        });
    }

    return dataToSearch
}


function searchForIdClose(dataToSearch) {

    removeOpenedDrawer(dataToSearch)    

    return dataToSearch
}


const ProductModifier = ({modifier}) => {
    const dispatch = useDispatch()
    const productDetails = useSelector(state => state.productDetails.data)
    let data = JSON.parse(JSON.stringify(productDetails));

    const setDrawerhandler = () => {
        const modifiedData = searchForId(data, modifier.id)
        dispatch(productDetailsActions.createProduct(modifiedData))
    }


    const setDrawerClosedHandler = () => {
        const modifiedData = searchForIdClose(data)

        // console.log('modifiedData::: ', modifiedData)
        dispatch(productDetailsActions.createProduct(modifiedData))
    }
    

    return <div className={classes.modifier_item}>   
        <ModifierTitle modifier={modifier} setDrawer={setDrawerhandler} setDrawerClosed={setDrawerClosedHandler}/>
        {modifier.options && !modifier.maxaggregatequantity && <Options options={modifier.options} parentModifier={modifier} />}
        {modifier.options && modifier.maxaggregatequantity && <OptionsAggregate options={modifier.options} parentModifier={modifier}/>}
    </div>
}

export default ProductModifier