import { useState, useEffect } from 'react'
import classes from './DateTimeAdvanced.module.css'

import DateTimeAdvanced from './DateTimeAdvanced'
import { useDispatch, useSelector } from 'react-redux'
import { locationActions } from '../../store/location'

const AsapScheduleDeliverySelect = ({setTimeMode, setDateHandler, setTimeHandler}) => {
    const dispatch = useDispatch()
    const cart = useSelector(state => state.cart.cart)
    const [scheduled, setScheduled] = useState(false)  

    useEffect(() => {
        if (cart && cart.timemode === "advance") {
            setScheduled(true)
            setTimeMode(true)
        }
    }, [])

    const handleChange = (e) => {

        if (e.target.value === 'schedule') {
            setScheduled(true)
            setTimeMode(true)
        } else {
            setScheduled(false)
            setTimeMode(false)
        }      
        
        dispatch(locationActions.setOrderType(e.target.value))

    }

    return <>
        <div className='form-controller'>
            <fieldset>
                <legend className={`label sr-only`}>Order Time</legend>
                <div className={classes.radio_input_container}>
                    <div className={`${classes.radio_input_item}`}>
                        <input type="radio" id="orderTime_asap" name="orderTime" value="asap" checked={!scheduled} onChange={handleChange}/>
                        <label htmlFor="orderTime_asap">Asap</label>
                    </div>
                    <div className={`${classes.radio_input_item}`}>
                        <input type="radio" id="orderTime_scheduled" name="orderTime" value="schedule" checked={scheduled} onChange={handleChange}/>
                        <label htmlFor="orderTime_scheduled">Scheduled</label>
                    </div>
                </div>
            </fieldset>
        </div>
        {scheduled && <div className='form-controller'>
            <DateTimeAdvanced isCurbside={false} isDelivery={true} setDate={setDateHandler} setTime={setTimeHandler}/>    
        </div>}
    </>

}

export default AsapScheduleDeliverySelect