import { useEffect, useState } from 'react'
import { getAvailableCartRewards } from '../../scripts/fetchApi'
import { useSelector } from 'react-redux'
import { getAuthToken } from '../../scripts/auth'

import '@splidejs/react-splide/css';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import classes from './AlternativePayment.module.css'
import RewardItem from './RewardItem'
import ArrowSliderLeftIcon from "../../icons/arrowsliderleft";
import ArrowSliderRightIcon from "../../icons/arrowsliderright";
import { getGenericToken } from '../../scripts/helpers';

const Rewards = () => {
    
    const [showDrawer, setShowDrawer] = useState(false)

    const [availableRewards, setAvailableRewards] = useState(null)

    let cart = useSelector(state => state.cart.cart)
    // let token = getAuthToken()
    let token = getGenericToken()

    useEffect(() => {
        setShowDrawer(false)
        const getRewards = async () => {
            const rewards = await getAvailableCartRewards(token, cart.id)
            setAvailableRewards(rewards)
            setShowDrawer(true)
        }

        getRewards()
        
    }, [token, cart])   


    return <div className={classes.drawer}>
        {!showDrawer && <span className='loader dark-raspberry medium'></span>}
        {showDrawer && <div className={classes.rewards_container}>
            <div className={classes.rewards_header}>
                {availableRewards && availableRewards.length>0 && <h4 className={classes.rewards_header_title}>Rewards Wallet</h4>}
                {availableRewards && availableRewards.length===0 && <h4 className={classes.rewards_header_title}>No Rewards Available</h4>}
            </div>
            {cart.appliedrewards && cart.appliedrewards.length > 0 && <div className={classes.applied_rewards}>
                <p className='text-center'>Applied rewards: </p>
                <RewardItem key={cart.appliedrewards[0].rewardid} reward={cart.appliedrewards[0]}/>             
            </div>}
            {cart.appliedrewards && cart.appliedrewards.length === 0 && <>
                {availableRewards && availableRewards.length>0 && <p className='text-center'>Choose one to apply to your order.</p>}
                {availableRewards && availableRewards.length>0 && <div className={classes.rewards_items}>
                    <Splide className='splide-custom-arrows' aria-label="Rewards" hasTrack={ false } options={ {
                        rewind: false,
                        perPage: 2,
                        pagination: false,
                        gap   : '1rem',
                        arrows: true,
                    } }>
                        <SplideTrack>
                            {availableRewards.map(reward => (
                                <SplideSlide key={reward.reference}>
                                    <RewardItem  reward={reward}/>
                                </SplideSlide>
                            ))} 
                        </SplideTrack>
                         
                        {/* <div className="splide__arrows" /> */}
                        <div className="splide__arrows">
                            <button className="splide__arrow splide__arrow--prev">
                                <ArrowSliderLeftIcon />
                                <span className="sr-only">Prev</span>
                            </button>
                            <button className="splide__arrow splide__arrow--next">
                                <ArrowSliderRightIcon />
                                <span className="sr-only">Next</span>
                            </button>
                        </div>
                    </Splide>
                                  
                </div>}
            </>}
        </div>}
    </div>
}

export default Rewards