import { useRef, useEffect, useState } from 'react';

import classes from './Header.module.css'
import { useMediaQuery } from '../../hooks/hooks';

const HeaderWrapper = ({children}) => {
    const wrapperRef = useRef(null);

    const [pageTouched, setPageTouched] = useState(false)
    const [headerSticky, setHeaderSticky] = useState(false);

    const screen1024andLarger = useMediaQuery('lg')

    useEffect(() => {

        let lastScrollTop = 200;
        const isSticky = () => {
            const header = wrapperRef.current;    
            setPageTouched(true)
            if (header != undefined && header != null) {
                var st = window.scrollY || document.documentElement.scrollTop; 
                if (st > lastScrollTop) {
                    setHeaderSticky(false)       
                } else if (st < lastScrollTop) {
                    setHeaderSticky(true)
                } // else was horizontal scroll
                lastScrollTop = st <= 200 ? 200 : st;
            }
            
        }; 
        
        window.addEventListener("scroll", isSticky);
       
        return () => {
            window.removeEventListener("scroll", isSticky);
        };
    }, []);


    return <>
        {/* {screen1024andLarger && <header className={`${classes.header}`} ref={wrapperRef}>
            {children}
        </header>}
        {!screen1024andLarger && <header className={`${classes.header} ${pageTouched ? (headerSticky ? `${classes.header_sticky} header-sticky` : `${classes.header_hide} header-hide`) : ''}`} ref={wrapperRef}>
            {children}
        </header>} */}

        <header className={`${classes.header} ${pageTouched ? (headerSticky ? `${classes.header_sticky} header-sticky` : `${classes.header_hide} header-hide`) : ''}`} ref={wrapperRef}>
            {children}
        </header>
    </>
}

export default HeaderWrapper