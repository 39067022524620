import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { cartActions } from '../../store/cart'
import { setSessionStorageItem, setStorageItem } from '../../scripts/localStorage'


import classes from './CartItem.module.css'
import { deleteProductInCart, updateProductInCart, validateCart } from '../../scripts/fetchApi'
import { variousActions } from '../../store/various'
import { formatNumberDecimal } from '../../scripts/helpers'
import CartItemImage from './CartItemImage'
import CartItemEdit from './CartItemEdit'

const CartItem = ({data}) => {

    const dispatch = useDispatch()

    const [inProgress, setInProgress] = useState(false)
    const [incrementItemLoading, setIncrementItemLoading] = useState(false)
    const [decrementItemLoading, setDecrementItemLoading] = useState(false)

    const dataLayerRemoveFromCart = () => {
        window.dataLayer = window.dataLayer || [];                    
        window.dataLayer.push({
        event: "remove_from_cart",
        ecommerce: {
            currency: "USD", // e.g. "USD",
            value: data.totalcost, // e.g. 7.77,
            items: [{
                item_id: data.productId, 
                item_name: data.name, 
                // coupon: null, 
                // discount: null, // e.g. 2.22,
                // item_category: <item category>, // e.g. "Apparel",
                // item_list_id: <item list id>, // e.g. "related_products",
                // item_list_name: <item list name>, // e.g. "Related Products",
                price: data.totalcost, // e.g. 9.99,
                quantity: data.quantity, // e.g. 1
            }]
        }
        });
    }


    let cart = useSelector(state => state.cart.cart)


    const removeItemHandler = async (id) => {
        setInProgress(true)

        const removeProd =  await deleteProductInCart(cart.id, id)

        if (removeProd.id) {
            // setStorageItem('currentCart', removeProd)
            setSessionStorageItem('currentCart', removeProd)
            dispatch(cartActions.addCart(removeProd))
            // START GTM DATA
            dataLayerRemoveFromCart()
            // END GTM DATA
        } else {
            dispatch(variousActions.setError(removeProd))
        }

        

        setInProgress(false)
    }

    const addOneItemHandler = async (id) => {
        setIncrementItemLoading(true)
        const product = cart.products.find(item => item.id == id)

        let options = [];
        product.choices.forEach(item => {
            options.push(item.optionid)
        })
        const reqObject = {
            quantity: product.quantity + 1,
            product_id: product.id,
            special_instructions: product.specialinstructions,
            options: options.join(','),
            // choice_custom_fields: choice_custom_fields
        }
        const updatedCart = await updateProductInCart(cart.id, id, reqObject)

        if (updatedCart.id) {
            // setStorageItem('currentCart', updatedCart)
            setSessionStorageItem('currentCart', updatedCart)
            dispatch(cartActions.addCart(updatedCart))
            // validateCartHandler(updatedCart.id)
            
            
        } else {
            dispatch(variousActions.setError(updatedCart))
        }

        setIncrementItemLoading(false)
    }

    const removeOneItemHandler = async (id) => {
        setDecrementItemLoading(true)

        if (data.quantity > 1) {            
            const product = cart.products.find(item => item.id == id)

            let options = [];
            product.choices.forEach(item => {
                options.push(item.optionid)
            })
            const reqObject = {
                quantity: product.quantity - 1,
                product_id: product.id,
                special_instructions: product.specialinstructions,
                options: options.join(','),
                // choice_custom_fields: choice_custom_fields
            }
            const updatedCart = await updateProductInCart(cart.id, id, reqObject)

            if (updatedCart.id) {
                // setStorageItem('currentCart', updatedCart)
                setSessionStorageItem('currentCart', updatedCart)
                dispatch(cartActions.addCart(updatedCart))
                // validateCartHandler(updatedCart.id)
            } else {
                dispatch(variousActions.setError(updatedCart))
            }   
        } else {

            const removeProd =  await deleteProductInCart(cart.id, id)

            if (removeProd.id) {
                // setStorageItem('currentCart', removeProd)
                setSessionStorageItem('currentCart', removeProd)
                dispatch(cartActions.addCart(removeProd))

            } else {
                dispatch(variousActions.setError(removeProd))
            }

        }

        // START GTM DATA
        dataLayerRemoveFromCart()
        // END GTM DATA
        setDecrementItemLoading(false)
    }

    const choices = data.choices.map(item => {
        return item.name
    })

    return <div className={classes.item}>
        <CartItemImage data={data}/>
        <div className={classes.text}>
            {!inProgress && <button className={classes.remove_item} onClick={removeItemHandler.bind(this, data.id)}>
                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.32812 6.61328C6.53906 6.40234 6.92578 6.40234 7.13672 6.61328L9 8.47656L10.8281 6.61328C11.0391 6.40234 11.4258 6.40234 11.6367 6.61328C11.8477 6.82422 11.8477 7.21094 11.6367 7.42188L9.77344 9.25L11.6367 11.1133C11.8477 11.3242 11.8477 11.7109 11.6367 11.9219C11.4258 12.1328 11.0391 12.1328 10.8281 11.9219L9 10.0586L7.13672 11.9219C6.92578 12.1328 6.53906 12.1328 6.32812 11.9219C6.11719 11.7109 6.11719 11.3242 6.32812 11.1133L8.19141 9.25L6.32812 7.42188C6.11719 7.21094 6.11719 6.82422 6.32812 6.61328ZM18 9.25C18 14.2422 13.957 18.25 9 18.25C4.00781 18.25 0 14.2422 0 9.25C0 4.29297 4.00781 0.25 9 0.25C13.957 0.25 18 4.29297 18 9.25ZM9 1.375C4.64062 1.375 1.125 4.92578 1.125 9.25C1.125 13.6094 4.64062 17.125 9 17.125C13.3242 17.125 16.875 13.6094 16.875 9.25C16.875 4.92578 13.3242 1.375 9 1.375Z" fill="var(--color-dark-raspberry)"/>
                </svg>
                <span className='sr-only'>remove item from cart</span>
            </button>}
            {inProgress && <div className={classes.remove_item}><span className="loader dark-raspberry"></span></div>}
            
            <div className={classes.title}>{data.name?.toLowerCase()}</div>
            <div className={classes.description}>{choices.join(', ')}</div>
            {data.specialinstructions !== "" && <div className={classes.special_instructions}>
                <span>Special Instructions:</span> {data.specialinstructions}
            </div>}
            {/* {data.choices && data.choices.filter(item => item.customfields).map(choice => (
                <div className={classes.custom_fields} key={choice.id}>
                    {choice.customfields && choice.customfields.map(field => (
                        <div key={field.fieldid}>{choice.name}: "{field.value}"</div>
                    ))}
                </div>
            ))} */}
            
            {/* <CartItemEdit itemData={data}/> */}
            <div className={classes.item_footer}>
                <div className={classes.cart_item_operation}>
                    {!decrementItemLoading && <button onClick={removeOneItemHandler.bind(this, data.id)} className={classes.increment_decrement}>
                        <svg width="12" height="2" viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.8125 0.75C11.8125 0.996094 11.5938 1.1875 11.375 1.1875H0.875C0.628906 1.1875 0.4375 0.996094 0.4375 0.777344C0.4375 0.53125 0.628906 0.3125 0.875 0.3125H11.375C11.5938 0.3125 11.8125 0.53125 11.8125 0.75Z" fill="#242C37"/>
                        </svg>
                        <span className='sr-only'>remove one item</span>
                    </button>}
                    {decrementItemLoading && <span className={classes.increment_decrement}><span className="loader dark-raspberry"></span></span>}
                    <div className={classes.item_count}>{data.quantity}</div>
                    {!incrementItemLoading && <button onClick={addOneItemHandler.bind(this, data.id)} className={classes.increment_decrement}>
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.8125 5.75C11.8125 5.99609 11.5938 6.1875 11.375 6.1875H6.5625V11C6.5625 11.2461 6.34375 11.4648 6.125 11.4648C5.87891 11.4648 5.6875 11.2461 5.6875 11V6.1875H0.875C0.628906 6.1875 0.4375 5.99609 0.4375 5.77734C0.4375 5.53125 0.628906 5.3125 0.875 5.3125H5.6875V0.5C5.6875 0.28125 5.87891 0.0898438 6.125 0.0898438C6.34375 0.0898438 6.5625 0.28125 6.5625 0.5V5.3125H11.375C11.5938 5.3125 11.8125 5.53125 11.8125 5.75Z" fill="#242C37"/>
                        </svg>
                        <span className='sr-only'>add one item</span>
                    </button>}
                    {incrementItemLoading && <span className={classes.increment_decrement}><span className="loader dark-raspberry"></span></span>}
                    

                </div>
                <div className={classes.price}>${formatNumberDecimal(data.totalcost)}</div>
            </div>
        </div>
    </div>
}

export default CartItem