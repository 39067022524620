import { useEffect, useState } from "react"
import { getDistanceFromLatLonInKm, useGetLocation } from "../../hooks/hooks";
import { useDispatch, useSelector } from "react-redux";
import { variousActions } from "../../store/various";
import { getLocationsByLatLng, getLocationsByLatLngWithDate } from "../../scripts/fetchApi";
import Crosshair from "../../icons/crosshair";

import classes from './GetCurrentLocationButton.module.css'

import Radar from "radar-sdk-js";

const GetCurrentLocationButton = ({deliveryType, foundLocations, noLocation}) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const getLocation = useGetLocation();

    let orderType = useSelector(state => state.location.order)

    // useEffect(() => {
    //     Radar.initialize(process.env.REACT_APP_RADAR_MAPS, {});
    // }, [])

    const getCurrentLocationHandler = async () => {


        setLoading(true)
        Radar.initialize(process.env.REACT_APP_RADAR_MAPS, {});
        Radar.ipGeocode()
        .then((result) => {
            const { ip, address, proxy } = result;

            if (result && result.address && result.address.latitude) {
                let coord = {
                    lat: result.address.latitude,
                    lng: result.address.longitude,
                }
                async function getLocations() {
                    let loc

                    if (orderType.type === 'asap') {
                        loc = await getLocationsByLatLng(coord, deliveryType)
                    } else {
                        loc = await getLocationsByLatLngWithDate(coord, deliveryType, orderType.date, orderType.time)
                    }
    
                    if (loc.length > 0) {
                        loc.forEach(element => {
                            element.distanceFromSearch = (getDistanceFromLatLonInKm(element.address.latitude, element.address.longitude, coord.lat, coord.lng) * 0.621371).toFixed(2)
                        });
                        foundLocations(loc)
                        noLocation(true)
                        setLoading(false)
                    } else {
                        noLocation(true)
                        foundLocations(null)
                        setLoading(false)
                    }
                    setLoading(false) 
                }

                getLocations()
            }
            // do something with results
        })
        .catch((err) => {
            // handle error
            dispatch(variousActions.setError({message: 'Something went wrong, please try again'}))
            setLoading(false) 
        });
 
    }


    return <div className={classes.container}>
        <button className="btn btn-large" onClick={getCurrentLocationHandler}>
            {loading && <span className="loader small"></span>}                    
            {!loading && <Crosshair />}
            <span>Use my location</span>
        </button>
    </div>
}

export default GetCurrentLocationButton