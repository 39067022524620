import classes from './BuilderImage.module.css'
import { useEffect, useRef, useState } from 'react';

// import 'context-filter-polyfill/dist/index.js'

import shapeSquare from '../../../assets/base/square/Original-Chocolate-Chip.jpg'
import shapeRound from '../../../assets/base/round/Original-Chocolate-Chip.jpg'
import shapeRect from '../../../assets/base/rect/Original-Chocolate-Chip.jpg'
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { OffscreenCanvasPolly } from '../../../scripts/helpers';


let selectedShape = 'square'
const squareBaseImages = require.context('../../../assets/base/square/', true)
const squareBaseImagesList = squareBaseImages.keys().map(image => squareBaseImages(image));

const rectBaseImages = require.context('../../../assets/base/rect/', true)
const rectBaseImagesList = rectBaseImages.keys().map(image => rectBaseImages(image));

const roundBaseImages = require.context('../../../assets/base/round/', true)
const roundBaseImagesList = roundBaseImages.keys().map(image => roundBaseImages(image));

const redRoundDollops = require.context('../../../assets/dollops/round/red', true)
const redRoundDollopsList = redRoundDollops.keys().map(image => redRoundDollops(image));

const whiteRoundDollops = require.context('../../../assets/dollops/round/white', true)
const whiteRoundDollopsList = whiteRoundDollops.keys().map(image => whiteRoundDollops(image));


const redSquareDollops = require.context('../../../assets/dollops/square/red', true)
const redSquareDollopsList = redSquareDollops.keys().map(image => redSquareDollops(image));

const whiteSquareDollops = require.context('../../../assets/dollops/square/white', true)
const whiteSquareDollopsList = whiteSquareDollops.keys().map(image => whiteSquareDollops(image));

const redRectDollops = require.context('../../../assets/dollops/rect/red', true)
const redRectDollopsList = redRectDollops.keys().map(image => redRectDollops(image));

const whiteRectDollops = require.context('../../../assets/dollops/rect/white', true)
const whiteRectDollopsList = whiteRectDollops.keys().map(image => whiteRectDollops(image));

const colorArr = [
    {
        color: 'Vanilla',
        noChange: true,
        hue: null,
        base: 'white',
        hsl: null,        
    }, {
        color: 'Chocolate',
        hue: 'hue-rotate(10deg)',
        base: 'red',
        hsl: 'hsl(22, 20%, 50%)',
        // filter: 'brightness(0) saturate(100%) invert(19%) sepia(13%) saturate(2437%) hue-rotate(341deg) brightness(97%) contrast(96%)'
        filter: 'hue-rotate(39deg) saturate(1) brightness(.6)'
    }, {
        color: 'Red',
        noChange: true,
        hue: null,
        base: 'red',
        hsl: null,
    }, {
        color: 'Blue',
        hue: 'hue-rotate(190deg)',
        base: 'red',
        hsl: 'hsl(0, 20%, 50%)',
        // filter: 'brightness(0) saturate(100%) invert(51%) sepia(73%) saturate(350%) hue-rotate(158deg) brightness(99%) contrast(97%)'
        filter: 'hue-rotate(207deg) saturate(1.2) brightness(2.2)'
    }, {
        color: 'Orange',
        hue: 'hue-rotate(30deg)',
        base: 'red',
        hsl: 'hsl(0, 20%, 50%)',
        // filter: 'brightness(0) saturate(100%) invert(30%) sepia(97%) saturate(1244%) hue-rotate(347deg) brightness(99%) contrast(91%)'
        filter: 'hue-rotate(28deg) saturate(1.8) brightness(2.8)'
    }, {
        color: 'Green',
        hue: 'hue-rotate(90deg)',
        base: 'red',
        hsl: 'hsl(0, 20%, 50%)',
        // filter: 'brightness(0) saturate(100%) invert(57%) sepia(28%) saturate(916%) hue-rotate(47deg) brightness(95%) contrast(91%)'
        filter: 'hue-rotate(99deg) saturate(.6) brightness(2.5)'
    }, {
        color: 'Pink',
        hue: 'hue-rotate(300deg)',
        base: 'red',
        hsl: 'hsl(0, 20%, 50%)',
        // filter: 'brightness(0) saturate(100%) invert(40%) sepia(60%) saturate(3645%) hue-rotate(326deg) brightness(98%) contrast(82%)'
        filter: 'hue-rotate(353deg) saturate(1) brightness(2.3)'
    }, {
        color: 'Yellow',
        hue: 'hue-rotate(60deg)',
        base: 'red',
        hsl: 'hsl(0, 20%, 50%)',
        // filter: 'brightness(0) saturate(100%) invert(86%) sepia(72%) saturate(429%) hue-rotate(353deg) brightness(93%) contrast(98%)'
        filter: 'hue-rotate(65deg) saturate(.7) brightness(3)'
    }, {
        color: 'Dark Purple',
        hue: 'hue-rotate(270deg)',
        base: 'red',
        hsl: 'hsl(0, 20%, 50%)',
        // filter: 'brightness(0) saturate(100%) invert(19%) sepia(10%) saturate(7356%) hue-rotate(219deg) brightness(97%) contrast(105%)'
        filter: 'hue-rotate(268deg) saturate(1.7) brightness(.8)'
    }, {
        color: 'Dark Green',
        hue: 'hue-rotate(120deg)',
        base: 'red',
        hsl: 'hsl(0, 20%, 50%)',
        // filter: 'brightness(0) saturate(100%) invert(43%) sepia(15%) saturate(1103%) hue-rotate(64deg) brightness(98%) contrast(95%)'
        filter: 'hue-rotate(123deg) saturate(.4) brightness(2)'
    }, {
        colorName: 'Dark Blue',
        hue: 'hue-rotate(120deg)',
        base: 'red',
        hsl: 'hsl(0, 20%, 50%)',
        filter: 'brightness(0) saturate(100%) invert(23%) sepia(28%) saturate(6293%) hue-rotate(193deg) brightness(97%) contrast(101%)'
    }
]

const textColor = [
    {
        colorName: 'Vanilla',
        color: 'hsl(49.3,87.5%,93.7%)',
        trueColor: "#fdf8e1",
    }, {
        colorName: 'Chocolate',
        color: 'hsl(24.2,59.6%,20.4%)',
        trueColor: "#532e15",
    }, {
        colorName: 'Red',
        color: 'hsl(358.9,68%,52.2%)',
        trueColor: "#d83235",
    }, {
        colorName: 'Blue',
        color: 'hsl(201.7,64.4%,57.1%)',
        trueColor: "#4ba5d8",
    }, {
        colorName: 'Orange',
        color: 'hsl(12.9,74.4%,51%)',
        trueColor: "#df4d25",
    }, {
        colorName: 'Green',
        color: 'hsl(89.7,52.6%,42.2%)',
        trueColor: "#6ca433",
    }, {
        colorName: 'Pink',
        color: 'hsl(349.7,73.1%,59.2%)',
        trueColor: "#e34b65",
    }, {
        colorName: 'Yellow',
        color: 'hsl(57.7,79.9%,61%)',
        trueColor: "#ebe54c",
    }, {
        colorName: 'Dark Purple',
        color: 'hsl(246.3,53.8%,31.4%)',
        trueColor: "#2e257b",
    }, {
        colorName: 'Dark Green',
        color: 'hsl(110.5,28.9%,38.6%)',
        trueColor: "#4f7f46",
    }, {
        colorName: 'Dark Blue',
        color: 'hsl(207.8,100%,34.7%)',
        trueColor: "#005fb1",
    }
]

function DrawText(canvas, ctx, textColorObj, customText) {
    ctx.font = '46px Intro Script Bold';                
    var lineheight = 60;

    if (window.innerWidth < 1441) {
        ctx.font = '36px Intro Script Bold';
        lineheight = 50;
    }
    if (window.innerWidth < 1200) {
        ctx.font = '26px Intro Script Bold';
        lineheight = 40;
    }

    if (window.innerWidth < 768) {
        ctx.font = '23px Intro Script Bold';
        lineheight = 30;
    }

    // if (window.innerWidth < 768) {
    //     ctx.font = '16px Intro Script Bold';
    //     lineheight = 30;
    // }
    // if (window.innerWidth < 480) {
    //     ctx.font = '14px Intro Script Bold';
    //     lineheight = 20;
    // }

    ctx.lineWidth=10;
    if (window.innerWidth < 768) {
        ctx.lineWidth=6
    }


    ctx.filter = 'none'
    ctx.textAlign = "center";
    ctx.textBaseline = 'middle';
    ctx.strokeStyle = textColorObj.baseColor;
    ctx.fillStyle = textColorObj.topColor;
    // canvas.style.webkitFontSmoothing="antialiased"
    // ctx.fillStyle = textColorObj.baseColor
    function draw3dText(context, text, x, y, textDepth){
        var n;
        ctx.save();
        
        // console.log('text, x, y::: ', text, x, y)
        // draw bottom layers
        for (n = 0; n < textDepth; n++) {
            context.fillText(text, x - n, y - n);
        }
        
        // draw top layer with shadow casting over
        // bottom layers
        // context.fillStyle = 'red';
        // context.shadowColor = 'black';
        context.fillStyle = textColorObj.topColor;
        context.shadowColor = textColorObj.baseColor;
        
        context.shadowBlur = 2;
        context.shadowOffsetX = textDepth + 2;
        context.shadowOffsetY = textDepth + 2;
        context.fillText(text, x - n, y - n);
        // ctx.translate(x,y);
        ctx.restore();
    }

    
    if (customText) {
        let cText = customText.trim().replace(/\s\s+/g, ' ')
        
        let strArr = []


        function checkWord(word, arr, fullString, incrementValue) {
            // let incrementValue = 1
            if (incrementValue <= arr.length) {  //incrementValue <= arr.length
                if (word.length > 6) {                
                    strArr.push(word.trim())
                    var re = new RegExp(word.trim(),"g");             
                    let leftover = fullString.replace(re,'');                
                    dealWithText(leftover)
                } else {
                    if (arr.length === 1) {
                        strArr.push(word.trim())
                    } else {                    
                        incrementValue += 1
                        let newGroup = ''
                        for (let i = 0; i<incrementValue; i++) {
                            newGroup = newGroup + ' ' + arr[i]
                        }                    
                        checkWord(newGroup, arr, fullString, incrementValue)
                    }
                }
            }
        }
        function dealWithText(str) {
            let incrementValue = 1
            if (str !== '') {
                let trimmedText = str.trim().replace(/\s\s+/g, ' ')
                
                let word = trimmedText.split(' ')[0]
                let wordArr = trimmedText.split(' ')   
    
                checkWord(word, wordArr, trimmedText, incrementValue)
            }
        }

        dealWithText(cText)

        let t = strArr.join(' ')
        let textArr = [...strArr, cText.replace(t, '')].filter(n => n)
    

        if (textArr.length % 2 === 0) {
            for (let i = 0; i < textArr.length; i++) {                            
                if (i < (textArr.length/2)) {
                    ctx.strokeText(textArr[i], canvas.width/2, canvas.height/2 - ((Math.floor(textArr.length/2) - i-.5)*lineheight))
                    // draw3dText(ctx, textArr[i], canvas.width / 2, canvas.height/2 - ((Math.floor(textArr.length/2) - i-.5)*lineheight), 5)
                } else if (i >= (textArr.length/2)) {
                    ctx.strokeText(textArr[i], canvas.width/2, canvas.height/2 + ((i - Math.floor(textArr.length/2) + .5)*lineheight))
                    // draw3dText(ctx, textArr[i], canvas.width / 2, canvas.height/2 + ((i - Math.floor(textArr.length/2) + .5)*lineheight), 5)
                }                                 
            }
            for (let i = 0; i < textArr.length; i++) {
                if (i < (textArr.length/2)) {
                    ctx.fillText(textArr[i], canvas.width/2, canvas.height/2 - ((Math.floor(textArr.length/2) - i-.5)*lineheight))
                    // draw3dText(ctx, textArr[i], canvas.width / 2, canvas.height/2 - ((Math.floor(textArr.length/2) - i-.5)*lineheight), 5)
                } else if (i >= (textArr.length/2)) {
                    ctx.fillText(textArr[i], canvas.width/2, canvas.height/2 + ((i - Math.floor(textArr.length/2) + .5)*lineheight))
                    // draw3dText(ctx, textArr[i], canvas.width / 2, canvas.height/2 + ((i - Math.floor(textArr.length/2) + .5)*lineheight), 5)
                }                                 
            }
        } else {
            for (let i = 0; i < textArr.length; i++) {                            
                if (i < (textArr.length/2)) {
                    ctx.strokeText(textArr[i], canvas.width/2, canvas.height/2 - ((Math.floor(textArr.length/2) - i)*lineheight))
                    // draw3dText(ctx, textArr[i], canvas.width / 2, canvas.height/2 - ((Math.floor(textArr.length/2) - i)*lineheight), 5)
                } else if (i > (textArr.length/2)) {
                    ctx.strokeText(textArr[i], canvas.width/2, canvas.height/2 + ((i - Math.floor(textArr.length/2))*lineheight))
                    // draw3dText(ctx, textArr[i], canvas.width / 2, canvas.height/2 + ((i - Math.floor(textArr.length/2))*lineheight), 5)
                } else {
                    ctx.strokeText(textArr[i], canvas.width/2, canvas.height/2 )
                    // draw3dText(ctx, textArr[i], canvas.width / 2, canvas.height/2, 5)
                }                                
            }
            for (let i = 0; i < textArr.length; i++) {
                if (i < (textArr.length/2)) {
                    ctx.fillText(textArr[i], canvas.width/2, canvas.height/2 - ((Math.floor(textArr.length/2) - i)*lineheight))
                    // draw3dText(ctx, textArr[i], canvas.width / 2, canvas.height/2 - ((Math.floor(textArr.length/2) - i)*lineheight), 5)
                } else if (i > (textArr.length/2)) {
                    ctx.fillText(textArr[i], canvas.width/2, canvas.height/2 + ((i - Math.floor(textArr.length/2))*lineheight))
                    // draw3dText(ctx, textArr[i], canvas.width / 2, canvas.height/2 + ((i - Math.floor(textArr.length/2))*lineheight), 5)
                } else {
                    ctx.fillText(textArr[i], canvas.width/2, canvas.height/2)
                    // draw3dText(ctx, textArr[i], canvas.width / 2, canvas.height/2, 5)
                }                                
            }
        }
        

    } else {
        ctx.strokeText("Your", canvas.width/2, canvas.height/2 - lineheight);
        ctx.strokeText("Message", canvas.width/2, canvas.height/2);
        ctx.strokeText("Here", canvas.width/2, canvas.height/2 + lineheight);   
        // draw3dText(ctx, "Your", canvas.width / 2, canvas.height/2 - lineheight, 5);
        // draw3dText(ctx, "Message", canvas.width / 2, canvas.height/2, 5);
        // draw3dText(ctx, "Here", canvas.width / 2, canvas.height/2 + lineheight, 5);
        ctx.fillText('Your', canvas.width/2, canvas.height/2 - lineheight);
        ctx.fillText('Message', canvas.width/2, canvas.height/2);
        ctx.fillText('Here', canvas.width/2, canvas.height/2 + lineheight);
    }  

}


const BuilderImage = ({options, mobile, selectedOptions}) => { //, t
    // console.log('selectedOptions::: ', selectedOptions)
    const location = useLocation()
    const [loading, setLoading] = useState(false)
    const [isReady, setIsReady] = useState(false)
    const customText = useSelector(state => state.product.customMessage)

    let wholeImage = [{
        uri: shapeSquare,
    }]
    let wholeImageWhitoutDollops = []
    let dollopsArray = []
    const textColorObj = {
        baseColor: 'white',
        topColor: 'black',
    }

    function loadImages(sources, callback) {    
        var images = {};
        var loadedImages = 0;
        var numImages = 0;
        // get num of sources
        for(let src in sources) {
          numImages++;
        }
        // console.log('numImages::: ', numImages)
        
        for(let src in sources) {
            // console.log('src::: ', src)
            images[src] = new Image();
            images[src].onload = function() {
            if(++loadedImages >= numImages) {   
                // console.log('images::: ', images)             
                callback(images);
            }
            };
            
            images[src].src = sources[src].uri;
            // images[src].style.filter = sources[src].filter
        }
        
    }
    
    const canvasContainerRef = useRef(null)
    const canvasRef = useRef(null)
    // const canvasRefFront = useRef(null)
    textColorObj.baseColor = 'white'     
    textColorObj.topColor = 'black'
    wholeImage.push({
        uri: shapeSquare,
    })
    
    
    useEffect(() => {
        document.fonts.load("46px Intro Script Bold").then(() => setIsReady(true));
        const canvasContainer = canvasContainerRef.current
        const canvas = canvasRef.current
        const ctx = canvas.getContext("2d");

        const setCanvasSize = () => {
            
            canvas.width = canvasContainer.clientWidth
            canvas.height = canvasContainer.clientWidth
            if (selectedOptions) {
                wholeImageWhitoutDollops = [{
                    uri: shapeSquare,
                }]
                selectedOptions.forEach(option => {
                    //START select base shape
                    if (option.modName.toLowerCase().includes("choose shape")) { 

                        if (option.selectedoptions.length > 0) {
                            option.selectedoptions.forEach(element => {
                                if (element.name.toLowerCase().includes('round')) {
                                    selectedShape = 'round'
                                    wholeImage = [{
                                        uri: shapeRound,
                                    }]
                                    
                                }
                                if (element.name.toLowerCase().includes('square')) {
                                    selectedShape = 'square'
                                    wholeImage = [{
                                        uri: shapeSquare,
                                    }]
                                }
                                if (element.name.toLowerCase().includes('rect')) {
                                    selectedShape = 'rect'
                                    wholeImage = [{
                                        uri: shapeRect,
                                    }]
                                }
                                
                            });
                            wholeImageWhitoutDollops = wholeImage
                        }
                        
                    }
                    //End select base shape

                    // START flavor
                    if (option.modName.toLowerCase().includes("choose cookie cake flavor")) {
                        if (option.selectedoptions.length > 0) {
                            if (selectedShape === 'square') {                    
                                squareBaseImagesList.forEach(imgSrc => {
                                    if (imgSrc.includes(option.selectedoptions[0]?.name.split(" ").join("-"))) {
                                        wholeImage = [{
                                            uri: imgSrc,
                                        }]
                                    }
                                })                   
                            }
                            if (selectedShape === 'rect') {                    
                                rectBaseImagesList.forEach(imgSrc => {
                                    if (imgSrc.includes(option.selectedoptions[0]?.name.split(" ").join("-"))) {
                                        wholeImage = [{
                                            uri: imgSrc,
                                        }]
                                    }
                                })                   
                            }
                            if (selectedShape === 'round') {                    
                                roundBaseImagesList.forEach(imgSrc => {
                                    if (imgSrc.includes(option.selectedoptions[0]?.name.split(" ").join("-"))) {
                                        wholeImage = [{
                                            uri: imgSrc,
                                        }]
                                    }
                                })                   
                            }
                        }                       


                        wholeImageWhitoutDollops = wholeImage
                    }
                    // END flavor

                    // START  border color
                    if (option.modName.toLowerCase().includes("border color")) {

                        if (option.selectedoptions.length > 0) {
                            dollopsArray = [] //clear the array first

                            option.selectedoptions.forEach(choice => {
                                dollopsArray.push({
                                    hue: colorArr.find(c => c.color === choice.name)?.hue,
                                    hsl: colorArr.find(c => c.color === choice.name)?.hsl,
                                    filter: colorArr.find(c => c.color === choice.name)?.filter,
                                    step: colorArr.find(c => c.color === choice.name)?.base,
                                    nocolor: colorArr.find(c => c.color === choice.name)?.base,
                                    name: colorArr.find(c => c.color === choice.name)?.color
                                })                                                    
                            })  
                            let redDollopsURLList = redRoundDollopsList
                            let whiteDollopsURLList = whiteRoundDollopsList
                            if (selectedShape === 'square') {                    
                                redDollopsURLList = redSquareDollopsList   
                                whiteDollopsURLList = whiteSquareDollopsList  
                            }
                            if (selectedShape === 'rect') {                    
                                redDollopsURLList = redRectDollopsList   
                                whiteDollopsURLList = whiteRectDollopsList 
                            }


                            let arr = dollopsArray.map((item, i) => {
                                let finalImage = {}
                                if (item.nocolor === 'red') {
                                    switch (i) {
                                        case 0:
                                            finalImage.uri = redDollopsURLList.find(item => item.includes("full"))
                                            break;
                                        case 1:
                                            finalImage.uri = dollopsArray.length === 2 ? redDollopsURLList.find(item => item.includes("2nd-simple")) : redDollopsURLList.find(item => item.includes("double-2"))
                                            break;
                                        case 2:
                                            finalImage.uri = redDollopsURLList.find(item => item.includes("double-3"))
                                            break;
                                        default:
                                            finalImage.uri = redDollopsURLList.find(item => item.includes("full"))
                                            break;
                                    }
                                } 
                                if (item.nocolor === 'white') {
                                    switch (i) {
                                        case 0:
                                            finalImage.uri = whiteDollopsURLList.find(item => item.includes("full"))
                                            break;
                                        case 1:
                                            finalImage.uri = dollopsArray.length === 2 ? whiteDollopsURLList.find(item => item.includes("2nd-simple")) : whiteDollopsURLList.find(item => item.includes("double-2"))
                                            break;
                                        case 2:
                                            finalImage.uri = whiteDollopsURLList.find(item => item.includes("double-3"))
                                            break;
                                        default:
                                            finalImage.uri = whiteRoundDollopsList.find(item => item.includes("full"))
                                            break;
                                    }
                                }                       
                                // finalImage.hue = item.hue
                                finalImage.hsl = item.hsl
                                finalImage.filter = item.filter
                                return finalImage;
                            })


                            wholeImage = [...wholeImageWhitoutDollops, ...arr]
                        }
                        
                        
                    }
                    // END  border color

                    // START base TEXT COLOR    
                    if (option.modName.toLowerCase().includes("base")) {                            
                        if (option.selectedoptions.length > 0) {
                            textColorObj.baseColor = textColor.find(c => c.colorName === option.selectedoptions[0].name).trueColor
                        }
                    }
                    // END base TEXT COLOR  

                    // START top TEXT COLOR    
                    if (option.modName.toLowerCase().includes("top")) {
                            
                        if (option.selectedoptions.length > 0) {
                            textColorObj.topColor = textColor.find(c => c.colorName === option.selectedoptions[0].name).trueColor
                        }
                    }
                    // END top TEXT COLOR 

                })
            }            
            setLoading(true)
            ctx.clearRect(0, 0, canvas.width, canvas.width);
            loadImages(wholeImage, function(images) {
                
                setLoading(false)
                for(var img in wholeImage) {
                    if (images[img]) {

                        if (wholeImage[img].filter) {
                            ctx.filter = wholeImage[img].filter                 
                        } 
                        ctx.drawImage(images[img], 0, 0, canvas.width, canvas.height) 
                        
                        ctx.filter = 'none'
                    }
                }       
                // DrawText(canvas,ctx, textColorObj, customText)  

                if (canvasContainer.clientWidth) {
                    if (!window.OffscreenCanvas) {
                        const offscreen = new OffscreenCanvasPolly(canvasContainer.clientWidth, canvasContainer.clientWidth);
                        DrawText(offscreen, offscreen.getContext("2d"), textColorObj, customText)
                        ctx.drawImage(offscreen, 0, 0); 
                        // console.log('t::::::::::: ', t)
                        // ctx.drawImage(t, 0, 0); 
                    } else {
                        const offscreen = new OffscreenCanvas(canvasContainer.clientWidth, canvasContainer.clientWidth);
                        DrawText(offscreen, offscreen.getContext("2d"), textColorObj, customText)
                        ctx.drawImage(offscreen, 0, 0); 
                        // console.log('t::::::::::: ', t)
                        // ctx.drawImage(t, 0, 0); 
                    }
                    // if (t) {
                        
                    // }
                }      
                                   
            });
                
            
        }

        setCanvasSize()
        window.onresize = setCanvasSize
       
        
    }, [selectedOptions, mobile, isReady, customText, location]) //, t


    const downloadImagehandler = () => {
        const canvas = canvasRef.current
        let img = canvas.toDataURL("image/png");

        let link = document.createElement('a');
        link.download = "my-cake.png";
        link.target = "_blank"
        link.href = img;
        link.click();
    }

    return <>
    
        <div className={classes.container} ref={canvasContainerRef}>
            <canvas ref={canvasRef} id="canvas" className={`canvas ${classes.canvas_back}`}></canvas>
            {loading && <div className={classes.loader_container}><span className='loader dark'></span></div>}
            {!loading && <button className={classes.download_btn} onClick={downloadImagehandler}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z"/></svg>
                <span className='sr-only'>Download Image</span>
            </button>}
        </div>
    </>
}

export default BuilderImage