import { useEffect } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom";

const RouteFolderTransitionPage = () => {
    const navigate = useNavigate();
    let locationStore = useSelector(state => state.location.location)

    useEffect(() => {
        if (locationStore) {
            if (locationStore.data && locationStore.data.attributes && locationStore.data.attributes.includes("MSC")) {
                navigate(`/marble-slab-creamery/${locationStore.location_slug}`)
            } else if (locationStore.data && locationStore.data.attributes && (locationStore.data.attributes.findIndex(element => element.includes("CNC")) >= 0)) {
                navigate(`/co-marble-slab-creamery/${locationStore.location_slug}`)
            } else {
                navigate(`/gac/${locationStore.location_slug}`)
            }
        } else {
            navigate('/')
        }


        // if (locationStore) {
        //     let path = window.location.pathname.split('/').slice(2).join('/')
        //     let pathDirectory1 = window.location.pathname.split('/').slice(1, 2)

        //     if (locationStore.data && locationStore.data.attributes && locationStore.data.attributes.includes("MSC")) {  
        //         if (pathDirectory1[0] !== 'msc') {
        //             navigate(`/marble-slab-creamery/${path}`)
        //         }
        //     } else if (locationStore.data && locationStore.data.attributes && (locationStore.data.attributes.findIndex(element => element.includes("CNC")) >= 0)) {              
        //         if (pathDirectory1[0] !== 'cnc') {
        //             navigate(`/co-marble-slab-creamery/${path}`)
        //         }
        //     } else {           
        //         if (pathDirectory1[0] !== 'gac') {
        //             navigate(`/gac/${path}`)
        //         }
        //     }
        // }
    }, [locationStore])

    return null
}

export default RouteFolderTransitionPage