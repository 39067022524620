import { useRef, useState } from 'react';
import classes from './SpecialInstructionsBuilder.module.css'

const CustomDesign = ({text}) => {
    const textareaRef = useRef(null);
    const [textareaValue, setTextareaValue] = useState(text)


    return <div className='form-fields-container'>
    <div className={`form-controller ${classes.form_controller}`}>
        <fieldset>
            <legend className='sr-only'>Order Customization</legend>
            {/* <div className={`form-group-checkbox ${classes.form_group}`}>
                <input type="checkbox" id="orderCustomizationTextarea" name="orderCustomizationTextarea" onChange={changeStateHandler} value={isActive} checked={isActive}/>
                <label htmlFor="orderCustomizationTextarea">special instructions (<span>optional</span>)</label>
            </div> */}
            
            <div className={classes.form_group}>
                <label htmlFor='orderCustomDesignTextareaInput' className={classes.label_title}>What design or decoration do you want on your Cookie Cake?</label>
                <textarea ref={textareaRef} id='orderCustomDesignTextareaInput' name="orderCustomDesignTextareaInput" placeholder='Enter any special instructions here, such as, please add pink and purple flowers' defaultValue={textareaValue}></textarea>
                <span className={classes.instructions}>Enter any special instructions here, such as a custom design description. No price altering substitutions/additions. </span>
            </div>                                
        </fieldset>
    </div>
</div>
}

export default CustomDesign