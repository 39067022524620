import { useEffect, useState } from 'react'
import Card from '../fragments/Card'
import classes from './FavoriteItem.module.css'
import { Link } from 'react-router-dom'
import AddToFavoriteOrders from './AddToFavoriteOrders'
import OrderAgain from './OrderAgain'

const RecentItem = ({item}) => {

    const [newDate, setnewDate] = useState()

    useEffect(() => {
        let trueDate = ''
        let time = ''
        const date = item.timeplaced.split(' ')
        date.forEach((element, index) => {
            if (index === 0) {
                const txt2 = element.slice(0, 4) + "-" + element.slice(4,6) + "-" + element.slice(6);
                trueDate = txt2
            }
            if (index === 1) {
                time = element
            }
            
        });
        setnewDate(trueDate + 'T' + time)
    }, [])
    
  
    return <Card direction="column_perma">
        <div className={classes.item_title}>Ordered at {item.vendorname}</div>
        <div className={classes.item_vendor}><span>Date: </span>{(new Date(newDate)).toLocaleString('en-US')}</div>
        <div className={classes.item_vendor}><span>Status: </span>{item.status}</div>
        <div className={classes.item_vendor}><span>Total: </span>${item.total}</div>
        <div className={classes.controls}>
            {/* <button className='btn btn-small'>View Details</button> */}
            <Link to={`/order/${item.id}`} className='btn btn-small'>View Details</Link>
            <OrderAgain item={item} tag={true}/>
            <AddToFavoriteOrders orderID={item.id}/>
        </div>
    </Card>
}

export default RecentItem