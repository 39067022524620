import DeliveryPannel from './DeliveryPannel';
import PickupPannel from './PickupPannel';
import classes from './SearchLocation.module.css'
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { locationActions } from '../../store/location';
import PickupIcon from '../../icons/pickupicon';
import DeliveryIcon from '../../icons/deliveryicon';


const SearchLocation = ({closeSidebar}) => {
    const dispatch = useDispatch()
    
    const [changePannel, setChangePannel] = useState({
        pickup: true,
        curbside: false,
        delivery: false
    });   



    const pickupPannelHandler = () => {
        setChangePannel({
            pickup: true,
            curbside: false,
            delivery: false
        })
        dispatch(locationActions.setVirtualDeliveryType('pickup'))
        dispatch(locationActions.setOrderDate(null))
        dispatch(locationActions.setOrderTime(null))
        dispatch(locationActions.setOrderType('asap'))
    }

    const deliveryPannelHandler = () => {
        setChangePannel({
            pickup: false,
            curbside: false,
            delivery: true
        })
        dispatch(locationActions.setVirtualDeliveryType('delivery'))
        dispatch(locationActions.setOrderDate(null))
        dispatch(locationActions.setOrderTime(null))
        dispatch(locationActions.setOrderType('asap'))
    }

    return <>
        {/* <div className={classes.sidebar_header}>
            
        </div> */}
        <div className={classes.sidebar_content}>
            <div className={classes.sidebar_content_wrapper}>
                <div className={classes.controls}>
                    <button className={`btn ${!changePannel.pickup ? 'btn-border' : ''} ${classes.btn_round_left}`} onClick={pickupPannelHandler}>
                        <PickupIcon />
                        <span>Pickup</span>
                    </button> 
                    <button className={`btn ${!changePannel.delivery ? 'btn-border' : ''} ${classes.btn_round_right}`} onClick={deliveryPannelHandler}>     
                        <DeliveryIcon />
                        <span>Delivery</span>
                    </button>
                </div>
                {changePannel.pickup && <div className={classes.sidebar_content_inner}>
                    <PickupPannel />
                </div>}
                {changePannel.delivery && <div className={classes.sidebar_content_inner}>
                    <DeliveryPannel switchToPickup={pickupPannelHandler}/>
                </div>}
            </div>
        </div>
    </>
}

export default SearchLocation;