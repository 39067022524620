import ReactDOM from "react-dom";
import { useDispatch, useSelector } from 'react-redux';
import Authentication from '../authentication/Authentication';
import { authActions } from '../../store/auth';

import Backdrop from '../utils/Backdrop'
import Sidebar from '../utils/Sidebar';

const SidebarAuthContainer = () => {
    const dispatch = useDispatch()
    const isUserSidebarOpened = useSelector(state => state.auth.isUserSidebarOpened)

    const openSignInSidebar = () => {
        dispatch(authActions.setUserSideBarState())
    }

    return <>
        {isUserSidebarOpened && <>
            {ReactDOM.createPortal(
                <Backdrop hide={openSignInSidebar} />,
                document.getElementById("backdrop-root")
            )}            
        </>}
        {ReactDOM.createPortal(
            <Sidebar click={openSignInSidebar} activeState={isUserSidebarOpened}>
                <Authentication closeSidebar={openSignInSidebar} />
            </Sidebar>,
            document.getElementById("overlay-root")
        )}
    </>
}

export default SidebarAuthContainer