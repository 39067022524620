import { Link } from "react-router-dom"

import classes from './AccountLanding.module.css'

import FavoriteOrders from "./FavoriteOrders"
import FavoriteLocations from "./FavoriteLocations"

const FavoritesLanding = () => {   

    return <div className="container-full">

        <div className={classes.wrapper}>
            <div className={classes.back_to_container}>
                <Link to="/account">
                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"><path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/></svg>
                    Back To Account
                </Link>
            </div>
            <div className={classes.favorites}>
                <h1 className="h2">Favorite Orders</h1>
                <FavoriteOrders />
            </div>

            <div className={classes.favorites}>
                <h2>Favorite Locations</h2>
                <FavoriteLocations />
            </div>
        </div>
    </div>
}

export default FavoritesLanding