import { useEffect, useState, useCallback } from "react";
import { DigitalWallets } from "@olo/pay";
import { useSelector } from "react-redux";

import { useDispatch } from "react-redux";
import { cartActions } from "../../store/cart";
import { getItemFromStorage, deleteItemFromStorage, deleteItemFromSessionnStorage } from "../../scripts/localStorage";
import { getAuthToken } from "../../scripts/auth";
import { cartCheckout } from "../../scripts/fetchApi";
import { variousActions } from "../../store/various";
import { virtualCartActions } from "../../store/virtualCart";
import { useNavigate } from "react-router-dom";
import { addDataLayerCheckout, deleteCookie, getCookie, getGenericToken } from "../../scripts/helpers";


import classes from './Billing.module.css'


export default function DefaultWallets({billingscheme}) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const guest = getItemFromStorage('guestUser')
    const user = useSelector(state => state.auth.user)
    // let token = getAuthToken()

    let token = getGenericToken()
    
    let hasLocation = useSelector(state => state.location.location)
    const hasUserOptedIn = useSelector(state => state.cart.hasUserOptedIn)

    let cart = useSelector(state => state.cart.cart)

    const walletsOptions = {
        options: {
            country: "US",
            currency: "usd",
            total: {
                label: "Pay Great American Cookies",
                amount: cart.total.toFixed(2)*100,
            },
            //Customizable breakdown for order details
            // displayItems: cart.products,
            // displayItems: [
            //     { label: "Subtotal", amount: cart.subtotal },
            //     { label: "Delivery Charge", amount: cart.customerhandoffcharge },
            //     { label: "Estimated Taxes & Fees", amount: cart.taxes[0].tax },
            //     { label: "Tip", amount: cart.tip },
            // ],
            //Note: it is highly recommended to use this- it will return the billing address in the Payment Method object for Google Pay and Apple Pay.
            requestPayerName: true,
        },
        style: {
            type: "default", //other types: "book" | "buy" | "donate"
            theme: "dark", //other themes: "light" | "light-outline"
            height: "40px",
        },
    };

    const [laoding, setLoading] = useState(false)
    const [digitalWallets, setDigitalWallets] = useState(null);
    const [paymentMethod, setPaymentMethod] = useState(null);

    const onPaymentSubmit = useCallback(

        async (paymentMethod, wallets) => {
            if (paymentMethod) {
                setPaymentMethod(paymentMethod);
                dispatch(cartActions.setDigitalWallet(paymentMethod))


                let reqData= {}
                let billingschemeid =''
                let walletType = ''

                if (wallets.canMakePayment.googlePay) {
                    billingschemeid = billingscheme.find(item => item.name === "Google Pay").id
                    walletType = "Google Pay"
                } else if (wallets.canMakePayment.applePay) {
                    billingschemeid = billingscheme.find(item => item.name === "Apple Pay").id
                    walletType = "Apple Pay"
                }


                // START GTM DATA
                let prodArray = []
                cart.products.forEach(element => {
                    prodArray.push({
                        item_id: element.productId,
                        item_name: element.name,
                        price: element.totalcost, // e.g. 9.99,
                        quantity: element.quantity, // e.g. 1
                    })
                });
                
                window.dataLayer = window.dataLayer || [];
                
                window.dataLayer.push({
                event: "add_payment_info",
                ecommerce: {
                    currency: "USD", // e.g. "USD",
                    value: cart.total, // e.g. 7.77,
                    coupon: cart.coupon,
                    payment_type: walletType,
                    items: prodArray
                }
                });
                // END GTM DATA

                const rwg_token = getCookie("rwg_token")

                if (token) {  // a user!!!                    
                    reqData = {
                        // billing_method: activeBilling.type,
                        billing_method: 'digitalwallet',
                        billingschemeid: billingschemeid,
                        card_token: paymentMethod.id,
                        user_type: 'user',                        
                        authtoken: token,
                        first_name: user.first_name,
                        last_name: user.last_name,
                        email_address: user.email,
                        contact_number: user.phone,
        
                        // card_token: billing.paymentMethod.id,
                        card_type: paymentMethod.card.brand,
                        card_number: paymentMethod.card.last4,
                        expiry_year: paymentMethod.card.exp_year,
                        expiry_month: paymentMethod.exp_month,
                        zip: paymentMethod.billing_details.address.postal_code 
                    }
                } else if (guest && guest.firstName !== '') { // a guest!!!

                    reqData = {
                        billing_method: 'digitalwallet',
                        billingschemeid: billingschemeid,
                        card_token: paymentMethod.id,
                        user_type: 'guest',
                        // createoloaccount: hasUserOptedIn,
                        first_name: guest.firstName,
                        last_name: guest.lastName,
                        email_address: guest.email,
                        contact_number: guest.phone,                   
    
                        card_type: paymentMethod.card.brand,
                        card_number: paymentMethod.card.last4,
                        expiry_year: paymentMethod.card.exp_year,
                        expiry_month: paymentMethod.exp_month,
                        zip: paymentMethod.billing_details.address.postal_code 
                    }       
                }

                const checkout = await cartCheckout(cart.id, reqData)

                if (checkout.id) {
                    //success

                    if (rwg_token) {
                        deleteCookie("rwg_token")
                    }

                    // delete current cart 
                    // deleteItemFromStorage('currentCart')
                    deleteItemFromSessionnStorage('currentCart')
                    dispatch(cartActions.addCart(null))
                    dispatch(cartActions.setCartSidebarState())
                    dispatch(virtualCartActions.clearVirtualCart())

                    wallets?.completePaymentEvent();

                    dispatch(cartActions.setBilling(null))
                    addDataLayerCheckout(checkout, hasLocation)
                    navigate(`/order/${checkout.id}`) 

                   
                } else {
                    dispatch(variousActions.setError(checkout))
                    wallets?.failPaymentEvent();
                }                
            }
        },
        [digitalWallets]
    );

    const [canRender, setCanRender] = useState()

    useEffect(() => {
        const initializeWallets = async () => {
            setLoading(true)
            const wallets = new DigitalWallets(process.env.REACT_APP_OLO_EVIRONMENT);

            //Use this to determine which digital wallets are available in the current browser context
            const canRenderButton = await wallets?.canRenderButton();

            setCanRender(canRenderButton)
            if (canRenderButton) {
                await wallets.initialize(walletsOptions);
                wallets.mountButton((paymentMethod) => {
                    onPaymentSubmit(paymentMethod, wallets);
                });
                setDigitalWallets(wallets);
            }

            setLoading(false)
        };
        if (!digitalWallets) {
            initializeWallets();
        }
        //On component unmount, unmount digital wallets instance
        return () => {
            if (!!digitalWallets) {
                digitalWallets?.unmount();
            }
        };
    }, [digitalWallets, onPaymentSubmit]);

    
    return <>  
        {/* {canRender && <div>Apple: {canRender.applePay.toString()}</div>} */}
        {laoding && <div className={`loader-box ${classes.absolute_loader}`}><span className="loader dark-raspberry medium" ></span></div>}
        <div data-olo-pay-payment-button className="digital-wallet-button"></div>          
    </>
}
