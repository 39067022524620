import MigrateToken from "../components/authentication/MigrateToken"
import BannerSimpleSet from "../components/common/BannerSimpleSet"

const MigrateTokenPage = () => {
    return <div className="overflow_container">
        <BannerSimpleSet title={'Marble Slab Creamery Rewards/ Slab Happy Rewards Account Migration'} type={'CNC'}/>
        <MigrateToken />
    </div>
}

export default MigrateTokenPage