import ReactDOM from "react-dom";
import Backdrop from "../utils/Backdrop";

import classes from './InfoComponent.module.css'
import CloseIcon from "../../icons/closeicon";

const InfoComponent = ({children, cancel, acceptText, accept}) => {


    return <>
        {ReactDOM.createPortal(
            <Backdrop hide={cancel} />,
            document.getElementById("backdrop-root")
        )}
        {ReactDOM.createPortal(
            <div className={classes.info_module}>                
                <div className={classes.info_module_inner}>
                    <button onClick={cancel} className={classes.close}>
                        <CloseIcon />
                        <span className="sr-only">close window</span>
                    </button>
                    {children}
                    <div className={classes.controls}>
                        {accept}
                        {cancel && <button className="btn" onClick={cancel}>Cancel</button>}
                    </div>
                </div>
            </div>,
            document.getElementById("overlay-root")
        )}
    </>
}

export default InfoComponent