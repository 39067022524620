import Card from '../fragments/Card'
import classes from './MockSuperCategories.module.css'

const MockSuperCategories = () => {

    const MOCKSUPERCAT = [{id:1}, {id:2}, {id:3}, {id:4}, {id: 5}, {id:6}, {id:7}, {id:8}]
    return <section>
        <div className="container-full">
            <div className={`grid-3 ${classes.supercategories}`}>
                {MOCKSUPERCAT.map((item) => (
                    <Card key={item.id + Math.random(10)} direction="column">
                        <div className={classes.button_link}>
                            <div className={classes.image_container}>                                
                            </div>
                            <div className={classes.text_content}>
                                <div className={classes.title}>                                   
                                    
                                </div>
                            </div>
                        </div>
                    </Card>
                ))}
            </div>
        </div>
    </section>
}

export default MockSuperCategories