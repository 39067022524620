import ReactDOM from "react-dom";
import { useDispatch } from "react-redux"
import { authActions } from "../../store/auth"
import { useRef, useState } from "react"
import ForgotPassword from "./ForgotPassword";
import { getCartById, userLogin } from "../../scripts/fetchApi";
import { variousActions } from "../../store/various";
import { getuserInfo } from "../../scripts/fetchApi";
import { getItemFromSessionStorage, getItemFromStorage, setSessionStorageItem } from "../../scripts/localStorage";

import classes from './Authentication.module.css'
import { setStorageItem } from "../../scripts/localStorage";
import Popup from "../common/Popup";
import { getCookie } from "../../scripts/helpers";

const isEmpty = (value) => value.trim() === '';

const isEmail = (email) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z0-9]/i.test(email);

const Signin = ({closeSidebar, position, noTitle}) => {
    const [forgotPassword, setForgotPassword] = useState(false)

    const emailInputRef = useRef(null)
    const passwordInputRef = useRef(null)

    const [formInputsValidity, setFormInputsValidity] = useState({
        email: true,
        password: true
    })

    const [openTermsAndConditions, setOpenTermsAndConditions] = useState(false)

    const [isLoading, setisLoading] = useState(false)

    const dispatch = useDispatch()

    const forgotPasswordHandler = () => {
        setForgotPassword(prevState => prevState = !prevState)
    }

    const closeTermsAndConditions = () => {
        setOpenTermsAndConditions(false)
    }


    const loginHandler = async (event) => {
        event.preventDefault()
        
        const enteredEmail = emailInputRef.current.value
        const enteredPassword = passwordInputRef.current.value

        const enteredEmailisValid = !isEmpty(enteredEmail) && isEmail(enteredEmail)
        const enteredPasswordisValid = !isEmpty(enteredPassword)


        setFormInputsValidity({
            email: enteredEmailisValid,
            password: enteredPasswordisValid
        })


        const formIsValid = enteredEmailisValid && enteredPasswordisValid

        if (formIsValid) {
            setisLoading(true)
            const data = {
                email: enteredEmail,
                password: enteredPassword
            }
            // const cart = getItemFromStorage('currentCart')
            const cart = getItemFromSessionStorage('currentCart')
            const login = await userLogin(data)

            if (login.message) {
                dispatch(variousActions.setError(login))     
            } else {
                dispatch(authActions.login(login))
                const user = await getuserInfo(login.authtoken)
                dispatch(authActions.setUser(user))
                if (cart) {
                    const getCart = await getCartById(cart.id, login.authtoken) // this apparently gets the current cartand if it exists it adds it to the logged in user? TODO / TOCHECK

                    if (getCart.id) {
                        // setStorageItem('currentCart', getCart)
                        setSessionStorageItem('currentCart', getCart)
                    }
                }
                if (!position) {
                    closeSidebar(false)
                }


                // open terms and conditions change pop 
                if (!getCookie('terms_agreed')) {
                    dispatch(authActions.openTermsAndConditions())
                }
                
                
            }


            setisLoading(false)
        }      
 
        
    }

    let cartClass = ''
    if (position && position === 'cart') {
        cartClass = 'cart_footer_button'
    }


    return <>
        {!forgotPassword && <>
            {!noTitle && <div className="margin-bottom-30">
                <h3 className={classes.form_title}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"/></svg>
                    Sign In
                </h3>
            </div>}
            <form onSubmit={loginHandler} className='form-fields-container'>    
                {/* <Input label="Email" id="email_input" type="email" name="email" required ref={emailInputRef} className={`${!formInputsValidity.email ? 'error' : ''}`}/>    */}
                <div className="form-controller">
                    <label className="label" htmlFor="email_input">Email</label>
                    <input type="email" id="email_input" placeholder="Email" ref={emailInputRef} className={`${!formInputsValidity.email ? 'error' : null}`} required/>
                    {!formInputsValidity.email && <div className='error-message'>Please enter a valid email!</div>}
                </div>
                <div className="form-controller">
                    <label className="label" htmlFor="password_input">Password</label>
                    <input id="password_input" type="password" ref={passwordInputRef} placeholder="******" className={`${!formInputsValidity.password ? 'error' : null}`} required/>
                    {!formInputsValidity.password && <div className='error-message'>Please enter a password!</div>}
                </div>
                <div className="form-controller">
                    <div className="margin-bottom-20">
                        <button type="button" className="btn-underline" onClick={forgotPasswordHandler}>Forgot password?</button>
                    </div>
                    
                </div>
                <div className={cartClass}>
                    {!isLoading && <button className="btn">Login</button>}
                    {isLoading && <div className="btn"><span className="loader"></span></div>}
                    
                </div>
            </form>  
        </>}

        {forgotPassword && <>
            <div className="margin-bottom-20">
                <button className="btn-underline" onClick={forgotPasswordHandler}>Back</button>
            </div>
            <div className="margin-bottom-20">               
            </div>
            <ForgotPassword />
        </>}
        
        
    </>
}

export default Signin