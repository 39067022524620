import { useState } from 'react'
import { useDispatch } from 'react-redux'
import classes from './DateTimeAdvanced.module.css'
import { locationActions } from '../../store/location'

import { useSelector } from 'react-redux'
import { cartSetDeliveryMethod, createCart, setVehicle } from '../../scripts/fetchApi'
import { setSessionStorageItem, setStorageItem } from '../../scripts/localStorage'
import { cartActions } from '../../store/cart'
import { variousActions } from '../../store/various'

const ConfirmSelection = ({timemode, date, time, isCurbside}) => {
    const dispatch = useDispatch()
    const [pickupLoading, setPickupLoading] = useState(false)
    const cart = useSelector(state => state.cart.cart)
    const location = useSelector(state => state.location.location)

    let vehicleInfo = useSelector(state => state.cart.vehicleInfo)

    // const make = cart.customfields.find(item => item.label==="Make") ? cart.customfields.find(item => item.label==="Make").value : vehicleInfo?.vehicleMake
    // const model = cart.customfields.find(item => item.label==="Model") ? cart.customfields.find(item => item.label==="Model").value : vehicleInfo?.vehicleModel
    // const color = cart.customfields.find(item => item.label==="Color") ? cart.customfields.find(item => item.label==="Color").value : vehicleInfo?.vehicleColor

    let make = vehicleInfo?.vehicleMake
    let model = vehicleInfo?.vehicleModel
    let color = vehicleInfo?.vehicleColor

    if (cart && cart.deliverymode === "curbside" && cart.customfields) {
        make = cart.customfields.find(item => item.label==="Make")?.value
        model = cart.customfields.find(item => item.label==="Model")?.value
        color = cart.customfields.find(item => item.label==="Color")?.value
    } 

    const setPickupHandler = async (event) => {
        event.preventDefault()
        setPickupLoading(true)

        const deliveryCartInfo = {
            delivery_mode: isCurbside ? 'curbside' : 'pickup',
            time: timemode === 'asap' ? 'asap' : 'schedule',
            schedule_date: date ? date : null,
            schedule_time: time ? time : null
        }

        

        
        if (cart) {
            const deliveryCart = await cartSetDeliveryMethod(cart.id, deliveryCartInfo)

            if (deliveryCart.id) {
                // validateCartHandler(deliveryCart.id)

                if (isCurbside && make && model && color && location) {
                    const vehicle = {
                        vehicle_make: make,
                        vehicle_model: model,
                        vehicle_color: color,
                    }
                    const cartVehicle = await setVehicle(deliveryCart.id, location.id, vehicle)

                    if (cartVehicle.id) {
                        // setStorageItem('currentCart', cartVehicle)
                        setSessionStorageItem('currentCart', cartVehicle)
                        dispatch(cartActions.addCart(cartVehicle))
                        setPickupLoading(false) 
                    } else {
                        dispatch(variousActions.setError(cartVehicle))
                    }
                } else {
                    // setStorageItem('currentCart', deliveryCart)
                    setSessionStorageItem('currentCart', deliveryCart)
                    dispatch(cartActions.addCart(deliveryCart))
                    setPickupLoading(false)
                }
                

                // on success close modal 
                dispatch(locationActions.setChangeDelivery({
                    close: false,
                    value: true
                }))
            } else {
                dispatch(variousActions.setError(deliveryCart))
                setPickupLoading(false)
            }
        } else {
            const newCart = await createCart({restaurant_id: location.id})
            if (newCart.id) {
                const deliveryCart = await cartSetDeliveryMethod(newCart.id, deliveryCartInfo)
                if (isCurbside && make && model && color && location) {
                    const vehicle = {
                        vehicle_make: make,
                        vehicle_model: model,
                        vehicle_color: color,
                    }
                    const cartVehicle = await setVehicle(deliveryCart.id, location.id, vehicle)

                    if (cartVehicle.id) {
                        // setStorageItem('currentCart', cartVehicle)
                        setSessionStorageItem('currentCart', cartVehicle)
                        dispatch(cartActions.addCart(cartVehicle))
                        setPickupLoading(false) 
                    } else {
                        dispatch(variousActions.setError(cartVehicle))
                    }
                } else {
                    // setStorageItem('currentCart', deliveryCart)
                    setSessionStorageItem('currentCart', deliveryCart)
                    dispatch(cartActions.addCart(deliveryCart))
                    setPickupLoading(false)
                }
                

                // on success close modal 
                dispatch(locationActions.setChangeDelivery({
                    close: false,
                    value: true
                }))
            } else {
                dispatch(variousActions.setError(newCart))
            }
                   
        }       

        
    }

    return <div className={classes.change_selections_date_confirm}>
        {!pickupLoading && <>
            <button className='btn btn-transparent' onClick={setPickupHandler} 
                // disabled={(isCurbside && make && model && color) ? false : true}
                disabled={isCurbside ? ((make && model && color) ? false : true) : false}
            >
                Confirm
            </button>
            {/* {isCurbside && <button className='btn btn-transparent' onClick={setPickupHandler} 
                // disabled={(isCurbside && make && model && color) ? false : true}
                disabled={isCurbside ? ((make && model && color) ? false : true) : false}
            >
                Confirm
            </button>} */}
            {/* {!isCurbside && <button className='btn btn-transparent' onClick={setPickupHandler} 
                // disabled={(isCurbside && make && model && color) ? false : true}                
            >
                Confirm
            </button>} */}
        </>}
        {pickupLoading && <div className='btn btn-transparent'><span className='loader dark'></span></div>}
    </div>
}

export default ConfirmSelection