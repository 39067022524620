import { redirect } from "react-router-dom"
import { defer, useLoaderData, Await } from "react-router-dom"
import { Suspense } from "react"
import AccountLanding from "../components/account/AccountLanding"
import { getuserInfo } from "../scripts/fetchApi"
import LoadingWrapperAuth from "../components/common/LoadingWrapperAuth"
import AccountWrapper from "../components/common/AccountWrapper"
import { getItemFromStorage } from "../scripts/localStorage"

const Account = () => {
    const data = useLoaderData() 
     
    return <Suspense fallback={<LoadingWrapperAuth text=""/>}>
        <Await resolve={data.data}>
            {() => <>
                <AccountWrapper>
                    <AccountLanding/>
                </AccountWrapper>
            </>}
        </Await>
    </Suspense>
}

export default Account


async function loadData() {
    const token = sessionStorage.getItem('token')


    // START hide account routes for a while
    const storage = getItemFromStorage('currentLocation')
    if (storage) {
        // function isMSC(location) {
        //     if (location) {
        //         if (location.data && location.data.attributes && location.data.attributes.includes("MSC")) {            
        //             return true
        //         } 
        //     }
        
        //     return false
        // }

        function isRewardsNonParticipating(location) {
            if (location) {
                if (location.data && location.data.attributes && location.data.attributes.includes("Rewards Non-Participating")) {            
                    return true
                } 
            }
        
            return false
        }
        // isMSC(storage.currentLocation) || 
        if (isRewardsNonParticipating(storage.currentLocation)) {
            return redirect('/')
        }
    }

    // END hide account routes for a while

    if (token) {
        const user = await getuserInfo(token)          
        if (!user.message) {
            return user
        } else {
            sessionStorage.removeItem("token")
            return redirect('/signin')
            // return null
        }
    }

    const olotoken = sessionStorage.getItem('olotoken')

    if (olotoken) {
        const user = await getuserInfo(olotoken, 'oloauth') 
        
        // console.log('olo user::: ', user)
        if (!user.message) {
            const formatUser = {
                first_name: user.firstname ? user.firstname : '',
                last_name: user.lastname ? user.lastname : '',
                name: (user.firstname ? user.firstname : '') + ' ' + (user.lastname ? user.lastname : ''),
                email: user.emailaddress,
                phone: user.phone ? user.phone : '',
            }    
            return user
        } else {
            sessionStorage.removeItem("token")
            return redirect('/signin')
            // return null
        } 

    }

    return redirect('/signin')
    // return null
}

export async function loader({ request, params}) {

   
    return defer({
        data: loadData()
    })    
}