import ReactDOM from "react-dom";
import Backdrop from "../utils/Backdrop";

import classes from './InfoComponent.module.css'
import { useEffect, useState } from "react";
import CloseIcon from "../../icons/closeicon";

const Popup = ({children, closeInfoHandler, transition}) => {

    const [inView, setInView] = useState(false)
    
    useEffect(() => {
        if (transition) {
            setInView(true)
        } else {
            setInView(false)
        }
        
    }, [transition])

    return <>
        {ReactDOM.createPortal(
            <Backdrop hide={closeInfoHandler} over={true}/>,
            document.getElementById("backdrop-root")
        )}
        {ReactDOM.createPortal(
            <div className={classes.info_module}>                
                <div className={`${classes.info_module_inner} ${classes.modal_module_inner} ${inView && classes.modal_in_view}`}>
                    {closeInfoHandler && <button onClick={closeInfoHandler} className={classes.close}>
                        <CloseIcon />
                        <span className="sr-only">close window</span>
                    </button>}
                    {children}
                </div>
            </div>,
            document.getElementById("overlay-root")
        )}
    </>
}

export default Popup