import EditAccount from "./EditAccount"
import classes from './AccountLanding.module.css'
import { Link } from "react-router-dom"
import { useState } from "react"
import ForgotPassword from "../authentication/ForgotPassword"


const Edit = () => {
    const [basicInfo, setBasicInfo] = useState(true)

    const changeTemplateHandler = () => {
        setBasicInfo(prevState => prevState = !prevState)
    }


    return <div className="container-full">
        <div className={classes.wrapper}>
            <div className={classes.back_to_container}>
                <Link to="/account">
                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"><path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/></svg>
                    Back To Account
                </Link>
            </div>
            <h1 className="text-center">Edit Account Info</h1>

            <div className={classes.form_wrapper}>              
                <div className={classes.controls}>
                    <button onClick={changeTemplateHandler} className={`btn ${!basicInfo ? 'btn-border' : null}`}>Edit basic information</button>
                    <button onClick={changeTemplateHandler} className={`btn ${basicInfo ? 'btn-border' : null}`}>Change Password</button>
                </div>
            </div>
            

            <div className={classes.form_wrapper}>              
                {basicInfo && <EditAccount />}
                {!basicInfo && <ForgotPassword edit={true}/>}
            </div>
            
        </div>
    </div>
}

export default Edit