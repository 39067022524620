import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { applyCartRewards, deleteCartRewards, validateCart } from '../../scripts/fetchApi'
import { cartActions } from '../../store/cart'
import { variousActions } from '../../store/various'
import { setSessionStorageItem, setStorageItem } from '../../scripts/localStorage'

import classes from './AlternativePayment.module.css'

const RewardItem = ({reward}) => {
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)

    let cart = useSelector(state => state.cart.cart)

    const applyRewardsHandler = async (membership_id, references) => {
        setIsLoading(true)

        const rewards = await applyCartRewards(cart.id, {
            membership_id: membership_id,
            references: references
        })

         

        if (rewards.id) {

            const validateRewards = await validateCart(rewards.id)

            if (validateRewards.id) {
                // setStorageItem('currentCart', validateRewards)
                setSessionStorageItem('currentCart', validateRewards)
                dispatch(cartActions.addCart(validateRewards))
            } else {
                dispatch(variousActions.setError(validateRewards))
            }
            
        } else {
            dispatch(variousActions.setError(rewards))
        }

        setIsLoading(false)
    }

    const removeRewardsHandler = async () => {
        setIsLoading(true)

        const rewards = await deleteCartRewards(cart.id, cart.appliedrewards[0].rewardid)

        if (rewards.id) {
             
            // setStorageItem('currentCart', rewards)
            setSessionStorageItem('currentCart', rewards)
            dispatch(cartActions.addCart(rewards))
        } else {
            dispatch(variousActions.setError(rewards))
        }

        setIsLoading(false)
    }

    return <div className={`${classes.reward_item} ${reward.applied && classes.rewar_item_applied}`}>
        <div className={classes.reward_item_inner}>
            <div className={classes.reward_item_head}>
                <div className={classes.reward_item_image}>
                    {reward.imageurl && <img src={reward.imageurl} alt={reward.label} className='image-cover'/>}
                    {reward.image_url && <img src={reward.image_url} alt={reward.label} className='image-cover' />}
                </div>
                <div className={classes.reward_item_label}>{reward.label}</div>
            </div>
            <div>
                {!reward.applied && <div className={classes.reward_item_button}>
                    {!isLoading && <button title="Redeem reward" onClick={applyRewardsHandler.bind(this, reward.membership_id, reward.reference)} className="btn btn-transparent">
                        Redeem                              
                    </button>}
                    {isLoading && <div className={`${classes.loader_container} btn btn-transparent`}><span className="loader dark"></span></div>}
                </div> }   
                {reward.applied && <div className={classes.reward_item_button}>
                    {!isLoading && <button title="remove reward" onClick={removeRewardsHandler} className="btn btn-transparent">
                        Remove
                    </button>}
                    {isLoading && <div className={`${classes.loader_container} btn btn-transparent`}><span className="loader dark"></span></div>}
                </div> }  
            </div>  
        </div>

        {/* old template  */}
        {/* {reward.imageurl && <img src={reward.imageurl} alt={reward.label} />}
        {reward.image_url && <img src={reward.image_url} alt={reward.label} />}
        <div className={classes.reward_item_label}>{reward.label}</div>
        {!reward.applied && <div className={classes.reward_item_button}>
            {!isLoading && <button title="add reward" onClick={applyRewardsHandler.bind(this, reward.membership_id, reward.reference)} className="btn btn-fit">
                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM232 344V280H168c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V168c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H280v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z"/></svg>
                <span className='sr-only'>Apply rewards</span>                                
            </button>}
            {isLoading && <div className={`${classes.loader_container} btn btn-fit`}><span className="loader dark-raspberry"></span></div>}
        </div> }   
        {reward.applied && <div className={classes.reward_item_button}>
            {!isLoading && <button title="remove reward" onClick={removeRewardsHandler.bind(this, reward.reference)} className="btn btn-fit">
                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM184 232H328c13.3 0 24 10.7 24 24s-10.7 24-24 24H184c-13.3 0-24-10.7-24-24s10.7-24 24-24z"/></svg>
                <span className='sr-only'>Remove rewards</span>
            </button>}
            {isLoading && <div className={`${classes.loader_container} btn btn-fit`}><span className="loader dark-raspberry"></span></div>}
        </div> }                     */}
    </div>
}

export default RewardItem