import { useDispatch, useSelector } from "react-redux"
import { cartActions } from "../../../store/cart"
import { cartSetDeliveryMethod, validateCart } from "../../../scripts/fetchApi"
import { setSessionStorageItem, setStorageItem } from "../../../scripts/localStorage"
import { variousActions } from "../../../store/various"
import classes from '../Cart.module.css'
import { useState } from "react"
import { formatOLODate, formatOLOTime } from "../../../scripts/helpers"

const OrderInfo = () => {

    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()
    const cart = useSelector(state => state.cart.cart)
    const sidebarStep = useSelector(state => state.cart.cartSidebarStep)

    const validateCartHandler = async (cartId) => {

        const validCart = await validateCart(cartId)

        if (validCart.id) {
            // setStorageItem('currentCart', validCart)
            setSessionStorageItem('currentCart', validCart)
            dispatch(cartActions.addCart(validCart))
            setIsLoading(false)
            return cart;
        } else {
            dispatch(variousActions.setError(validCart))
            setIsLoading(false)
            return null
        }

    }

    let locationStore = useSelector(state => state.location.location)

    const goToNextStep = async (step) => {
        // setIsLoading(true)
        if (locationStore) {

            setIsLoading(true)  
            
            
            if (cart.timemode ==="advance" && !cart.timewanted) { // if there is a scheduled delivery but no time, it means that the desired time is in the past or before the order is ready
                const deliveryCartInfo = {
                    delivery_mode: cart.deliverymode,
                    time: 'asap',
                    schedule_date:  null,
                    schedule_time: null
                }
                if (!locationStore?.status?.open) {
                    deliveryCartInfo.time = 'schedule'
                    deliveryCartInfo.schedule_date = formatOLODate(cart.earliestreadytime)
                    deliveryCartInfo.schedule_time = formatOLOTime(cart.earliestreadytime)
                }
                const cartMethod = await cartSetDeliveryMethod(cart.id, deliveryCartInfo)    
                if (cartMethod.id) {
                    setSessionStorageItem('currentCart', cartMethod)
                    dispatch(cartActions.addCart(cartMethod))

                    const validate = await validateCart(cartMethod.id)       

                    if (validate.id) {
                        // advance()
                        dispatch(cartActions.setCartSidebarStep("info"))
                    } else {
                        dispatch(variousActions.setError(validate)) 
                    }
                    setIsLoading(false)
                } else {
                    dispatch(variousActions.setError(cartMethod)) 
                }     
            } else if (cart.timemode ==="asap" && !locationStore.status.open) { // timemode is asap but the location is closed
                const deliveryCartInfo = {
                    delivery_mode: cart.deliverymode,
                    time: 'asap',
                    schedule_date:  null,
                    schedule_time: null
                }
                if (!cart?.status?.open) {
                    deliveryCartInfo.time = 'schedule'
                    deliveryCartInfo.schedule_date = formatOLODate(cart.earliestreadytime)
                    deliveryCartInfo.schedule_time = formatOLOTime(cart.earliestreadytime)
                }
                const cartMethod = await cartSetDeliveryMethod(cart.id, deliveryCartInfo)    
                if (cartMethod.id) {
                    setSessionStorageItem('currentCart', cartMethod)
                    dispatch(cartActions.addCart(cartMethod))

                    const validate = await validateCart(cartMethod.id)       

                    if (validate.id) {
                        // advance()
                        dispatch(cartActions.setCartSidebarStep("info"))
                    } else {
                        dispatch(variousActions.setError(validate)) 
                    }
                    setIsLoading(false)
                } else {
                    dispatch(variousActions.setError(cartMethod)) 
                } 
            } else {
                const validate = await validateCart(cart.id)       

                if (validate.id) {
                    // advance()
                    dispatch(cartActions.setCartSidebarStep("info"))
                } else {
                    dispatch(variousActions.setError(validate)) 
                }
                setIsLoading(false)
            }

            // START GTM DATA
            let prodArray = []
            cart.products.forEach(element => {
                prodArray.push({
                    item_id: element.productId,
                    item_name: element.name,
                    price: element.totalcost, // e.g. 9.99,
                    quantity: element.quantity, // e.g. 1
                })
            });
            
            window.dataLayer = window.dataLayer || [];

            
            window.dataLayer.push({
            event: "begin_checkout",
            ecommerce: {
                currency: "USD", // e.g. "USD",
                value: cart.total, // e.g. 7.77,
                items: prodArray
            }
            });
            // END GTM DATA
            
        } else { //no location
            dispatch(variousActions.setError({ message: 'Please set a location in order to continue'}))
        }
        // const cartIsValid = await validateCartHandler(cart.id)

        // // if (cartIsValid.id) {
        // //     dispatch(cartActions.setCartSidebarStep(step))
        // // } 
        // // setIsLoading(false)
    }

    return <div className={`${classes.cart_timeline_item} ${sidebarStep === "info" && classes.active}  ${sidebarStep === "checkout" && classes.complete}`}>
    <button onClick={goToNextStep.bind(this, "info")} disabled={sidebarStep === "info"}>
        <span className={classes.number}>
            <span>
                {!isLoading && 2}
                {isLoading && <div className="loader dark-raspberry"></div>}
            </span>
            {!isLoading && <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" fill="#fff"/></svg>}
            {isLoading && <div className={classes.svg_loader}><div className="loader small"></div></div>}
        </span>
        Your Info
    </button>
</div>
}

export default OrderInfo