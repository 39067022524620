import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import { redirect } from "react-router-dom";
import { getItemFromStorage } from './scripts/localStorage';

import Home, { loader as menuLoader } from './pages/Home'
import Layout from './components/layout/Layout'
import ErrorPage from './pages/Error'
import CategoriesDetails from './pages/CategoriesDetails'
import ProductDetails, {loader as productLoader} from './pages/ProductDetails'
import CreateCookie from './pages/CreateCookie'
import LocationPage, {loader as locationLoader } from './pages/LocationPage'
import LocationPageOld, {loader as locationLoaderOld} from './pages/LocationPageOld';


import Account, {loader as authLoader } from './pages/Account';
import SignIn from './components/account/SignIn';
import Edit from './pages/EditAccount';
import Favorites from './pages/Favorites';
import Recent from './pages/Recent';
import OrderStatus, {loader as orderStatusLoader} from './pages/OrderStatus';
// import ThankYou, {loader as thankyouLoader} from './pages/ThankYou';
// import Test from './pages/Test';
import Rewards from './pages/Rewards';

import TagManager from 'react-gtm-module'
import RouteFolderTransitionPage from './pages/RouteFolderTransitionPage';
import Migrate from './pages/Migrate';

import MigrateTokenPage from './pages/MigrateToken';

import './index.css';
import Locations from './pages/Locations';
import LocationsPage from './pages/LocationsPage';

const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM
}
 
TagManager.initialize(tagManagerArgs)


function Index() {
    
    const router = createBrowserRouter([
        {
            path: '/',
            element: <Layout />,
            errorElement: <ErrorPage />,
            children: [
                { index: true, element: <Home />, loader: menuLoader},    
                { path: '/cnc', element: <RouteFolderTransitionPage />},
                { path: '/msc', element: <RouteFolderTransitionPage />},
                { path: '/gac', element: <RouteFolderTransitionPage />},
                // { path: '/:locationSlug', loader: ({ request, params}) => {
                //     const storage = getItemFromStorage('currentLocation')
                //     const slug = params.locationSlug
                //     if (storage === null) {
                //         return redirect('/')
                //     } else {
                //         if (storage.currentLocation.slug === slug) {
                //             return redirect(`/${storage.currentLocation.slug}/menu`)
                //         }
                //         // return redirect('/')
                //         throw new Error("Not Found", { status: 404 });
    
                //     }
                // }},
                // {path: 'locations', element: <Home locationPage={true}/>, loader: menuLoader},
                {path: 'locations', element: <LocationsPage />},
                {path: 'rewards-migration', element: <Migrate />},
                {path: 'rewards-migration/token', element: <MigrateTokenPage />},
                { path: '/co-marble-slab-creamery/:locationSlug', children: [
                    {index: true, element: <LocationPage />, loader: locationLoader},
                    { path: ':categoryId', element: <CategoriesDetails />, loader: locationLoader },
                    { path: 'product/:productSlug', element: <ProductDetails />, loader: locationLoader},
                    { path: 'create/:productSlug', element: <CreateCookie />, loader: locationLoader},
                ]},
                { path: '/marble-slab-creamery/:locationSlug', children: [
                    {index: true, element: <LocationPage />, loader: locationLoader},
                    { path: ':categoryId', element: <CategoriesDetails />, loader: locationLoader },
                    { path: 'product/:productSlug', element: <ProductDetails />, loader: locationLoader},
                    { path: 'create/:productSlug', element: <CreateCookie />, loader: locationLoader},
                ]},
                { path: '/gac/:locationSlug', children: [
                    {index: true, element: <LocationPage />, loader: locationLoader},
                    { path: ':categoryId', element: <CategoriesDetails />, loader: locationLoader },
                    { path: 'product/:productSlug', element: <ProductDetails />, loader: locationLoader},
                    { path: 'create/:productSlug', element: <CreateCookie />, loader: locationLoader},
                ]},
                // { path: '/:locationSlug/menu', children: [
                //     {index: true, element: <RouteFolderTransitionPage />},
                // ]},
                { path: '/:locationSlug/menu', children: [
                    {index: true, element: <LocationPageOld />, loader: locationLoaderOld},
                    { path: ':categoryId', element: <CategoriesDetails />, loader: locationLoaderOld },
                    { path: 'product/:productSlug', element: <ProductDetails />, loader: locationLoaderOld},
                    { path: 'create/:productSlug', element: <CreateCookie />, loader: locationLoaderOld},
                ]},
                {path: 'signin', element: <SignIn />},
                {path: 'account', children: [
                    // {index: true, element: <Account />, loader: checkAuthLoader},
                    // {path: 'edit', element: <Edit />, loader: checkAuthLoader},
                    // {path: 'favorites', element: <Favorites />, loader: checkAuthLoader},
                    // {path: 'recent', element: <Recent />, loader: checkAuthLoader},    
                    // {path: 'rewards', element: <Rewards />, loader: checkAuthLoader},
                    {index: true, element: <Account />, loader: authLoader},
                    {path: 'edit', element: <Edit />, loader: authLoader},
                    {path: 'favorites', element: <Favorites />, loader: authLoader},
                    {path: 'recent', element: <Recent />, loader: authLoader},    
                    {path: 'rewards', element: <Rewards />, loader: authLoader},
                ]},
                {path: 'order/:orderId', element: <OrderStatus />, loader: orderStatusLoader},
            ]
        },    
    ])

    return <RouterProvider router={router} />;
}

function App() {     
    return <div className="App">        
        <Index />
    </div>;
}

export default App;
