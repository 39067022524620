import InputMask from "react-input-mask";
import classes from './NonOloPay.module.css'

const DateInputMaks = ({ value, onChange, refVar }) => {
    return (
        <InputMask
            ref={refVar}
            className={classes.input}
            mask="99/9999"
            placeholder="MM/YYYY"
        />
    );
};

export default DateInputMaks;
