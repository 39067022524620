import { useRef, useState } from "react"
import { userForgotPassword } from "../../scripts/fetchApi";
import { useDispatch } from "react-redux";
import { variousActions } from "../../store/various";
import { isEmail, isEmpty } from "../../scripts/helpers";



const ForgotPassword = ({edit}) => {
    const dispatch = useDispatch()
    const [isLoading, setisLoading] = useState(false)
    const emailInputRef = useRef(null)
    const [passSent, setPassSet] = useState(false)
    const [formInputsValidity, setFormInputsValidity] = useState({
        email: true
    })

    const submitFormHandler = async (event) => {
        event.preventDefault()
        setisLoading(true)

        

        const enteredEmail = emailInputRef.current.value

        const enteredEmailisValid = !isEmpty(enteredEmail) && isEmail(enteredEmail)

        setFormInputsValidity({
            email: enteredEmailisValid,
        })
        const formIsValid = enteredEmailisValid

        if (formIsValid) {
            const forgotPass = await userForgotPassword({
                email: enteredEmail
            })

            if (forgotPass && forgotPass.message) {
                dispatch(variousActions.setError(forgotPass))
            } else {
                setPassSet(true)
            }
            // if (forgotPass)
            
        } 
        setisLoading(false)
    }
    return <>
        {!passSent && <>
            <div className="margin-bottom-20">
                {!edit && <>
                    <h3>Forgot Password</h3>
                    <p>If you've forgotten your password, don't worry! Simply enter your email address in the field below, and we'll send you a link to reset your password.</p>
                </>}
                {edit && <p>Enter your email address in the field below, and we'll send you a link to reset your password.</p>}
            </div>
            
            <form onSubmit={submitFormHandler} className='form-fields-container'>
                <div className="form-controller">
                    <label className="label" htmlFor="password_input">Request New Password</label>
                    <input type="email" id="email_input" placeholder="Email" ref={emailInputRef} className={`${!formInputsValidity.email ? 'error' : null}`} required/>
                    {!formInputsValidity.email && <div className='error-message'>Please enter an email!</div>}
                </div>
                <div className="form-controller">                
                    {!isLoading && <button className="btn">Send Instructions</button>}
                    {isLoading && <div className="btn"><span className="loader"></span></div>}
                </div>
            </form>
        </> }
        {passSent && <div>Instructions sent to the email you provided</div>}
    </>
}

export default ForgotPassword