import classes from './MockProductPage.module.css'
import background from '../../assets/bg-details.png'

const MockProductPage = () => {

    const MockModifiers = [{id:1}, {id:2}, {id:3}, {id:4}, {id: 5}]

    return <div className="relative">
        <div className={classes.background}>
            <img src={background} role="presentation" alt="" className="image-cover" />
        </div>
        <div className="container-full">
            <div className={classes.product_details_container}>
                {/* <PresentationBox /> */}
                <div className={classes.presentation_box}>
                    <div className={classes.page_title}></div>
                    
                    <div className={classes.description}></div>
                    
                    <div className={classes.composite_image}>
                        <div className={classes.composite_image_inner}>
                            
                        </div>
                    </div>
                </div>
                {/* <ProductOptions /> */}
                <div className={classes.product_container}>
                    <div>
                        {MockModifiers.map(mod => (
                            <div className={classes.modifier_item} key={mod.id}>   
                                <div className={`${classes.options_item_header}`}>
                                    <div className={classes.options_item_header_text}>
                                        <h3> </h3>                                
                                    </div>
                                </div>
                                <div>
                                    <div className={`${classes.options_container} grid-4`}>
                                        {MockModifiers.map(option => (
                                            <div className={classes.option} key={option.id}>
                                                <div className={classes.image_container}>
                                                    <div className={classes.image_container_inner}>
                                                    </div>
                                                </div>
                                                <div className={classes.option_title}>
                                                    <div className={classes.option_title_text}></div>
                                                    <div className={classes.option_price}></div>
                                                </div>
                                            </div>
                                        ))}
                                        
                                    </div>    
                                </div>
                            </div>
                        ))}

                    </div>

                </div>
            </div>
        </div>
    </div>
}

export default MockProductPage