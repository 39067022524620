import classes from "./CreditCardForm.module.css";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { variousActions } from "../../store/various";

import { CreditCardElements } from "@olo/pay";
import { cartActions } from "../../store/cart";




const isEmpty = (value) => value.trim() === '';
const isLength5 = (value) => value.length >= 5;


const styleObject = {
    base: {
        // fontSize: "clamp(16px, 0.9375vw, 18px)",
        fontSize: "16px",
        fontFamily: "inherit",
        color: "#584446",
        "::placeholder": {
            color: "#8a8a8a",
        },
    },
    complete: {
        color: "#242c37",
    },
    invalid: {
        color: "red",
        ":hover": {
            color: "orangered",
        },
    },
};
const options = {
    cardElementOptions: {
        style: styleObject,
    },
    mountTargets: {
        number: "#custom-card-number-selector",
        expiry: "#custom-card-expiry",
        cvv: "#custom-card-cvv",
    },
};
const cardElements = new CreditCardElements(process.env.REACT_APP_OLO_EVIRONMENT);


const CreditCardForm = () => {
    const zipInputRef = useRef()
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false)
    const [cardOk, setCardOk] = useState({
        number: false,
        cvv: false,
        date: false,
    });
    let cart = useSelector((state) => state.cart.cart);

    const [selectedPayment, setSelectedPayment] = useState([])
    // useCallback
    useEffect(() => {  //useEffect
        async function createCard() {
            await cardElements.create(options);
            cardElements.cardNumber.on("change", (event) => {

                if (event.complete) {
                    setCardOk(
                        (prevState) =>
                            (prevState = { ...prevState, number: true })
                    );
                } else if (event.error) {
                    const message = event.error.message;

                    setCardOk(
                        (prevState) =>
                            (prevState = { ...prevState, number: false })
                    );
                }
            });

            cardElements.cardExpiry.on("change", (event) => {

                if (event.complete) {
              
                    setCardOk(
                        (prevState) =>
                            (prevState = { ...prevState, expiry: true })
                    );
                } else if (event.error) {
                    const message = event.error.message;
     
                    setCardOk(
                        (prevState) =>
                            (prevState = { ...prevState, expiry: false })
                    );
                }
            });

        }
        createCard();
        if (cardOk.number && cardOk.cvv && cardOk.date) {

            const billing = cardElements.createPaymentMethod();


        }

        return () => {
            cardElements.clear();         
        }; 
        // aaa()
    }, []);

    const createB = async () => {
        setLoading(true)
        const enteredZip = zipInputRef.current.value
        if (!isEmpty(enteredZip) && isLength5(enteredZip)) {
            const billing = await cardElements.createPaymentMethod();
            if (billing.error) {
                dispatch(
                    variousActions.setError({ message: billing.error.message })
                );
            } else {
           
                billing.zipCode = enteredZip
                // setSelectedPayment(prevState => prevState = [...prevState, billing])
                setSelectedPayment([billing])

  
                dispatch(cartActions.setBilling(billing));
                // START GTM DATA
                let prodArray = []
                cart.products.forEach(element => {
                    prodArray.push({
                        item_id: element.productId,
                        item_name: element.name,
                        price: element.totalcost, // e.g. 9.99,
                        quantity: element.quantity, // e.g. 1
                    })
                });
                
                window.dataLayer = window.dataLayer || [];
                
                window.dataLayer.push({
                event: "add_payment_info",
                ecommerce: {
                    currency: "USD", // e.g. "USD",
                    value: cart.total, // e.g. 7.77,
                    coupon: cart.coupon,
                    payment_type: "credit card",
                    items: prodArray
                }
                });
                // END GTM DATA
            }
        } else {
            dispatch(variousActions.setError({message: 'Please provide a valid zip code'}))
        }
        
        setLoading(false)
    };

    return (
        <>
            {/* {cardOk && <div>can continue</div>} */}
            <div className={`form-fields-container ${classes.olo_card_form}`}>
                <div className="form-controller-group">
                    <div className="form-controller form-controller-3">
                        <label className="label" htmlFor="card_number">
                            Card Number
                        </label>
                        <div id="custom-card-number-selector"></div>
                    </div>
                    <div className="form-controller form-controller-1">
                        <label className="label" htmlFor="cvv_input">
                            CVV
                        </label>
                        <div id="custom-card-cvv"></div>
                    </div>
                </div>
                <div className="form-controller-group align-bottom">
                    <div className="form-controller form-controller-1">
                        <label className="label" htmlFor="expiry_date">
                            Expiry Date
                        </label>
                        <div id="custom-card-expiry"></div>
                    </div>
                    <div className='form-controller form-controller-1'>
                        <label className='label' htmlFor='locationZip'>Zip Code*</label>
                        <input ref={zipInputRef} type='text' id='locationZip' name='locationZip' placeholder='12345' required/>
                    </div>
                    <div className="form-controller-1">
                        {!loading && <button onClick={createB} className={`btn ${classes.set_credit_card}`}>Add Card</button>}
                        {loading && <div className={`btn ${classes.set_credit_card}`}><span className="loader"></span></div>}
                    </div>
                </div>
                
            </div>

            {/* <SelectedPayment />      */}
        </>
    );
};

export default CreditCardForm;
