import { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import classes from './MapContainer.module.css'

const MapContainer = ({children, activeState}) => {
    const nodeRef = useRef(null)

    return <CSSTransition 
        in={activeState} 
        nodeRef={nodeRef} 
        timeout={400}
        classNames="sidebar-group"
        unmountOnExit>
            <div ref={nodeRef} className={classes.container}>                
                {children}
            </div>
    </CSSTransition>
}

export default MapContainer