import { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useSelector } from "react-redux";

const MetaTagsAsync = () => {
    let pageUrl = window.location
    const location = useSelector(state => state.location.location)

    const [title, setTitle] = useState('Great American Cookies - Cookie Cake Delivery')
    const [description, setDescription] = useState("Make it the sweetest Birthday with a fresh-baked Cookie Cake! Order today. Customize your celebration! Order a Create Your Own Cookie Cake today.")

    useEffect(() => {
        if (location) {
            if (location.data && location.data.attributes && location.data.attributes.includes("MSC")) {
                setTitle(`Ice Cream, Cakes & Desserts Near Me in ${location.address.city}, ${location.address.state} - Marble Slab Creamery | Pickup & Delivery`)
                setDescription(`Looking for Ice Cream Scoops, Shakes, and Cakes? Browse all  Marble Slab Creamery locations in ${location.address.city}, ${location.address.state}, to see all of our desserts!`)
            } else if (location.data && location.data.attributes && (location.data.attributes.findIndex(element => element.includes("CNC")) >= 0)) {
                // CNC store
                setTitle(`Cookie Cakes, Ice Cream, and Cookies Near Me in ${location.address.city}, ${location.address.state} - Great American Cookies and Marble Slab Creamery | Pickup & Delivery`)
                setDescription(`Looking for Cookie Cakes, Ice Cream, and Cookies? Browse all Great American Cookies and Marble Slab Creamery locations in ${location.address.city}, ${location.address.state}, to see all of our desserts!`)
            } else {
                // default
                setTitle(`Cookie Cakes, Cookies & Desserts Near Me in ${location.address.city}, ${location.address.state} - Great American Cookies | Pickup & Delivery`)
                setDescription(`Looking for Cookie Cakes, Cookies and Double Doozies? Browse all Great American Cookies locations in ${location.address.city}, ${location.address.state}, to see all of our desserts!`)
            }
        } 
    }, [location])

    return <HelmetProvider>
        <Helmet>
            {/* <title>{location ? `${location.address.city} Great American Cookies in ${location.address.state} - Cookie Cakes and Fresh Baked Cookies` : 'Great American Cookies - Cookie Cake Delivery'}</title>
            <meta name="description" content={location ? `The best cookies in ${location.address.state}. Huge, gourmet desserts for takeout, delivery or pick-up. Made fresh daily. Unique and trendy flavors weekly.` : "Make it the sweetest Birthday with a fresh-baked Cookie Cake! Order today. Customize your celebration! Order a Create Your Own Cookie Cake today."} /> */}
            <title>{title}</title>
            <meta name="description" content={description} />
            
            <link rel="canonical" href={pageUrl} />
        </Helmet>
    </HelmetProvider>
}

export default MetaTagsAsync