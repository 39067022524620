import { createSlice } from "@reduxjs/toolkit"
import { setStorageItem } from "../scripts/localStorage"

const initialAuthState = { 
    authToken: null,
    user: null,
    isUserSidebarOpened: false,
    oloToken: null,
    termsAndConditionsPop: false
}
const authSlice = createSlice({
    name: 'authentication',
    initialState: initialAuthState,
    reducers: {
        login(state, data) {
            state.authToken = data.payload.authtoken  
            setStorageItem('tokens', data.payload)
            sessionStorage.removeItem('olotoken')
            sessionStorage.setItem('token', data.payload.authtoken); 
        },
        logout(state) {
            state.user = null
            state.authToken = null
            state.oloToken = null
            // localStorage.removeItem('token')
            // localStorage.removeItem('expiration')
            sessionStorage.removeItem('token')
            sessionStorage.removeItem('olotoken')
            localStorage.removeItem('tokens')
        },
        setUser(state, data) {
            state.user = data.payload
        },
        setUserSideBarState(state) {
            state.isUserSidebarOpened = !state.isUserSidebarOpened
        },
        oloLogin(state, data) {
            state.oloToken = data.payload.authtoken
            sessionStorage.removeItem('token')
            sessionStorage.setItem('olotoken', data.payload.authtoken);     
        },
        openTermsAndConditions(state, data) {
            state.termsAndConditionsPop = !state.termsAndConditionsPop
        }
    }
})

export const authActions = authSlice.actions

export default authSlice.reducer