import { useEffect, useRef, useState } from "react";
import Radar from "radar-sdk-js";
import "radar-sdk-js/dist/radar.css";

import classes from "./PickupPannel.module.css";
import SearchIcon from "../../icons/searchicon";

const AutocompleteComponent = ({selectedResult, loading, setError, setValue, setAddressDetails, inputError, label}) => {
    const autocompleteRef = useRef(null)

    const [radarAddress, setRadarAddress] = useState(null)

    useEffect(() => {
        Radar.initialize(process.env.REACT_APP_RADAR_MAPS, {});

        if (autocompleteRef) {
            Radar.ui.autocomplete({
                container: 'radar_autocomplete',
                countryCode: 'US',
                style: {
                    position: 'absolute',
                    backgroundColor: 'red'
                },
                onSelection: (address) => {
                    setRadarAddress(address)

                    let postal_code = false
                    let city = false
                    let street_number = false
                    let street = false

                    let addrressDetails = {
                        postal_code: '',
                        city: '',
                        street_number: '',
                        street: '',
                        full_address: ''
                    }

                    if(address.city) {
                        city = true
                        addrressDetails.city = address.city
                    }
                    if (address.postalCode) {
                        postal_code = true
                        addrressDetails.postal_code = address.postalCode
                    }
                    
                    if(address.number) {
                        street_number = true
                        addrressDetails.street_number = address.number
                    }
                    if(address.street) {
                        street = true
                        addrressDetails.street = address.street
                    }

                    if (postal_code && city && street_number && street) {
                        setError(null)
                        setValue(address.formattedAddress)
                        addrressDetails.full_address = address.formattedAddress
                        setAddressDetails(addrressDetails)
                        selectedResult(address)
                    } else {
                        setError('The address provided seems incomplete. Please ensure you enter your full address.')
                    }

                },
            });
        }       


    }, []);


    return <div className="form-fields-container">
        
        <label className={'label label-caps'} htmlFor='searchAddress'>{label ? label : 'Enter an address'}</label>
        <div className={classes.form_input_container}>
            <input type='text' /* placeholder='City, State or Zip Code' */ id="radar_autocomplete" ref={autocompleteRef} className={`${inputError ? 'error' : ''}`} required/>
            <button className={classes.btn_simple} type="button" onClick={() => {selectedResult(radarAddress)}}>
                {loading && <span className="loader"></span>}
                {!loading && <SearchIcon />}
                <span className="sr-only">Search location</span>
            </button>
        </div>
        
    </div>
}

export default AutocompleteComponent