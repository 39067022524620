import { useSelector } from 'react-redux';
import ProductModifiers from './ProductModifiers';
import AddToCart from './AddToCart';
import SpecialInstructions from './SpecialInstructions';

const ProductContainer = () => {
    const mod = useSelector(state => state.productDetails.data)

    return <>
        {mod && <>
            <ProductModifiers modifiers={mod}/>
            <SpecialInstructions />
            <AddToCart />
        </>}  
    </>
}

export default ProductContainer