import Card from '../fragments/Card'
import classes from './RewardsItem.module.css'


const RewardsItem = ({item}) => {
    return <Card direction="column_perma">
        <div className={classes.item_image}>
            <img src={item.reward_image_url} alt={item.name} className='image-contain'/>
        </div>
        <div className={classes.item_title}>{item.name}</div>
    </Card>
}

export default RewardsItem