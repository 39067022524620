import { useEffect, useState } from 'react'
import classes from './Billing.module.css'
import CreditCardForm from './CreditCardForm'
import DefaultWallets from './DigitalWallet'
import { useSelector } from 'react-redux'
import SelectedPayment from './SelectedPayment'


const Billing = ({billingMethod,}) => {
    const [isCard, setIsCard] = useState(false)
    const selectedPayment = useSelector(state => state.cart.billing)

    useEffect(() => {
        billingMethod.forEach(element => {
            if (element.name === "Credit Card") {
                setIsCard(true)
            }
        });
    }, [billingMethod])


    return <>
        
        <div className={classes.billing_header}>
            {isCard && <div>
                <button className={`btn btn-border ${classes.btn_hand} ${classes.active}`} >Credit Card</button>
            </div>}
            <DefaultWallets billingscheme={billingMethod}/>          
        </div>
        {!selectedPayment && <div className={classes.billing_body}>
            {isCard && <CreditCardForm />}
        </div>}
        {selectedPayment && <SelectedPayment />}
    </>
}

export default Billing