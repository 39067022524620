import { useEffect, useState } from "react"
import { generateTimeList } from "../../scripts/helpers"

const TimeSelect = ({handleTimeChange}) => {

    const [timeTable, setTimeTable] = useState(null)

    useEffect(() => {
        setTimeTable(generateTimeList('06:00', '23:59', false))
    }, [])

    return <select className='select' onChange={handleTimeChange} defaultValue={'Time'}>
        <option disabled value="Time" hidden>Time</option>
        {timeTable && timeTable.map(item => (
            <option key={item.label} value={item.value}>{item.label}</option>
        ))}
    </select>
}

export default TimeSelect