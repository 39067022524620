import Footer from "../components/layout/Footer"
import Header from "../components/layout/Header"

import classes from './Error.module.css'

const ErrorPage = () => {
    return <>
        <Header />
        <main>
            <div className="container-full">
                <section className={classes.error}>
                    <h1 className="text-center">Page Not Found</h1>
                    <h3 className="text-center">No cookies for you here :( !</h3>
                </section>
            </div>
        </main>
        <Footer />
    </>
}

export default ErrorPage