
import Card from '../fragments/Card'
import FavOrderDetails from './FavOrderDetails'
import classes from './FavoriteItem.module.css'
import OrderAgain from './OrderAgain'
import RemoveFavoriteOrder from './RemoveFavoriteOrder'

const FavoriteItem = ({item, removeItemHandler}) => {

 
    return <Card direction="column">
        <div className={classes.item_title}>{item.name}</div>
        <div className={classes.item_vendor}><span>Vendor: </span>{item.vendorname}</div>
        {item.products && item.products.length > 0 && <div className={classes.item_products}>
            {item.products.map(product => (
                <div key={product.productid} className={classes.product_item}>
                    <div className={classes.product_name}>{product.quantity}X{product.name}</div>
                </div>
            ))}    
        </div>}

        <div className={classes.controls}>
            <FavOrderDetails item={item}/>
            <OrderAgain item={item}/>
            <RemoveFavoriteOrder item={item} removeitem={removeItemHandler}/>
        </div>

        
    </Card>
}

export default FavoriteItem