import { useEffect, useRef, useState } from "react";
import Radar from "radar-sdk-js";
import "radar-sdk-js/dist/radar.css";

import classes from "./PickupPannel.module.css";
import SearchIcon from "../../icons/searchicon";
import CloseCircleIcon from "../../icons/closecircleicon";

const AutocompleteComponentExtra = ({selectedResult, loading, label, hasLocations}) => {
    const autocompleteRef = useRef(null)
    // const inputRef = useRef(null);
    const [radarAddress, setRadarAddress] = useState(null)
    // const [enteredText, setEnteredText] = useState(''); 

    const [showSearchButton, setShowSearchButton] = useState(true)

    useEffect(() => {
        Radar.initialize(process.env.REACT_APP_RADAR_MAPS, {});

        if (autocompleteRef) {
            Radar.ui.autocomplete({
                container: 'radar_autocomplete',
                countryCode: 'US',
                onSelection: (address) => {
                    setRadarAddress(address)
                    selectedResult(address)
                },
                style: {
                    pinIcon: {display: 'none'},
                    addressText: {
                        fontSize: 100,
                        lineHeight: 24,
                        color: '#000',
                        fontWeight: '600',
                    },
                }
            });
        }

    }, []);

    useEffect(() => {
        if (hasLocations) {
            setShowSearchButton(false)
        }
    }, [hasLocations])

    const clearResultshandler = () => {
        autocompleteRef.current.value = "";
        setShowSearchButton(true)
    }


    const searchOnClick = () => {
        // selectedResult(autocompleteRef.current.value)

        Radar.forwardGeocode({ query: autocompleteRef.current.value })
        .then((result) => {
            const { addresses } = result;
            // do something with addresses

            if (addresses && addresses.length > 0) {
                selectedResult(addresses[0])
            } else {
                selectedResult(undefined)
            }
        })
        .catch((err) => {
            // handle error
        });
    }

    return <div className="form-fields-container">
        
        <label className={'label label-caps'} htmlFor='searchAddress'>{label ? label : 'Enter an address'}</label>
        <div className={classes.form_input_container}>
            <input type='text' /* placeholder='City, State or Zip Code' */ id="radar_autocomplete"  ref={autocompleteRef}/>
            {/* <button className={classes.btn_simple} type="button" onClick={() => {selectedResult(radarAddress)}}>
                {loading && <span className="loader"></span>}
                {!loading && <SearchIcon />}
                <span className="sr-only">Search location</span>
            </button> */}
            
            {loading && <div className={classes.loader_container}>
                <span className="loader locations"></span>
            </div>}
           
            {!loading && showSearchButton && <button className={classes.btn_simple} type="button" onClick={searchOnClick}>   {/* former click action->>>> () => {selectedResult(radarAddress)}  */}
                <SearchIcon />
                <span className="sr-only">Search location</span>
            </button>}
            {!loading && !showSearchButton && <button className={classes.btn_clear} type="button" onClick={clearResultshandler}>
                <CloseCircleIcon />
                <span className="sr-only">Clear search</span>
            </button>}
        </div>
        
    </div>
}

export default AutocompleteComponentExtra