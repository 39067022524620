import { useDispatch, useSelector } from 'react-redux';
import { cartActions } from '../../store/cart';

// import { getItemFromStorage } from '../../scripts/localStorage';

import classes from './HeaderCartButton.module.css'
import OrderIcon from '../../icons/ordericon';
import { formatNumberDecimal } from '../../scripts/helpers';

const HeaderCartButton = () => {
    const dispatch = useDispatch()

    const cart = useSelector(state => state.cart.cart)

    let totalProducts = 0
    if (cart) {        
        totalProducts = cart.products ? cart.products.length : 0
    }
    let subtotal = 0
    if (cart) {
        subtotal = cart.subtotal ? cart.subtotal : 0
    }

    const openCartSidebarHandler = () => {
        dispatch(cartActions.setCartSidebarState())

        if (cart && cart.products.length > 0) {
            let prodArray = []
            cart.products.forEach(element => {
                prodArray.push({
                    item_id: element.productId,
                    item_name: element.name,
                    price: element.totalcost, // e.g. 9.99,
                    quantity: element.quantity, // e.g. 1
                })
            });
            // START GTM DATA
            window.dataLayer = window.dataLayer || [];

            
            window.dataLayer.push({
            event: "view_cart",
            ecommerce: {
                currency: "USD", // e.g. "USD",
                value: cart.total, // e.g. 7.77,
                items: prodArray
            }
            });
            // END GTM DATA
        }
    }


    


    return <button className="btn-clear" onClick={openCartSidebarHandler}>
                                
        <div className={classes.control_box}>
            <div className='relative'>
                <OrderIcon />
                <span className={classes.cart_items_number}>{totalProducts}</span>
            </div>
            <div className={classes.cart_total}>${formatNumberDecimal(subtotal)}</div>
        </div>
    </button>
}

export default HeaderCartButton