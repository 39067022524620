import ReactDOM from "react-dom";
import { useDispatch, useSelector } from 'react-redux';
import { locationActions } from '../../store/location';

import Backdrop from '../utils/Backdrop'
import Sidebar from '../utils/Sidebar';
import SearchLocation from "../locations/SearchLocation";
import MapRadar from "../locations/MapRadar";
import MapContainer from "../locations/MapContainer";
import { useMediaQuery } from '../../hooks/hooks';

const SidebarLocationContainer = () => {
    const dispatch = useDispatch()
    const isLocationSidebarOpened = useSelector(state => state.location.islocationSidebarOpened)
    const cart = useSelector(state => state.cart.cart)

    const screen900andlarger = useMediaQuery('md900'); 


    const openLocationSidebar = () => {
        dispatch(locationActions.setLocationsSideBarState())   
        if (cart) {
            if (cart.deliverymode === 'pickup' || cart.deliverymode === 'curbside') {
                dispatch(locationActions.setVirtualDeliveryType('pickup')) 
            }
            if (cart.deliverymode === 'dispatch') {
                dispatch(locationActions.setVirtualDeliveryType('delivery'))
            }
        }  else {
            dispatch(locationActions.setVirtualDeliveryType('pickup'))
        }       
        
    }

    return <>
        {isLocationSidebarOpened && <>
            {ReactDOM.createPortal(
                <Backdrop hide={openLocationSidebar} />,
                document.getElementById("backdrop-root")
            )}            
        </>}
        {ReactDOM.createPortal(
            <div>
                
                {screen900andlarger && <MapContainer activeState={isLocationSidebarOpened}>
                    <MapRadar zoom={4} center={[-87.6298, 41.8781]}/>
                </MapContainer>}
                <Sidebar click={openLocationSidebar} activeState={isLocationSidebarOpened} design={'location'}>
                    <SearchLocation closeSidebar={openLocationSidebar} />
                </Sidebar>
            </div>,
            document.getElementById("overlay-root")
        )}
    </>
}

export default SidebarLocationContainer