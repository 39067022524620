import { configureStore } from "@reduxjs/toolkit";

import counterReducer from "./counter";
import authReducer from "./auth";
import locationReducer from "./location";
import cartReducer from "./cart";
import virtualCart from "./virtualCart";

import variuosReducer from "./various"
import productChoices from "./productChoices";
import productDetails from "./productDetails";




const store = configureStore({
    reducer: {
        counter: counterReducer,
        auth: authReducer,
        location: locationReducer,
        cart: cartReducer,
        virtualCart: virtualCart,
        various: variuosReducer,
        product: productChoices,
        productDetails: productDetails
    }
});



export default store;