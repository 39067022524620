import ReactDOM from "react-dom";

// import Bannerv2 from "./Bannerv2";
import BannerSimple from "./BannerSimple";
import MockProductPage from "./MockProductPage";
import MockSuperCategories from "./MockSuperCategories";

const LoadingWrapper = ({text, productPage}) => {
    return <>
    {!productPage && <>
        <div className="overflow_container">
            {/* <Bannerv2 /> */}
            <BannerSimple />
            <MockSuperCategories />
        </div>
    </>}
    {productPage && <MockProductPage />}
    {ReactDOM.createPortal(
        <div className='preparing_restaurant'>
            <div className='preparing_restaurant_backdrop'>
                <div className='preparing_restaurant_container'>
                    <div className='preparing_restaurant_loader'></div>
                    <div className='preparing_restaurant_message'>{text}</div>
                </div>
            </div>
        </div>,
        document.getElementById("overlay-root")
    )}
    </>
}

export default LoadingWrapper