import { useSelector } from "react-redux"
import MigrateAccount from "../components/authentication/Migrate"
import Signup from "../components/authentication/Signup"
import BannerSimple from "../components/common/BannerSimple"

import classes from './Migrate.module.css'
import CompleteMigrate from "../components/authentication/CompleteMigrate"
import BannerSimpleSet from "../components/common/BannerSimpleSet"
import { useEffect, useState } from "react"
import { GetUserViaPunchMobileAPI } from "../scripts/fetchApi"
import { getItemFromSessionStorage, getItemFromStorage } from "../scripts/localStorage"

const Migrate = () => {
   
    const user = useSelector(state => state.auth.user)
    const storageTokens = getItemFromStorage('tokens')

    const [punchUser, setPunchUser] = useState(null)

    useEffect(() => {
        async function aaa() {
            if (storageTokens) {
                let user = await GetUserViaPunchMobileAPI(storageTokens.authtoken, {
                    access_token: storageTokens.access_token
                })
                
                if (user.message) {
                    setPunchUser(null)
                } else {
                    setPunchUser(user)
                }
            } else {
                setPunchUser(null)
            }
            
        }
        aaa()
    }, [])


    return <div className="overflow_container">
        <BannerSimpleSet title={'Marble Slab Creamery Rewards/ Slab Happy Rewards Account Migration'} type={'CNC'}/>
        <div className="container-full">
            <div className={classes.container}>
                {!user && <>
                    <MigrateAccount btnText={'Migrate My Account'} btnClass={'btn-large'}/>
                </>}
                {punchUser && <>
                    {punchUser.user && !punchUser.user.migrate_status && punchUser.user.verification_mode === "email" && <CompleteMigrate />} 
                    {punchUser.user && punchUser.user.migrate_status && punchUser.user.verification_mode === null && <div className={classes.min_container}><h4>Account migration is already complete</h4></div>}
                    {punchUser.user && !punchUser.user.migrate_status && punchUser.user.verification_mode === null && <div className={classes.min_container}><h4>Account migration is not posible</h4></div>}
                </>}
            </div>
        </div>
        
    </div>
}

export default Migrate