import { useState } from "react"

import classes from './Migrate.module.css'
import { submitMigration } from "../../scripts/fetchApi"
import { useDispatch, useSelector } from "react-redux"
import { variousActions } from "../../store/various"
import { getItemFromStorage } from "../../scripts/localStorage"

const MigrateToken = () => {
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)
    const [migrateComplete, setMigrateComplete] = useState(false)

    const tokens = useSelector(state => state.auth.tokens)
    const user = useSelector(state => state.auth.user)

    const storageTokens = getItemFromStorage('tokens')


    const sendToken = async (event) => {
        event.preventDefault()
        setIsLoading(true)
        const fd = new FormData(event.target)

        const formData = Object.fromEntries(fd.entries())


        if (formData && formData.token_input !== '') {
            const reqBody = {
                access_token: storageTokens.access_token,
                token : formData.token_input,
                username: user.email //email or phone
            }
            const migrate = await submitMigration(storageTokens.authtoken, reqBody)


            if (migrate.message) {
                dispatch(variousActions.setError(migrate))
            } else {
                setMigrateComplete(true)
            }
        }       


        setIsLoading(false)
    }

    return <div className={classes.min_container}>
        {!migrateComplete && <div>
            <div className={classes.text_container}>
                <p>A migration verification code was sent to your email. Please check it and add it here to complete your migration.</p>
            </div>
            <form onSubmit={sendToken} className='form-fields-container'>
                <div className="form-controller">
                    <label className="label" htmlFor="token_input">Migration verification code</label>
                    <input type="text" id="token_input" name="token_input" placeholder="Enter your migration code" required  />
                </div>

                <div className={`form-controller`}>
                    {!isLoading && <button className={`btn btn-large`} type="submit">Submit</button>}
                    {isLoading && <div className="btn"><span className="loader"></span></div>}
                </div>
            </form>
        </div>}

        {migrateComplete && <div>
            Migration Complete!    
        </div>}
    </div>
}

export default MigrateToken