import { OrderTrackerInfo } from "../scripts/fetchApi"

import { redirect } from "react-router-dom"
import { defer, useLoaderData, Await } from "react-router-dom"
import { Suspense } from "react"
import LoadingWrapper from "../components/common/LoadingWrapper"
import OrderStatusComponent from "../components/account/OrderStatusComponent"


const OrderStatus = () => {    
    const data = useLoaderData()    
    
    return <Suspense fallback={<LoadingWrapper text="Tracking Order, please wait"/>}>
        <Await resolve={data.data}>
            {() => <>
                <OrderStatusComponent />
            </>}
        </Await>
    </Suspense>
}

export default OrderStatus

async function loadData(orderId) {
    if (orderId) {      
        const item = JSON.parse(sessionStorage.getItem(orderId))

        if (item) {
            return item
        }
        const order = await OrderTrackerInfo(orderId)

        sessionStorage.setItem(order.id, JSON.stringify(order))
        return order
    } else {
        return redirect('/')
    }
}
export function loader({ request, params}) {
    const orderId = params.orderId
    
    return defer({
        data: loadData(orderId)
    })
}