import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { locationActions } from '../../store/location';
import classes from './DateTime.module.css'

import DateSelect from './DateSelect';
import TimeSelect from './TimeSelect';
import PenIcon from '../../icons/penicon';

const DateTime = ({hidelabel, smallButtons}) => {
    const [scheduled, setScheduled] = useState(false)    

    const dispatch = useDispatch()

    const handleChange = (e) => {
        
        if (e.target.value === 'schedule') {
            setScheduled(true)
        } else {
            setScheduled(false)
        }
        
        dispatch(locationActions.setOrderType(e.target.value))
    }

    let orderType = useSelector(state => state.location.order)

    const handleDateChange = (e) => {

        e.target.setAttribute(
            "data-date",
            (new Date(e.target.value)).toLocaleString('en-US', {day: "2-digit", month: "2-digit"})
        )
        dispatch(locationActions.setOrderDate(e.target.value))
    }
    const handleTimeChange = (e) => {

        dispatch(locationActions.setOrderTime(e.target.value))

        e.target.setAttribute(
            "data-time",
            (new Date(orderType.date + 'T' + e.target.value)).toLocaleTimeString('en-US', {hour: "2-digit", minute: "2-digit"})
        )
    }
    
    let date = new Date();
    date.setDate(date.getDate() + 7);
    
    

    return <>
        <div className='form-controller'>
            <fieldset>
                <legend className={`label`}>Order Time</legend>
                <div className={classes.radio_input_container}>
                    <div className={`${classes.radio_input_item} ${smallButtons ? classes.radio_input_item_small : ''}`}>
                        <input type="radio" id="orderTime_asap" name="orderTime" value="asap" defaultChecked onChange={handleChange}/>
                        <label htmlFor="orderTime_asap">Asap</label>
                    </div>
                    <div className={`${classes.radio_input_item} ${smallButtons ? classes.radio_input_item_small : ''}`}>
                        <input type="radio" id="orderTime_scheduled" name="orderTime" value="schedule" onChange={handleChange}/>
                        <label htmlFor="orderTime_scheduled">Scheduled</label>
                    </div>
                </div>
            </fieldset>
        </div>
        {scheduled && <div className={classes.scheduled_inputs}>
            <div className='form-controller-group'>
                <div className='form-controller'>
                    <label htmlFor='dateSchedule' className='label' >Select a date</label>
                    <div className={classes.relative}>
                        {/* <input id='dateSchedule' name='dateSchedule' min={today} max={interval7} type='date' onChange={handleDateChange} value={orderType.date ? orderType.date : ''} data-date={orderType.date ? (new Date(orderType.date)).toLocaleString('en-US', {day: "2-digit", month: "2-digit"}) : ''} required/> */}
                        <DateSelect handleDateChange={handleDateChange}/>
                        <span className={classes.open_drawer}>
                            {/* <CalendarIcon /> */}
                            <PenIcon />
                        </span>
                    </div>
                </div>
                <div className='form-controller'>
                    <label htmlFor='timeSchedule' className='label'>Select a time</label>
                    <div className={classes.relative}>
                        {/* <input id='timeSchedule' name='timeSchedule' type='time' onChange={handleTimeChange} value={orderType.time ? orderType.time : ''} data-time={orderType.time && orderType.date ? (new Date(orderType.date + 'T' + orderType.time)).toLocaleTimeString('en-US', {hour: "2-digit", minute: "2-digit"}) : ''} required/> */}
                        <TimeSelect handleTimeChange={handleTimeChange}/>
                        <span className={classes.open_drawer}>
                            {/* <ClockIcon fill="#fff"/> */}
                            <PenIcon />
                        </span>
                    </div>
                </div>
            </div>
        </div>}
    </>
}

export default DateTime