import { useEffect, useState } from 'react'
import classes from './DispatchOrderTracker.module.css'
import CheckMarkIcon from '../../icons/checkmarkicon'

const DispatchOrderTrackerTabs = ({status}) => {
    const [liveStatus, setLiveStatus] = useState({
        received: true,
        inProgress: false,
        out: false,
        delivered: false
    })

    useEffect(() => {
        setLiveStatus({
            received: (status === "Pending" || status === "PickupInProgress" || status === "DeliveryInProgress" || status === "Delivered") ? true : false,
            inProgress: (status === "PickupInProgress" || status === "DeliveryInProgress" || status === "Delivered") ? true : false,
            out: (status === "DeliveryInProgress" || status === "Delivered") ? true : false,
            delivered: (status === "Delivered") ? true : false,
        })
    }, [status])

    return <div className={classes.order_delivery_stages}>
        <div className={`${classes.order_delivery_stage} ${liveStatus.received && classes.stage_active}`}>
            <span>
                {!liveStatus.received && <>1</>}
                {liveStatus.received && <CheckMarkIcon />}
            </span>
            Order Received
        </div>
        <div className={`${classes.order_delivery_stage} ${liveStatus.inProgress && classes.stage_active}`}>
            <span>
                {!liveStatus.inProgress && <>1</>}
                {liveStatus.inProgress && <CheckMarkIcon />}
            </span>
            Pickup in Progress
        </div>
        <div className={`${classes.order_delivery_stage} ${liveStatus.out && classes.stage_active}`}>
            <span>
                {!liveStatus.out && <>1</>}
                {liveStatus.out && <CheckMarkIcon />}
            </span>
            Out for Delivery
        </div>
        <div className={`${classes.order_delivery_stage} ${liveStatus.delivered && classes.stage_active}`}>
            <span>
                {!liveStatus.delivered && <>1</>}
                {liveStatus.delivered && <CheckMarkIcon />}
            </span>
            Delivered
        </div>
    </div>
}

export default DispatchOrderTrackerTabs