import { useEffect, useRef, useState } from 'react';
import classes from './SpecialInstructionsBuilder.module.css'
import { useDispatch } from 'react-redux';
import { productChoicesActions } from '../../../store/productChoices';

const CustomMessage = ({text}) => {
    const dispatch = useDispatch()
    const textareaRef = useRef(null);

    const [textareaValue, setTextareaValue] = useState('')

    let typingTimer;                //timer identifier
    const doneTypingInterval = 1000;  //time in ms, 5 seconds for example

    const keyUpFunction = () => {
        clearTimeout(typingTimer);
        typingTimer = setTimeout(doneTyping, doneTypingInterval);
    }
    const keyDownFunction = () => {
        clearTimeout(typingTimer);
    }

    const doneTyping = () => {
        console.log('done typing 2000')
        dispatch(productChoicesActions.customMessageText(textareaRef.current.value))
    }

    useEffect(() => {
        setTextareaValue(text)
        dispatch(productChoicesActions.customMessageText(text))
    }, [text])


    return <div className='form-fields-container'>
    <div className={`form-controller ${classes.form_controller}`}>
        <fieldset>
            <legend className='sr-only'>Order Customization</legend>   
            <div className={classes.form_group}>
                <label htmlFor='orderCustomMessageTextareaInput' className={classes.label_title}>What do you want your Cookie Cake to say?</label>
                <textarea ref={textareaRef} onKeyUp={keyUpFunction} onKeyDown={keyDownFunction} maxLength={30} id='orderCustomMessageTextareaInput' name="orderCustomMessageTextareaInput" placeholder='Write your custom text here... up to 30 characters' defaultValue={textareaValue}></textarea>
                <span className={classes.instructions}>Please do not enter quotation marks for your cake writing.</span>
            </div>                                
        </fieldset>
    </div>
</div>
}

export default CustomMessage