import { useDispatch, useSelector } from 'react-redux'
import classes from './ConfirmAllSelections.module.css'
import { productChoicesActions } from '../../../store/productChoices'


const ConfirmAllSelections = () => {
    const dispatch = useDispatch()

    const optionsConfirmed = useSelector(state => state.product.optionsConfirmed)

    const confirmAllSelectionsHandler = (event) => {
        if (event.target.checked) {
            dispatch(productChoicesActions.confirmOptions(true))
        } else {
            dispatch(productChoicesActions.confirmOptions(false))
        }
    }

    return <div className={classes.checkbox_container}>
        <div className='form-container form-group-checkbox'>
            <input type="checkbox" id="confirmAllSelections" name="confirmAllSelections" onChange={confirmAllSelectionsHandler} value={optionsConfirmed} checked={optionsConfirmed}/>
            <label htmlFor="confirmAllSelections" className={`label-regular-font ${classes.label}`}>I confirm that I have reviewed my choices and approve these selections.</label>
        </div>
    </div>
}

export default ConfirmAllSelections