import { useEffect, useState } from 'react'
import classes from './Header.module.css'
import LocationPin from '../../icons/locationpin'
import { useDispatch } from 'react-redux'
import { locationActions } from '../../store/location'
import PickupIcon from '../../icons/pickupicon'
import DeliveryIcon from '../../icons/deliveryicon'
import { useSelector } from 'react-redux'

import AsapScheduleSelect from './AsapScheduleSelect'
import DeliverySelection from './DeliverySelection'
import { createCart } from '../../scripts/fetchApi'
import { setSessionStorageItem, setStorageItem } from '../../scripts/localStorage'
import { cartActions } from '../../store/cart'
import { variousActions } from '../../store/various'
import Curbside from '../locations/Curbside'
import PenIcon from '../../icons/penicon'

const ChangeSelections = ({closeChangeSelector}) => {
    const dispatch = useDispatch()
    const location = useSelector(state => state.location.location)
    const cart = useSelector(state => state.cart.cart)

    const [loading, setLoading] = useState(false)

    const [isCurbside, setIsCurbside] = useState(false)
    
    const [changePannel, setChangePannel] = useState({
        pickup: true,
        delivery: false
    }); 

    const changeToCurbside = (e) => {
        setIsCurbside(e)
    }

    const openLocationSelectorHandler = () => {
        dispatch(locationActions.setLocationsSideBarState())   
        closeChangeSelector() 
    }


    const pickupPannelHandler = () => {
        setChangePannel({
            pickup: true,
            delivery: false
        })
    }
    const deliveryPannelHandler = () => {
        setChangePannel({
            pickup: false,
            delivery: true
        })
    }


    const [showDeliveryAddresInput, setShowDeliveryAddresInput] = useState(false)
    const changeDeliveryAddressHandler = () => {
        setShowDeliveryAddresInput(p => p = !p)
    }

    useEffect(() => {
        // if there is no cart, create one !!! 
        const createNewCart = async () => {
            setLoading(true)
            const newCart = await createCart({restaurant_id: location.id})
            if (newCart.id) {
                // setStorageItem('currentCart', newCart)
                setSessionStorageItem('currentCart', newCart)
                dispatch(cartActions.addCart(newCart))
                
            } else {
                dispatch(variousActions.setError(newCart))
            }
            setLoading(false)
        }
        if (!cart) {
            createNewCart()
        }
        if (cart && cart.deliverymode === "curbside") {
            setIsCurbside(true)
        } 
        if (cart && cart.deliverymode === "dispatch") {
            setChangePannel({
                pickup: false,
                delivery: true
            })
        }
    }, [])

    return <>
        {!loading && <div className={classes.change_selections_container}>
            {cart && <>
                <div className={classes.change_selections_header}>
                    <LocationPin />
                    {!cart && <span>
                        <div className={classes.change_selections_title}><span className={classes.highlight}>Pickup from:</span> {location.name.replace('Great American Cookies ','')}</div>
                        <div>{location.address.street}, {location.address.city}, {location.address.state} {location.address.zip} </div>
                    </span>}
                    {cart && <>
                        {(cart.deliverymode === 'pickup') && <span>
                            <div className={classes.change_selections_title}><span className={classes.highlight}>Pickup from:</span> {location.name.replace('Great American Cookies ','')}</div>
                            <div>{location.address.street}, {location.address.city}, {location.address.state} {location.address.zip} </div>
                            <div className={classes.change_selections_button_container}>
                                <button className={`btn-underline btn-flex-5 ${classes.btn_svg_18} ${classes.switch_button}`} onClick={openLocationSelectorHandler}>
                                    Switch Pickup Location <PenIcon />
                                </button>
                            </div>
                        </span>}
                        {(cart.deliverymode === 'curbside') && <span>
                            <div className={classes.change_selections_title}><span className={classes.highlight}>Curbside Pickup from:</span> {location.name.replace('Great American Cookies ','')}</div>
                            <div>{location.address.street}, {location.address.city}, {location.address.state} {location.address.zip} </div>
                            <div className={classes.change_selections_button_container}>
                                <button className={`btn-underline btn-flex-5 ${classes.btn_svg_18} ${classes.switch_button}`} onClick={openLocationSelectorHandler}>
                                    Switch Curbside Location <PenIcon />
                                </button>
                            </div>
                        </span>}
                        {(cart.deliverymode === "dispatch") && <span>
                            <div className={classes.change_selections_title}><span className={classes.highlight}>Delivery to:</span> </div>
                            <div>{cart.deliveryaddress.streetaddress}, {cart.deliveryaddress.city}, {cart.deliveryaddress.zipcode} </div>
                            <div className={classes.change_selections_button_container}>
                                <button className={`btn-underline btn-flex-5 ${classes.btn_svg_18} ${classes.switch_button}`} onClick={changeDeliveryAddressHandler}>
                                    Change Delivery Address <PenIcon />
                                </button>
                            </div>
                        </span>}
                    </>}
                </div>   
                {location && <div className={classes.change_selections_body}>
                    <div className={classes.change_selections_main_controls}>
                        {location.services.pickup && <button className={`btn ${!changePannel.pickup ? 'btn-border' : ''}`} onClick={pickupPannelHandler}>
                            <PickupIcon />
                            <span>Pickup</span>
                        </button> }
                        {location.services.dispatch && <button className={`btn ${!changePannel.delivery ? 'btn-border' : ''}`} onClick={deliveryPannelHandler}>     
                            <DeliveryIcon />
                            <span>Delivery</span>
                        </button>}
                    </div>
                    {changePannel.pickup && <form>
                        {location.services.curbside && <Curbside curbside={changeToCurbside}/>}
                        <div className={` ${classes.change_selections_date_controls}`}>
                            <AsapScheduleSelect isCurbside={isCurbside}/>
                        </div>
                    </form>}
                    
                    {changePannel.delivery && <DeliverySelection showAddress={showDeliveryAddresInput}/>}
                </div>}
            </>}
            
        </div>}
        {loading && <div className={classes.change_selections_container}><div  className={classes.loader_container}><span className='loader dark'></span></div></div>}
    </>
}

export default ChangeSelections