import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import classes from './SubCategoriesController.module.css'

const SubCategoriesController = ({data, scrollTo, activeId}) => {
    let location = useLocation();
    
    // const [isActive, setIsActive] = useState(false)
    const clickHandler = (id) => {
        // setIsActive(true)
        scrollTo(id)
    }

    useEffect(() => {
        if (location.state && location.state.toHolidays && location.state.toHolidays === data.name) {
            scrollTo(`item-${data.id}`)
        }
    }, [location])

    // isActive ? classes.active : '', 
    return <button key={data.id} className={[classes.categories_controller_item, `item-${data.id}` === activeId ? classes.active : ''].join(' ')} onClick={clickHandler.bind(null, `item-${data.id}`)}>
        {/* <div className={classes.icon} dangerouslySetInnerHTML={{__html: data.icon}}></div> */}
        {data.image && <div className={classes.icon} >
            <img src={data.image} alt="icon"/>
        </div>}
        <div className={classes.name}>{data.name === "Create Your Own Cookie Cake" ? "Create Your Own" : data.name}</div>
    </button>
}

export default SubCategoriesController