import { useDispatch, useSelector } from 'react-redux'
import classes from './Cart.module.css'
import { useState } from 'react'

import { cartSetDeliveryMethod, validateCart } from '../../scripts/fetchApi'
import { variousActions } from '../../store/various'
import { setSessionStorageItem, setStorageItem } from '../../scripts/localStorage'
import { cartActions } from '../../store/cart'

import OrderTypeCurbsideModal from './OrderTypeCurbsideModal'
import Popup from '../common/Popup'
import { formatOLODate } from '../../scripts/helpers'
import LocationPin from '../../icons/locationpin'
import OrderTypeDispatchModalSimplified from './OrderTypeDispatchModalSimplified'
import DeliveryIcon from '../../icons/deliveryicon'
import CurbsideIcon from '../../icons/curbsideicon'

const ChangeCartDeliveryTypeButton = ({children}) => {
    const dispatch = useDispatch()
    const [showModal, setShowModal] = useState(false)

    const location = useSelector(state => state.location.location)  
    const cart = useSelector(state => state.cart.cart)

    const [pickupLoading, setPickupLoading] = useState(false)
    const [deliveryStepDispatch, setdeliveryStepDispatch] = useState(false)
    const [deliveryStepCurbside, setdeliveryStepCurbside] = useState(false)

    const validateCartHandler = async (cartId) => {

        const validCart = await validateCart(cartId)

        if (validCart.id) {
            // setStorageItem('currentCart', validCart)
            setSessionStorageItem('currentCart', validCart)
            dispatch(cartActions.addCart(validCart))
            setShowModal(false)
            // return cart;
        } else {
            dispatch(variousActions.setError(validCart))

            // return null
        }
        setPickupLoading(false)
    }

    const showModalHandler = () => {
        setShowModal(p => p = !p)
        setdeliveryStepDispatch(false)
        setdeliveryStepCurbside(false)
    }

    const closeModalhandler = () => {
        setShowModal(false)
        setdeliveryStepDispatch(false)
        setdeliveryStepCurbside(false)
    }

    const setPickupHandler = async () => {
        setPickupLoading(true)

        const deliveryCartInfo = {
            delivery_mode: 'pickup',
            time: cart.timemode === 'asap' ? 'asap' : 'schedule',
            schedule_date: cart.timewanted === null ? null : formatOLODate(cart.timewanted),
            schedule_time: cart.timewanted === null ? null : cart.timewanted.split(" ")[1]
        }


        const deliveryCart = await cartSetDeliveryMethod(cart.id, deliveryCartInfo)

        if (deliveryCart.id) {
            validateCartHandler(deliveryCart.id)
        } else {
            dispatch(variousActions.setError(deliveryCart))
            setPickupLoading(false)
        }

        
    }

    const setDeliveryHandler = () => {
        setdeliveryStepDispatch(true)
    }

    const setCurbsideHandler = () => {        
        setdeliveryStepCurbside(true)
    }

    return <>        
        {/* <div className={classes.checkout_location_date_info_cta} onClick={showModalHandler}> */}
        <button onClick={showModalHandler}>{children}</button>
        {/* </div> */}
        {showModal && <Popup closeInfoHandler={closeModalhandler} transition={showModal}>
            {!deliveryStepDispatch && !deliveryStepCurbside && <>
                <h3>Change Order Type</h3>
                <div className={classes.modal_controls}>
                    {location.services.dispatch && cart.deliverymode !== 'dispatch' && <button className='btn btn-border' onClick={setDeliveryHandler}>
                        <DeliveryIcon />
                        Delivery
                    </button>}
                    {location.services.pickup && cart.deliverymode !== 'pickup' && <>
                        {!pickupLoading && <button className='btn btn-border' onClick={setPickupHandler}>Pickup</button>}
                        {pickupLoading && <div className='btn btn-border'><span className='loader dark'></span></div>}
                    </>}                    
                    {location.services.curbside && cart.deliverymode !== 'curbside' &&<button className='btn btn-border' onClick={setCurbsideHandler}>
                        <CurbsideIcon />
                        Curbside
                    </button>} 
                </div>
            </>}
            {deliveryStepDispatch && <div>
                {/* <OrderTypeDispatchModal closeModal={closeModalhandler}/> */}
                <OrderTypeDispatchModalSimplified closeModal={closeModalhandler}/>
            </div>}   
            {deliveryStepCurbside && <div>
                <OrderTypeCurbsideModal closeModal={closeModalhandler}/>
            </div>} 
        </Popup>}
    </>
}

export default ChangeCartDeliveryTypeButton