import { useDispatch } from 'react-redux'
import ClockIconSmall from '../../icons/clockiconsmall'
import EditPenIcon from '../../icons/editpenicon'
import LocationPinSmall from '../../icons/locationpinsmall'
import StoreIcon from '../../icons/storeicon'
import classes from './CartHeader.module.css'
import { cartActions } from '../../store/cart'
import { locationActions } from '../../store/location'
import Popup from '../common/Popup'
import { useState } from 'react'
import { cartSetDeliveryMethod, setVehicle, validateCart } from '../../scripts/fetchApi'
import { setSessionStorageItem } from '../../scripts/localStorage'
import { variousActions } from '../../store/various'
import AdvanceDays from './AdvanceDays'
import { useEffect } from 'react'
import ChangeCartDeliveryTypeButton from './ChangeCartDeliveryTypeButton'

const CartHeader = ({cart, location}) => {
    const dispatch = useDispatch()
    const [showModal, setShowModal] = useState()
    const [loading, setLoading] = useState(false)
    const [advanceForm, setAdvanceForm] = useState(false)
    const [earliestDate, setEarliestDate] = useState()


    useEffect(() => {
        if (cart) {
            let trueDate = ''
            let time = ''
            let date = ''
            if (cart.timemode === "advance") {
                if (cart.timewanted) {
                    date = cart.timewanted.split(' ')
                } else {
                    date = cart.earliestreadytime.split(' ')
                }

            } else {
                date = cart.earliestreadytime.split(' ')
            }
            date.forEach((element, index) => {
                if (index === 0) {
                    const txt2 = element.slice(0, 4) + "-" + element.slice(4,6) + "-" + element.slice(6);
                    trueDate = txt2
                }
                if (index === 1) {
                    time = element
                }
                
            });
            setEarliestDate(trueDate + 'T' + time)
        }
    }, [cart])

    const showAdvanceFormHandler = () => {
        setAdvanceForm(true)
    }
    const openLocationChangeHandler = () => {
        dispatch(cartActions.setCartSidebarState())

        dispatch(locationActions.setLocationsSideBarState()) 
    }

    const showModalHandler = () => {
        setShowModal(p => p = !p)
    }

    const validateCartHandler = async (cartId, orderInfo) => {

        const validCart = await validateCart(cartId)

        if (validCart.id) {
            // setStorageItem('currentCart', validCart)
            setSessionStorageItem('currentCart', validCart)
            dispatch(cartActions.addCart(validCart))
            dispatch(locationActions.setOrderType(orderInfo.time))
            dispatch(locationActions.setOrderDate(orderInfo.schedule_date))
            dispatch(locationActions.setOrderTime(orderInfo.schedule_time))
            
            setShowModal(false)
            // return cart;
        } else {
            dispatch(variousActions.setError(validCart))
            // return null
        }
        setLoading(false)
    }

    const setAsapDeliveryHandler = async () => {
        setLoading(true)

        
        const newDateCartInfo = {            
            delivery_mode: cart.deliverymode === 'dispatch' ? 'delivery' : cart.deliverymode,
            time: 'asap',
            schedule_date: null,
            schedule_time:null
        }

        if (cart.deliverymode === 'dispatch') {
            newDateCartInfo.zip_code = cart.deliveryaddress.zipcode
            newDateCartInfo.street_address = cart.deliveryaddress.streetaddress
            newDateCartInfo.city = cart.deliveryaddress.city
            newDateCartInfo.phone_number = cart.deliveryaddress.phonenumber
            newDateCartInfo.special_instructions = cart.deliveryaddress.specialinstructions
            newDateCartInfo.building = cart.deliveryaddress.building
        }

        

        
        const deliveryCart = await cartSetDeliveryMethod(cart.id, newDateCartInfo)

        if (deliveryCart.id) {
            if (cart.deliverymode === 'curbisde') {
                const vehicleInfo = {
                    vehicle_make: cart.customfields[0].value,
                    vehicle_model: cart.customfields[1].value,
                    vehicle_color: cart.customfields[2].value,
                }
                const cartVehicle = await setVehicle(deliveryCart.id, location.id, vehicleInfo)
                if (cartVehicle.id) {
                    validateCartHandler(cartVehicle.id, newDateCartInfo)
                } else {
                    dispatch(variousActions.setError(cartVehicle))
                    setLoading(false)
                }
            } else {
                validateCartHandler(deliveryCart.id, newDateCartInfo)
            }
            
        } else {
            dispatch(variousActions.setError(deliveryCart))
            setLoading(false)
        }
    }

    return <div className={classes.cart_header}>
        {cart && <>
            <div className={classes.cart_header_item}>
                <div className={classes.label}><LocationPinSmall />
                    {cart.deliverymode === 'pickup' && <>Pickup From</>}
                    {cart.deliverymode === 'curbside' && <>Curbside From</>}
                    {cart.deliverymode === 'dispatch' && <>Delivery To</>}
                </div>
                <div className={classes.value}>
                    
                    {cart && cart.deliverymode !== 'dispatch' && <>{location.address && location.address.street }</>}
                    {cart && cart.deliverymode === 'dispatch' && <>{cart.deliveryaddress.streetaddress}, {cart.deliveryaddress.city}, {cart.deliveryaddress.zipcode}</>}
                    {!cart && <>{location.address && location.address.street }</>}

                    {/* <button onClick={openLocationChangeHandler}>
                        <EditPenIcon />
                        <span className='sr-only'>edit location</span>
                    </button> */}
                    <ChangeCartDeliveryTypeButton>
                        <EditPenIcon />
                        <span className='sr-only'>edit location</span>
                    </ChangeCartDeliveryTypeButton>
                </div>
            </div>
            <div className={classes.cart_header_item}>
                <div className={classes.label}><ClockIconSmall />{cart.deliverymode === 'dispatch' ? 'Delivery time' : 'Pickup time'}</div>
                <div className={classes.value}>
                    {(new Date(earliestDate)).toLocaleString('en-US', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                    })}
                    <button onClick={showModalHandler}>
                        <EditPenIcon />
                        <span className='sr-only'>edit time</span>
                    </button>
                </div>
            </div>
        </>}

        {!cart && <>
            <div className={classes.sidebar_header_message}>
                {location && <>
                    <StoreIcon />
                    <span className={classes.sidebar_header_message_type}>Pickup</span>
                    <span className={classes.sidebar_header_message_store_name}>{location.address.street}, {location.address.zip}, {location.address.city}</span>
                </>}
                {!location && <>Pick a location to start your order!</>}
            </div>
        </>}        
        {showModal && <Popup closeInfoHandler={showModalHandler} transition={showModal}>
            <h3>Select Date and Time</h3>
            <div className={classes.modal_controls}>
                {!loading && <button className='btn btn-border-input' onClick={setAsapDeliveryHandler}>Asap</button>}
                {loading && <div className='btn btn-border-input'><span className='loader dark'></span></div>}
                <button className={`btn btn-border-input ${advanceForm && 'btn-active'}`} onClick={showAdvanceFormHandler}>Schedule</button>
            </div>
            {advanceForm && <AdvanceDays closeModal={showModalHandler}/>}                

        </Popup>}
    </div>
}

export default CartHeader