import classes from './CartItem.module.css'

import { getItemFromStorage } from '../../scripts/localStorage'
import { useEffect, useState } from 'react'

import placeholderImage from '../../assets/GAC-placeholder.png'

const CartItemImage = ({data}) => {
    const menu = getItemFromStorage('currentMenu')
    const upsellsItemsFromStorage = getItemFromStorage('cartUpsells')
    const [imageSrc, setImageSrc] = useState(placeholderImage)
    const [hasImage, sethasImage] = useState(false)

    useEffect(() => {
        if (menu) {
            menu.menu.categories.forEach(element => {                
                if (element.products) {                    
                    if (element.products.length > 0) {
                        let foundproduct = element.products.find(item => item.id == data.productId)
                        if (foundproduct) {
                            setImageSrc(foundproduct.image_small_url)
                            sethasImage(true)
                            return
                        } 
                    }
                }

                if (element.sub_categories) {
                    if (element.sub_categories.length > 0) {
                        element.sub_categories.forEach(item => {
                            if (item.products) {                    
                                if (item.products.length > 0) {
                                    let foundproduct = item.products.find(prod => prod.id == data.productId)
                                    if (foundproduct) {
                                        setImageSrc(foundproduct.image_small_url)
                                        sethasImage(true)
                                        return
                                    } 
                                }
                            }


                            if (item.sub_categories) {
                                if (item.sub_categories.length > 0) {
                                    item.sub_categories.forEach(subItem => {
                                        if (subItem.products) {                    
                                            if (subItem.products.length > 0) {
                                                let foundproduct = subItem.products.find(prod => prod.id == data.productId)
                                                if (foundproduct) {
                                                    setImageSrc(foundproduct.image_small_url)
                                                    sethasImage(true)
                                                    return
                                                } 
                                            }
                                        }
                                    })
                                }
                            }
                        })
                    }
                }
            });
        }
        if (upsellsItemsFromStorage) {
            upsellsItemsFromStorage.forEach(group => {
                if (group.items) {
                    let foundproduct = group.items.find(item => item.id == data.productId)
                    if (foundproduct) {
                        setImageSrc(`${group.imagepath}${foundproduct.images[0].filename}`)
                        sethasImage(true)
                        return
                    } 
                }
            })
        }
    }, [])
    return <div className={classes.image_container}>
        <img src={imageSrc} alt={data.title} className={hasImage ? 'image-cover' : 'image-contain'}/>
    </div>
}

export default CartItemImage