import Category from "../components/products/Category"
import { useLoaderData, Await, useParams, useLocation, useNavigate } from "react-router-dom"
import { Suspense, useEffect } from "react"
import LoadingWrapper from "../components/common/LoadingWrapper"
import { useSelector } from "react-redux"

const CategoriesDetails = () => {
   
    const data = useLoaderData() 

    const params = useParams()
    const navigate = useNavigate();
    const location = useLocation()

    let locationStore = useSelector(state => state.location.location)

    useEffect(() => {
        if (location && location.pathname) {
            if (location.pathname.includes('/menu')) {
                navigate(`/gac/${locationStore.location_slug}/${params.categoryId}`)
            }
        }
    }, [location])

    return <Suspense fallback={<LoadingWrapper text=""/>}>
        {/* Preparing your personal restaurant! Please wait */}
        <Await resolve={data.data}>
            {() => <>                
                <Category />
            </>}        
        </Await>
    </Suspense>
}

export default CategoriesDetails