const productTitles = [
    {
        title: 'Choose Cookie Cake Border Color:',
        mobile: 'Border Color:'
    }, 
    {
        title: 'Choose Message Base Color:',
        mobile: 'Message Base Color:'
    },
    {
        title: 'Choose Message Top Color:',
        mobile: 'Message Top Color:'
    },
    {
        title: 'Choose Cookie Cake Batter:',
        mobile: 'Cookie Cake Batter:'
    },
    {
        title: 'Choose Cookie Cake Flavor:',
        mobile: 'Cookie Cake Flavor:'
    }
]

export default productTitles