import { useDispatch, useSelector } from 'react-redux'
import classes from './SelectedChoice.module.css'


const PresentationCustomFields = () => {
    const customFields = useSelector(state => state.product.customFields)
    
    return <>
        {customFields && <div className={`${classes.choice_basic} ${classes.choice_custom_fields}`}>
            <div className={classes.choice_basic_inner}>
                <div className={classes.choice_title}>{customFields.fieldName.split("_").join(" ")}</div>
                <div className={classes.field_value}>
                    {customFields.fieldValue}    
                </div>           
            </div>
        </div>}
    </>
}

export default PresentationCustomFields