import { useRef, useEffect, useState } from "react";
import { useParams } from "react-router-dom"
import SubCategoriesController from "./SubCategoriesController";
import ChevronLeftIcon from "../../icons/chevronlefticon"
import ChevronRightIcon from "../../icons/chevronrighticon"
import classes from './Category.module.css'

const SubCategoryHeaderNav = ({item, handleClickScroll, activeID, flag}) => {
    const params = useParams();
    const navRef = useRef(null)
    const containerRef = useRef(null)
    const [scrollControls, setScrollControls] = useState(false)
    const scrollLefthandler = () => {
        navRef.current.scrollLeft -= 300
    }
    const scrollRighthandler = () => {
        navRef.current.scrollLeft += 300
    }

    useEffect(() => {
    
        // const navWidth = navRef.current.offsetWidth
        const navLinks = navRef.current.getElementsByTagName("button")
        let navLinksWidth = -20
        Array.from(navLinks).forEach(element => {
            navLinksWidth += element.offsetWidth + 20
        });
        const getWidthDifference = () => {
            // console.log('getWidthDifference')
            // console.log('navLinksWidth:::: ', navLinksWidth)
            // console.log('containerRef.current.offsetWidth:::: ', containerRef.current.offsetWidth)
            if ( navLinksWidth > containerRef.current.offsetWidth) {
                setScrollControls(true)
            } else {
                setScrollControls(false)
            }
        }
        getWidthDifference()
        // window.onresize = getWidthDifference
        window.addEventListener("resize", getWidthDifference, true);
        // window.addEventListener("load", getWidthDifference, true);

        return function cleanup() {
            window.removeEventListener("resize", getWidthDifference, true);
            // window.removeEventListener("load", getWidthDifference, true);
        }
    }, [flag])
    return <div ref={containerRef} className={classes.navigation_container}>
        <div className={`${classes.categories_controller} categories_controller`} ref={navRef}>
            {item.sub_categories.filter(item => (item.products && item.products.length > 0) || (item.sub_categories && item.sub_categories.length > 0)).map(item => (
                <SubCategoriesController key={item.id} data={item} scrollTo={handleClickScroll} activeId={activeID}/>
            ))}
        </div>

        {scrollControls && <div className={classes.controls}>
            <button onClick={scrollLefthandler} className={classes.button_left}>
                <ChevronLeftIcon />
                <span className="sr-only">scroll left</span>
            </button>
            <button onClick={scrollRighthandler} className={classes.button_right}>
                <ChevronRightIcon />
                <span className="sr-only">scroll rightt</span>
            </button>
        </div>}
    </div>
}

export default SubCategoryHeaderNav