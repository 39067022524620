import { useDispatch, useSelector } from 'react-redux'
import { locationActions } from '../../store/location'
import classes from './BannerSlider.module.css'
import '@splidejs/react-splide/css';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';


// import imageCoBrand from '../../assets/GAC_MSC_Hero.webp'
import { useNavigate } from 'react-router-dom'

const cncimages =  require.context('../../assets/slider/cnc', true)

const cncSlider = [{
    image: 'HBD_Evergreen_Transparent',
    subtitle: 'Celebrate Birthdays',
    title: 'WITH A COOKIE CAKE',
    description: 'Order a Custom Birthday Cookie Cake',
    bk_color: '#f7f6f4',
    link: {
        category: 'cookie-cakes',      
    }
}, {
    image: '212GAC_MDG_Webslider-DAD',
    subtitle: 'Because Dad',
    title: 'Deserves The Best',
    description: 'Treat Dad to a Fresh-Baked Cookie Cake!',
    bk_color: '#a8d5d6',
    link: {
        category: 'cookie-cakes',
        subcategory: 'Holidays'        
    }
}, {
    image: '212GAC_MDG_Webslider-GRAD_high-res',
    subtitle: 'Celebrate',
    title: 'Sweet Success',
    description: 'with a Graduation Cookie Cake! ',
    bk_color: '#f9de8d',
    link: {
        category: 'cookie-cakes',
        subcategory: 'School & Graduation'        
    }
}]

// const nonCncSlider = [{
//     image: 'HBD_Evergreen_Transparent',
//     subtitle: 'Celebrate Birthdays',
//     title: 'WITH A COOKIE CAKE',
//     description: 'Order a Custom Birthday Cookie Cake',
//     link: {
//         category: 'cookie-cakes',      
//     }
// }, {
//     image: '212GAC_MDG_Webslider-MOM _high-res',
//     subtitle: 'Because Mom',
//     title: 'Deserves The Best',
//     description: 'Treat Mom to a Fresh-Baked Cookie Cake!',
//     link: {
//         category: 'cookie-cakes',
//         subcategory: 'Holidays'        
//     }
// }, {
//     image: '212GAC_MDG_Webslider-GRAD_high-res',
//     subtitle: 'Celebrate',
//     title: 'Sweet Success',
//     description: 'with a Graduation Cookie Cake! ',
//     link: {
//         category: 'cookie-cakes',
//         subcategory: 'School & Graduation'        
//     }
// }]

// const nonCncSlider = [{
//     image: 'HBD_Evergreen_Transparent',
//     subtitle: 'Celebrate Birthdays',
//     title: 'WITH A COOKIE CAKE',
//     description: 'Order a Custom Birthday Cookie Cake',
// }, {
//     image: '212GAC_BlueberryCookie_Webslider-Artboard-1',
//     subtitle: 'A fan favorite is back!',
//     title: 'Enjoy a Dozen Blueberry Muffin Cookies',
// }, {
//     image: '212GAC_DoubleDoozie_Webslider-reflection',
//     title: 'Double the Delicious with a Double Doozie®',
//     description: 'Pair your favorite Cookies with our Decadent Icing',
// }]



const BannerSlider = ({noLocation}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const cncimagesList = cncimages.keys().map(image => cncimages(image));

    let locationStore = useSelector(state => state.location.location)

    const openLocationSidebar = () => {
        dispatch(locationActions.setLocationsSideBarState())        
    }

    // const navigateTohandler = () => {
    //     navigate('cookie-cakes', { state: { toHolidays: 'Holidays' } })
    // }
    const navigateTohandler = (link) => {
        // navigate('cookie-cakes', { state: { toHolidays: 'Holidays' } })
        if (link.subcategory) {
            navigate('cookie-cakes', { state: { toHolidays: link.subcategory } })
        } else {
            navigate(link.category)
        }
    }
    


    return <div className={`${classes.banner} banner-slider`}>
        <div className={classes.banner_background}>
            {locationStore && locationStore.cobranded && <>
                <Splide className='splide-custom-arrows' aria-label="Rewards" hasTrack={ false } options={ {
                    rewind: false,
                    perPage: 1,
                    fade: true,
                    pagination: true,
                    gap   : '0rem',
                    arrows: false,
                } }>
                    <SplideTrack>
                        {cncSlider.map(slide => (
                            <SplideSlide key={slide.title} className={classes.banner_slide} style={{backgroundImage: `url("data:image/svg+xml,%3Csvg%20width%3D%221024%22%20height%3D%2297%22%20viewBox%3D%220%200%201024%2097%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M193.096%2070.5399C159.857%2062.6559%20120.442%2054.7719%2071.4344%2074.5489C45.5329%2085.0009%2022.7976%2085.0005%200.439148%2085H-0.00012207L0%200H29.5H1024V85C990.239%2077.7%20951.342%2068.5109%20931.464%2076.6499C880.763%2097.3559%20779.054%2098.2529%20722.237%2071.8029C693.046%2058.2269%20654.494%2069.7149%20611.965%2081.2029C571.632%2092.0939%20527.713%20102.989%20484.816%2092.5029C396.741%2070.9579%20303.841%2063.6569%20258.582%2076.7029C239.247%2082.3009%20217.89%2076.4209%20193.096%2070.5399Z%22%20fill%3D%22%23${slide.bk_color?.split('#').pop()}%22%2F%3E%0A%3Cpath%20d%3D%22M193.096%2070.5399C159.857%2062.6559%20120.442%2054.7719%2071.4344%2074.5489C45.533%2085.0009%2022.7977%2085.0005%200.439148%2085L-0.00012207%2085L0%2075C22.4999%2075%2045.3633%2074.9476%2071.4344%2064.4271C120.442%2044.6501%20159.857%2052.5342%20193.096%2060.4182C217.89%2066.2992%20239.247%2072.1791%20258.582%2066.5811C303.841%2053.5351%20396.741%2060.8361%20484.816%2082.3811C527.713%2092.8671%20571.632%2081.9721%20611.965%2071.0811C654.494%2059.5931%20693.046%2048.1052%20722.237%2061.6812C779.054%2088.1312%20880.763%2087.2341%20931.464%2066.5281C951.342%2058.3891%20990.239%2067.7%201024%2075V85C990.239%2077.7%20951.342%2068.5109%20931.464%2076.6499C880.763%2097.3559%20779.054%2098.2529%20722.237%2071.8029C693.046%2058.2269%20654.494%2069.7149%20611.965%2081.2029C571.632%2092.0939%20527.713%20102.989%20484.816%2092.5029C396.741%2070.9579%20303.841%2063.6569%20258.582%2076.7029C239.247%2082.3009%20217.89%2076.4209%20193.096%2070.5399Z%22%20fill%3D%22%23AA0061%22%2F%3E%0A%3C%2Fsvg%3E")`}}>
                                <div className={classes.banner_slide_inner} style={{backgroundColor : slide.bk_color}}>
                                    <div className='container-full'>
                                        <div className={classes.banner_inner}>
                                            <div className={classes.banner_text}>
                                                <div className={classes.banner_text_inner}>
                                                    {slide.subtitle && <div className={classes.subtitle}>{slide.subtitle}</div>}
                                                    {slide.title && <h1 className={classes.title}>{slide.title}</h1>}
                                                    {slide.description && <div className={classes.description}>{slide.description}</div>}
                                                    <div className={classes.banner_text_footer}>
                                                        {!noLocation && <button to="cookie-cakes" className='btn-fancy' onClick={navigateTohandler.bind(this, slide.link)}>Order Now</button>}
                                                        {noLocation && <button to="cookie-cakes" className='btn-fancy' onClick={openLocationSidebar}>Order Now</button>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={classes.banner_image}>
                                                <img src={cncimagesList.find(item => item.includes(slide.image))} alt='banner' className='image-contain' width={754} height={871}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SplideSlide>
                        ))}
                    </SplideTrack>
                    <div className={classes.custom_splide_pagination}>
                        <ul className="splide__pagination"></ul>
                    </div>
                    
                </Splide>
            </>}
            {(!locationStore || (locationStore && !locationStore.cobranded)) && <>
                <Splide className='splide-custom-arrows' aria-label="Rewards" hasTrack={ false } options={ {
                    rewind: false,
                    perPage: 1,
                    fade: true,
                    pagination: true,
                    gap   : '0rem',
                    arrows: false,
                } }>
                    <SplideTrack>
                        {cncSlider.map(slide => (
                            <SplideSlide key={slide.title} className={classes.banner_slide} style={{backgroundImage: `url("data:image/svg+xml,%3Csvg%20width%3D%221024%22%20height%3D%2297%22%20viewBox%3D%220%200%201024%2097%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M193.096%2070.5399C159.857%2062.6559%20120.442%2054.7719%2071.4344%2074.5489C45.5329%2085.0009%2022.7976%2085.0005%200.439148%2085H-0.00012207L0%200H29.5H1024V85C990.239%2077.7%20951.342%2068.5109%20931.464%2076.6499C880.763%2097.3559%20779.054%2098.2529%20722.237%2071.8029C693.046%2058.2269%20654.494%2069.7149%20611.965%2081.2029C571.632%2092.0939%20527.713%20102.989%20484.816%2092.5029C396.741%2070.9579%20303.841%2063.6569%20258.582%2076.7029C239.247%2082.3009%20217.89%2076.4209%20193.096%2070.5399Z%22%20fill%3D%22%23${slide.bk_color?.split('#').pop()}%22%2F%3E%0A%3Cpath%20d%3D%22M193.096%2070.5399C159.857%2062.6559%20120.442%2054.7719%2071.4344%2074.5489C45.533%2085.0009%2022.7977%2085.0005%200.439148%2085L-0.00012207%2085L0%2075C22.4999%2075%2045.3633%2074.9476%2071.4344%2064.4271C120.442%2044.6501%20159.857%2052.5342%20193.096%2060.4182C217.89%2066.2992%20239.247%2072.1791%20258.582%2066.5811C303.841%2053.5351%20396.741%2060.8361%20484.816%2082.3811C527.713%2092.8671%20571.632%2081.9721%20611.965%2071.0811C654.494%2059.5931%20693.046%2048.1052%20722.237%2061.6812C779.054%2088.1312%20880.763%2087.2341%20931.464%2066.5281C951.342%2058.3891%20990.239%2067.7%201024%2075V85C990.239%2077.7%20951.342%2068.5109%20931.464%2076.6499C880.763%2097.3559%20779.054%2098.2529%20722.237%2071.8029C693.046%2058.2269%20654.494%2069.7149%20611.965%2081.2029C571.632%2092.0939%20527.713%20102.989%20484.816%2092.5029C396.741%2070.9579%20303.841%2063.6569%20258.582%2076.7029C239.247%2082.3009%20217.89%2076.4209%20193.096%2070.5399Z%22%20fill%3D%22%23AA0061%22%2F%3E%0A%3C%2Fsvg%3E")`}}>
                                <div className={classes.banner_slide_inner} style={{backgroundColor : slide.bk_color}}>
                                    <div className='container-full'>
                                        <div className={classes.banner_inner}>
                                            <div className={classes.banner_text}>
                                                <div className={classes.banner_text_inner}>
                                                    {slide.subtitle && <div className={classes.subtitle}>{slide.subtitle}</div>}
                                                    {slide.title && <h1 className={classes.title}>{slide.title}</h1>}
                                                    {slide.description && <div className={classes.description}>{slide.description}</div>}
                                                    <div className={classes.banner_text_footer}>
                                                        {!noLocation && <button to="cookie-cakes" className='btn-fancy' onClick={navigateTohandler.bind(this, slide.link)}>Order Now</button>}
                                                        {noLocation && <button to="cookie-cakes" className='btn-fancy' onClick={openLocationSidebar}>Order Now</button>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={classes.banner_image}>
                                                <img src={cncimagesList.find(item => item.includes(slide.image))} alt='banner' className='image-contain' width={754} height={871}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SplideSlide>
                        ))}
                    </SplideTrack>
                    <div className={classes.custom_splide_pagination}>
                        <ul className="splide__pagination"></ul>
                    </div>
                    
                </Splide>
            </>}
        </div>
    </div>
}

export default BannerSlider