import { useEffect, useState } from 'react'
import classes from './DispatchOrderTracker.module.css'
import { orderDispatchStatus } from '../../scripts/fetchApi'
import { useParams } from 'react-router-dom'
import DispatchOrderTrackerTabs from './DispatchOrderTrackerTabs'

const DispatchOrderTracker = () => {
    const params = useParams()
    const [loader, setLoader] = useState(false)
    const [orderTracker, setOrderTracker] = useState(null)

    useEffect(() => {
        const getStatus = async () => {
            setLoader(true)
            if (params && params.orderId) {
                const status = await orderDispatchStatus(params.orderId)
                setOrderTracker(status[0])
            } 
            setLoader(false)
        }
        getStatus()
    }, [params])

    return <>
        {loader && <div className='loader-box'><span className='loader dark'></span></div>}
        {!loader && <>
            {orderTracker && orderTracker.status !== "Scheduled" && <DispatchOrderTrackerTabs status={orderTracker.status}/>}

            {orderTracker && orderTracker.status === "Scheduled" && <div className={classes.order_delivery_stages}>Your tracking informaiton will show up in here once the order is ready for processing</div>}
            {orderTracker && orderTracker.status === "Canceled" && <div className={classes.order_delivery_stages}>Your order has been canceled!</div>}
        </>}
    </>
}

export default DispatchOrderTracker