import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux"
import classes from './Cart.module.css'
import Signin from "../authentication/Signin";
import Signup from "../authentication/Signup"
import { useNavigate } from 'react-router-dom'
import { cartActions } from "../../store/cart";
import { getItemFromStorage } from "../../scripts/localStorage";
import { isEmail, isEmpty, formatPhone } from "../../scripts/helpers";
import { setStorageItem } from "../../scripts/localStorage";
function isMSC(location) {
    if (location) {
        if (location.data && location.data.attributes && location.data.attributes.includes("MSC")) {            
            return true
        } 
    }

    return false
}

function isRewardsNonParticipating(location) {
    if (location) {
        if (location.data && location.data.attributes && location.data.attributes.includes("Rewards Non-Participating")) {            
            return true
        } 
    }

    return false
}

const CartUserInfo = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const user = useSelector(state => state.auth.user)
    const [hideAccount, setHideAccount] = useState(false)

    const guest = getItemFromStorage('guestUser')

    let locationStore = useSelector(state => state.location.location)

    const [authType, setAuthType] = useState('guest')
    const [formInputsValidity, setFormInputsValidity] = useState({
        email: true,
        firstName: true,
        lastName: true,
        phone: true
    })

    const fNameInputRef = useRef(null)
    const lNameInputRef = useRef(null)
    const emailInputRef = useRef(null)
    const phoneInputRef = useRef(null)

    const isAuthenticated = useSelector(state => state.auth.user)

    const checkoutHandler = (event) => {
        event.preventDefault()

        const enteredFirstName = fNameInputRef.current.value
        const enteredLastName = lNameInputRef.current.value
        const enteredEmail = emailInputRef.current.value
        const enteredPhone = phoneInputRef.current.value

        const enteredEmailisValid = !isEmpty(enteredEmail) && isEmail(enteredEmail)
        const enteredFirstNameisValid = !isEmpty(enteredFirstName)
        const enteredLastNameisValid = !isEmpty(enteredLastName)
        const enteredPhoneisValid = !isEmpty(enteredPhone)

        setFormInputsValidity({
            email: enteredEmailisValid,
            firstName: enteredFirstNameisValid,
            lastName: enteredLastNameisValid,
            phone: enteredPhoneisValid
        })

        const formIsValid = enteredEmailisValid && enteredFirstNameisValid && enteredLastNameisValid && enteredPhoneisValid

        if (formIsValid) {
            setStorageItem('guestUser', {
                firstName: enteredFirstName,
                lastName: enteredLastName,
                email: enteredEmail,
                phone: enteredPhone
            })
            // advance()
            dispatch(cartActions.setCartSidebarStep("checkout"))
        } 
    }

    const switchAuthType = (e) => {
        setAuthType(e)
    } 

    const userAuthContinueHandler = () => {
        // advance()
        dispatch(cartActions.setCartSidebarStep("checkout"))
    }

    const changeInfoHandler = () => {
        dispatch(cartActions.setCartSidebarState())
        navigate('/account/edit')
    }

    const [phoneValue, setPhoneValue] = useState(guest ? guest.phone : null)
    const phoneFormatHandler = (e) => {    
        setPhoneValue(formatPhone(e.target.value))
    }

    const goToMigrate = () => {
        dispatch(cartActions.setCartSidebarState())
        navigate('/rewards-migration')
    }

    // isMSC(locationStore) || 
    useEffect(() => {
        if (isRewardsNonParticipating(locationStore)) {
            setHideAccount(true)
        } else {
            setHideAccount(false)
        }
    }, [locationStore])

    return <>
        {isAuthenticated && <>
            <h3>Account Details</h3>
            <div className={classes.user_info}><span>Name:</span>{user.name}</div>
            <div className={classes.user_info}><span>Email:</span>{user.email}</div>
            <div className={classes.user_info}><span>Phone:</span>{formatPhone(user.phone)}</div>
            <div className={classes.user_info}>
                <button onClick={changeInfoHandler} className="btn">Change Info</button>
            </div>
            <div className={classes.cart_footer}>
                <button type="submit" className="btn" onClick={userAuthContinueHandler}>Continue to Payment</button>
            </div>
        </>}
        {!isAuthenticated && <>
            <div className={classes.info_pannel_header}>
                <button className={`btn ${authType === 'guest' && classes.btn_active}`} onClick={switchAuthType.bind(this, 'guest')}>
                    Guest Checkout
                </button>
                {!hideAccount && <>
                    <button className={`btn ${authType === 'sign' && classes.btn_active}`} onClick={switchAuthType.bind(this, 'sign')}>
                        Sign In
                    </button>
                    <button className={`btn ${authType === 'signup' && classes.btn_active}`} onClick={switchAuthType.bind(this, 'signup')}>
                        Join Rewards
                    </button>
                </>}
            </div>

            {authType === 'guest' && <form onSubmit={checkoutHandler}>
                <div className={`form-fields-container ${classes.guest_form}`}>
                    <div className="form-controller-group">
                        <div className="form-controller">
                            <label className="label" htmlFor="guest_first_name">First Name</label>
                            <input ref={fNameInputRef} type="text" id="guest_first_name" placeholder="Johnathan" required className={`${!formInputsValidity.firstName ? 'error' : null}`} defaultValue={guest ? guest.firstName : null}/>
                            {!formInputsValidity.firstName && <div className='error-message'>Please enter a name!</div>}
                        </div>
                        <div className="form-controller">
                            <label className="label" htmlFor="guest_last_name">Last Name</label>
                            <input ref={lNameInputRef} type="text" id="guest_last_name" placeholder="Hale" required className={`${!formInputsValidity.lastName ? 'error' : null}`} defaultValue={guest ? guest.lastName : null}/>
                            {!formInputsValidity.lastName && <div className='error-message'>Please enter a name!</div>}
                        </div>
                    </div>
                    <div className="form-controller">
                        <label className="label" htmlFor="guest_email">Email Address</label>
                        <input ref={emailInputRef} type="text" id="guest_email" placeholder="johnathanhale@mail.com" required className={`${!formInputsValidity.email ? 'error' : null}`}  defaultValue={guest ? guest.email : null}/>
                        {!formInputsValidity.email && <div className='error-message'>Please enter a valid email!</div>}
                    </div>
                    <div className="form-controller">
                        <label className="label" htmlFor="guest_phone">Phone Number</label>
                        <input ref={phoneInputRef} type="text" id="guest_phone" placeholder="123-456-7890" required className={`${!formInputsValidity.phone ? 'error' : null}`}  onChange={phoneFormatHandler} defaultValue={phoneValue}/>
                        {!formInputsValidity.phone && <div className='error-message'>Please enter a phone number</div>}
                        {/* <label className="label" htmlFor="guest_phone">Phone Number</label>
                        <input ref={phoneInputRef} type="text" id="guest_phone" placeholder="123-456-7890" required className={`${!formInputsValidity.phone ? 'error' : null}`} maxLength="11" />
                        {!formInputsValidity.phone && <div className='error-message'>Please enter a phone number</div>} */}
                    </div>
                </div>
                <div className={classes.cart_footer}>
                    <button type="submit" className="btn">Continue to Payment</button>
                </div>
            </form>}

            {authType === 'sign' && <div>
                <div className={classes.migrate_disclaimer}>
                    <p>We've combined Great American Cookies and Marble Slab Creamery Rewards! If you have an existing Great American Cookies Rewards account, please log in below.</p>
                    <p>If you have an existing Marble Slab Creamery Rewards account, <button onClick={goToMigrate}>please visit the migration page</button> to complete the account migration process.</p>
                </div>
                <Signin position={'cart'} noTitle={true}/>    
            </div>}
            {authType === 'signup' && <>
                <div className={classes.migrate_disclaimer}>
                    <p>We've combined Great American Cookies and Marble Slab Creamery Rewards! If you have an existing Great American Cookies Rewards account, please log in below.</p>
                    <p>If you have an existing Marble Slab Creamery Rewards account, <button onClick={goToMigrate}>please visit the migration page</button> to complete the account migration process.</p>
                </div>
                <Signup position={'cart'} noTitle={true}/>
            </>}
        </>}
    </>
}

export default CartUserInfo