import { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { authActions } from "../../store/auth"
import { userLogout } from "../../scripts/fetchApi"
import { getAuthToken } from "../../scripts/auth"
import { variousActions } from "../../store/various"

import classes from './AccountLanding.module.css'
import { cartActions } from "../../store/cart"
import { useSelector } from "react-redux"

const AccountLanding = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [logoutLoading, setLogoutLoading] = useState(false)

    const user = useSelector(state => state.auth.user)
    

    const logoutHandler = async () => {  
        setLogoutLoading(true)
        const token = sessionStorage.getItem('token')
        const olotoken = sessionStorage.getItem('olotoken')
        // const logout = await userLogout(getAuthToken()) //  
        const logout = await userLogout(token ? token : olotoken)

        if (logout && logout.message) {
            dispatch(variousActions.setError({message: 'Something went wrong'}))
        } else {
            // localStorage.removeItem('currentCart')
            sessionStorage.removeItem('currentCart')
            dispatch(cartActions.addCart(null))            
            dispatch(authActions.logout())

            navigate(`/signin`);
        }
        setLogoutLoading(false)  
        
    }

    


    return <div className="container-full">
        <div className={classes.wrapper}>
            {user && <>
                <h1>Hello, {user.first_name}</h1>
                <div className={classes.wrapper_inner}>
                    <div className={classes.content}>
                        <h3>What would you like to do today?</h3>
                        <ul>
                            <li>
                                <Link to="edit">Edit Account Info</Link>
                            </li>
                            <li>
                                <Link to="favorites">Go to Favorites</Link>
                            </li>
                            <li>
                                <Link to="recent">Go to Recent Orders</Link>
                            </li>
                            <li>
                                <Link to="rewards">See my rewards</Link>
                            </li>
                            <li>
                                {logoutLoading && <div className={classes.btn_subtitute}><span className="loader dark-raspberry"></span>  <span className={classes.btn_subtitute_span}>Sign Out</span></div>}
                                {!logoutLoading && <button onClick={logoutHandler}>Sign Out</button>}
                                
                            </li>
                        </ul>
                    </div>
                    <div className={classes.sidebar}>
                        <h4>Account Info</h4>
                        <div className={classes.info_item}>
                            <span>Name: </span>
                            {user.name}
                        </div>
                        <div className={classes.info_item}>
                            <span>Email: </span>
                            {user.email}
                        </div>
                        <div className={classes.info_item}>
                            <span>Phone: </span>
                            {user.phone}
                        </div>
                    </div>
                </div>
            </>}
        </div>
    </div>
}

export default AccountLanding