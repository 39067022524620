import MapContainer from "../components/locations/MapContainer";
import MapRadar from "../components/locations/MapRadar";
import SearchLocation from "../components/locations/SearchLocation";
import { useMediaQuery } from "../hooks/hooks";

import classes from './LocationsPage.module.css'

const LocationsPage = () => {
    const screen900andlarger = useMediaQuery('md900'); 
    return <>
        <div className={classes.container}>
            <div className={classes.map_container}>
                {screen900andlarger && <MapContainer activeState={true}>
                    <MapRadar zoom={4} center={[-87.6298, 41.8781]}/>
                </MapContainer>}
            </div>
            <div className={classes.search_container}>
                <SearchLocation closeSidebar={null} />
            </div>
        </div>
    </>
}

export default LocationsPage