import { useSelector, useDispatch } from 'react-redux'
import { getItemFromStorage, deleteItemFromStorage, deleteItemFromSessionnStorage } from '../../scripts/localStorage'
import classes from './Cart.module.css'
import { cartCheckout, getBillingSchemes, cartSetDeliveryAddress, setCartReferral } from '../../scripts/fetchApi'
import { useEffect, useState } from 'react'
import Billing from './Billing'
import { virtualCartActions } from '../../store/virtualCart'
import AlternativePayment from './AlternativePayment'
import { useNavigate } from 'react-router-dom'
import { variousActions } from '../../store/various'
import { cartActions } from '../../store/cart'
import Tip from './Tip'
import { getAuthToken } from '../../scripts/auth'
import OrderDate from './OrderDate'

import ContactInformation from './ContactInformation'
import OrderDeliveryType from './OrderDeliveryType'
import OrderCarInfo from './OrderCarInfo'
import OrderLocationInfo from './OrderLocationInfo'
import NonOloPay from './NonOloPay'
import { addDataLayerCheckout, deleteCookie, getCookie, getGenericToken } from '../../scripts/helpers'


const CartCheckout = ({cartId, totalPrice}) => {
    const navigate = useNavigate()

    const dispatch = useDispatch()
    const [billingMethod, setBillingMethod] = useState([])
    const [searchingBilling, setSearchingBilling] = useState(false)
    const [isLoading, setisLoading] = useState(false)

    const guest = getItemFromStorage('guestUser')

    let cart = useSelector(state => state.cart.cart)

    // let token = getAuthToken()
    let token = getGenericToken()

    const user = useSelector(state => state.auth.user)

    let hasLocation = useSelector(state => state.location.location)

    const hasUserOptedIn = useSelector(state => state.cart.hasUserOptedIn)

    const rwg_token = getCookie("rwg_token")


    useEffect(() => {        
        
        

        if (rwg_token) {
            const referralToken = {
                rwg_token: rwg_token,                
            }
            
            const setRefferal = async () => {
                const ref = await setCartReferral(cart.id, referralToken)

            }

            setRefferal()
        }

        // get and set billing methods
        const getBilling = async () => {
            setSearchingBilling(true)
            const scheme = await getBillingSchemes(cartId)
            setBillingMethod(scheme)
            setSearchingBilling(false)
        }
        getBilling()


    }, [cartId])
    
    const billing = useSelector(state => state.cart.billing)


    const placeOrderHandler = async () => {
        setisLoading(true)
        let reqData= {}
        if (user) {  // a user!!!
            reqData = {
                // billing_method: activeBilling.type,
                billing_method: 'creditcardtoken',
                user_type: 'user',
                authtoken: token,
                first_name: user.first_name,
                last_name: user.last_name,
                email_address: user.email,
                // contact_number: user.phone,
                contact_number: user.phone.replace(/\D/g,''), 

                card_token: billing.paymentMethod.id,
                card_type: billing.paymentMethod.card.brand,
                card_number: billing.paymentMethod.card.last4,
                expiry_year: billing.paymentMethod.card.exp_year,
                expiry_month: billing.paymentMethod.card.exp_month,
                zip: billing.zipCode 
            }
        } else  { // a guest!!!
            if (guest) {
                reqData = {
                    billing_method: 'creditcardtoken',
                    user_type: 'guest',
                    // createoloaccount: hasUserOptedIn,
                    first_name: guest.firstName,
                    last_name: guest.lastName,
                    email_address: guest.email,
                    // contact_number: guest.phone,     
                    contact_number: guest.phone.replace(/\D/g,''),              
    
                    card_token: billing.paymentMethod.id,
                    card_type: billing.paymentMethod.card.brand,
                    card_number: billing.paymentMethod.card.last4,
                    expiry_year: billing.paymentMethod.card.exp_year,
                    expiry_month: billing.paymentMethod.card.exp_month,
                    zip: billing.zipCode 
                }
            } else {
                dispatch(variousActions.setError({message: 'Please check contact information'}))
            }
            
        }


        if (cart.deliverymode === "dispatch") {
          
            const deliveryCartInfo = {
                // ...cart.deliveryaddress,
                phone_number: user ? user.phone.replace(/\D/g,'') : guest.phone.replace(/\D/g,''),
                // contact_number: user ? user.phone.replace(/\D/g,'') : guest.phone.replace(/\D/g,''),
                // phonenumber: user ? user.phone : guest.phone,
                delivery_mode: 'delivery',
                time: cart.timemode,
                street_address: cart.deliveryaddress.streetaddress,
                zip_code: cart.deliveryaddress.zipcode,
                special_instructions: cart.deliveryaddress.specialinstructions,
                city: cart.deliveryaddress.city,
                building: cart.deliveryaddress.building
            } 
            // const cartSetPhone = await cartSetDeliveryMethod(cart.id, deliveryCartInfo)
            const cartSetPhone = await cartSetDeliveryAddress(cart.id, deliveryCartInfo)

            if (cartSetPhone.id) {
                const checkout = await cartCheckout(cartSetPhone.id, reqData)

                if (checkout.success === false) {
                    //failed
                    dispatch(variousActions.setError(checkout))
                } else {
                    //success

                    if (rwg_token) {
                        deleteCookie("rwg_token")
                    }
                    // delete current cart 
                    // deleteItemFromStorage('currentCart')
                    deleteItemFromSessionnStorage('currentCart')
                    dispatch(cartActions.addCart(null))
                    dispatch(cartActions.setCartSidebarState())
                    dispatch(cartActions.setBilling(null))
                    dispatch(virtualCartActions.clearVirtualCart())
                    addDataLayerCheckout(checkout, hasLocation)
                    navigate(`/order/${checkout.id}`)  
                    

                }
            } else {
                dispatch(variousActions.setError(cartSetPhone))
            }
        } else {
            const checkout = await cartCheckout(cart.id, reqData)

            if (checkout.success === false) {
                //failed
                dispatch(variousActions.setError(checkout))
            } else {
                //success


                if (rwg_token) {
                    deleteCookie("rwg_token")
                }
                // delete current cart 
                // deleteItemFromStorage('currentCart')
                deleteItemFromSessionnStorage('currentCart')
                dispatch(cartActions.addCart(null))
                dispatch(cartActions.setCartSidebarState())
                dispatch(virtualCartActions.clearVirtualCart())
                dispatch(cartActions.setBilling(null))
                addDataLayerCheckout(checkout, hasLocation)
                navigate(`/order/${checkout.id}`)

            }
        }
        
        
        setisLoading(false)

    }

    // const [testNoOLOpay, setTestNoOLOPay] = useState(false)

    return <div className={classes.cart_items}>
        <div className={classes.checkout_location_date_info}>            
            <OrderLocationInfo />
            <OrderDate />
        </div>

        {cart && cart.deliverymode === "curbside" && <div className={classes.checkout_location_date_info}>
            {/* <OrderDeliveryType /> */}
            <OrderCarInfo />            
        </div>}
        
        <ContactInformation />
        {hasLocation.payment.tip && <Tip cart={cart}/>}
        <AlternativePayment />
        {!searchingBilling && hasLocation.id !== 17199 && <div className={classes.billing_container}>
            {billingMethod && billingMethod.length > 0 &&  billingMethod.filter(item => item.supportsolopay).length > 0 && <Billing billingMethod={billingMethod}/>}
            {billingMethod && billingMethod.length <= 0 && <div>No Billing Method</div>}
        </div>}
        {billingMethod && billingMethod.length > 0 && billingMethod.filter(item => !item.supportsolopay && item.type === "creditcard").map(item => (
            <NonOloPay key={item.type}/>
        ))}
        {searchingBilling && <div className={classes.billing_loader}><span className='preparing_restaurant_loader'></span></div>}

        <div className={classes.disclaimer}>
            {/* <p>Submitting this order confirms that you accept all associated terms and conditions. Review address & order details for accuracy. Your order will be sent to Great American Cookies</p> */}
            <p>* Submitting this order confirms that you accept all associated terms and conditions. Please be advised Delivery orders are fulfilled via third party. Review address & order details for accuracy. Your order will be sent to {hasLocation.name}.</p>
        </div>
    
        {billingMethod && billingMethod.length === 0 && <div className={classes.cart_footer}>
            <div className={classes.cart_footer_loader}>
                <span className="loader dark"></span>    
            </div>
        </div>}
        {billingMethod && billingMethod.length > 0 && billingMethod.filter(item => item.supportsolopay).length > 0 && <div className={classes.cart_footer}>            
            {!isLoading && <button className="btn" onClick={placeOrderHandler} disabled={billing ? false : true}>Place order | ${totalPrice.toFixed(2)}</button>}
            {isLoading && <div className="btn"><span className="loader"></span></div>}
        </div>}
    </div>
}

export default CartCheckout

