import { useSelector } from "react-redux"
import WarningComponent from "../common/WarningComponent"

const ErrorContainer = () => {
    const errors = useSelector(state => state.various.errors)
    return <>
        {errors && errors.length > 0 && <WarningComponent  data={errors}/>}
    </>
}

export default ErrorContainer