import { Link, useParams } from "react-router-dom"
import classes from './CategoriesHeader.module.css'
import { useRef, useEffect, useState } from "react";
import ChevronLeftIcon from "../../icons/chevronlefticon"
import ChevronRightIcon from "../../icons/chevronrighticon"

const CategoriesHeaderNav = ({menu, flag}) => {
    const params = useParams();
    const navRef = useRef(null)
    const containerRef = useRef(null)
    const [scrollControls, setScrollControls] = useState(false)
    const scrollLefthandler = () => {
        navRef.current.scrollLeft -= 300
    }
    const scrollRighthandler = () => {
        navRef.current.scrollLeft += 300
    }
    
    useEffect(() => {
        const navLinks = navRef.current.getElementsByTagName("a")
        let navLinksWidth = -20
        Array.from(navLinks).forEach(element => {
            navLinksWidth += element.offsetWidth + 20
        });
        const getWidthDifference = () => {
            if ( navLinksWidth > containerRef.current.offsetWidth) {
                setScrollControls(true)
            } else {
                setScrollControls(false)
            }
        }
        getWidthDifference()
        // window.onresize = getWidthDifference
        window.addEventListener("resize", getWidthDifference, true);
        // window.addEventListener("load", getWidthDifference, true);

        return function cleanup() {
            window.removeEventListener("resize", getWidthDifference, true);
            // window.removeEventListener("load", getWidthDifference, true);
        }
    }, [flag])


    return <div ref={containerRef} className={classes.navigation_container}>
        <div className={`${classes.categories_header_inner} ${scrollControls ? classes.categories_header_inner_left : ''}`} ref={navRef}>
            {menu && menu.categories.filter(item => (item.products && item.products.length > 0) || (item.sub_categories && item.sub_categories.length > 0)).map(item => (
                <Link to={`../${item.slug}`} key={item.id} className={`${classes.item} ${item.slug === params.categoryId ? classes.active : ''} categories_header_item`}>
                    <div className={`sticky-image ${classes.image_container}`}>
                        {item.image_secondary && <img src={item.image_secondary} alt={item.title} />}
                        {!item.image_secondary && <img src={item.image} alt={item.title} />}
                    </div>
                    <div className={classes.title}>{item.name}</div>
                </Link>
            ))}
        </div>

        {scrollControls && <div className={classes.controls}>
            <button onClick={scrollLefthandler} className={classes.button_left}>
                <ChevronLeftIcon />
                <span className="sr-only">scroll left</span>
            </button>
            <button onClick={scrollRighthandler} className={classes.button_right}>
                <ChevronRightIcon />
                <span className="sr-only">scroll rightt</span>
            </button>
        </div>}
    </div>
}

export default CategoriesHeaderNav