import { createSlice } from "@reduxjs/toolkit";

const initialProductDetails = {
    data: null,
    edit: null,
    editCreate: null,
    pageReload: false,
    accordionTrigger: null
}

const productDetailsSlice = createSlice({
    name: 'productDetails',
    initialState: initialProductDetails,
    reducers: {
        createProduct(state, data) {
            state.data = data.payload
        },
        selectOption(state, data) {
            console.log('data.payload:::: ', data.payload)
            console.log('state.data:::: ', state.data)
            if (data.payload) {
                let foundIndex = state.data.findIndex(x => x.id == data.payload.id);

                if (foundIndex) {
                    console.log('found')
                    state.data[foundIndex] = data.payload
                } 
            }            
        },
        setEditOptions(state, data) {
            state.edit = data.payload
        },
        setEditOptionsCreate(state, data) {
            state.editCreate = data.payload
        },
        setpageReload(state, data) {
            // state.pageReload = !state.pageReload
            state.pageReload = data.payload
        },
        builderAccordionTriggerMark(state) {
            state.accordionTrigger = Math.random()
        }
    }
})


export const productDetailsActions = productDetailsSlice.actions

export default productDetailsSlice.reducer