import { useEffect, useState } from "react"

import classes from './LocationsHours.module.css'
import { getDayOfTheWeek } from "../../scripts/helpers"

const sorter = {
    "monday": 1,
    "tuesday": 2,
    "wednesday": 3,
    "thursday": 4,
    "friday": 5,
    "saturday": 6,
    "sunday": 7
}

function returnDayOfTheWeek(day) {
    const options = { weekday: "long" };
    const date = new Date(day);
    return new Intl.DateTimeFormat("en-US", options).format(date)
}

const LocationsHours = ({hours}) => {
    const [storeHours, setStoreHours] = useState(null)
    useEffect(() => {
        let h = []
        
        if (hours) {
            for (const [key, value] of Object.entries(hours)) {
                // console.log(`${key}: ${value}`);
             
                h.push({
                    day: key,
                    dayLong: returnDayOfTheWeek(value.start),
                    open: new Date(value.start).toLocaleTimeString('en-US', { hour: "2-digit", minute: "2-digit" }),
                    close: new Date(value.end).toLocaleTimeString('en-US', { hour: "2-digit", minute: "2-digit" })
                })
            }
            h.sort(function sortByDay(a, b) {
                let day1 = a.dayLong.toLowerCase();
                let day2 = b.dayLong.toLowerCase();
                return sorter[day1] - sorter[day2];
            });
      
            setStoreHours(h)
        }
    }, [hours])

    return <div className={classes.container}>
        {storeHours && storeHours.map(item => (
            <div key={item.day} className={`${classes.row} ${getDayOfTheWeek() === item.day ? classes.today : ''}`}>
                <div>{item.dayLong}</div>
                <div>{item.open} - {item.close}</div> 
            </div>
        ))}
    </div>
}

export default LocationsHours