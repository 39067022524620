
import { Link, useParams } from "react-router-dom"
import { useSelector } from 'react-redux';
import classes from './CategoriesHeader.module.css'
import { getItemFromStorage } from "../../scripts/localStorage";

import { useEffect, useRef, useState } from "react"
import { CSSTransition } from 'react-transition-group';
import { useSetMenu } from "../../hooks/hooks";
import CategoriesHeaderNav from "./CategoriesHeaderNav";

const CategoriesHeader = () => {
    // const menu = useSelector(state => state.location.menu)
    const menu = useSetMenu()
    const params = useParams();
    const nodeRef = useRef(null)
    const categoriesHeaderRef = useRef(null)

    const [topBar, setTopBar] = useState(false);

    

    const isSticky = (e) => {
        // const categories_header = document.getElementById('categories_header');
        const categories_header = categoriesHeaderRef.current
        const scrollTop = window.scrollY;

        if (scrollTop > categories_header.getBoundingClientRect().bottom) { 
            setTopBar(true)
        } else {
            setTopBar(false)
        }
    };   
    
    useEffect(() => {    
        window.addEventListener('scroll', isSticky);
        return () => {
          window.removeEventListener('scroll', isSticky);
        };
    }, [])


    return <>
        {menu && <>
            <div className={classes.categories_header} ref={categoriesHeaderRef}>
                <div className={`${classes.categories_header_container} categories-header-container`}>
                    <div className='container-full'>
                        <CategoriesHeaderNav menu={menu} flag={topBar}/>
                    </div>
                </div>
            </div>
            <CSSTransition 
            in={topBar} 
            nodeRef={nodeRef} 
            timeout={500}
            classNames="fixed-bar"
            unmountOnExit>
                <div className={`categories_header_fixed ${classes.categories_header_fixed}`} ref={nodeRef}>
                    <div className='container-full'>
                        <CategoriesHeaderNav menu={menu} flag={topBar}/>
                    </div>
                </div>
            </CSSTransition>
        </>}
    </>
}

export default CategoriesHeader