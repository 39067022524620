import ReactDOM from "react-dom";
import { useSelector, useDispatch } from 'react-redux';
import { cartActions } from '../../store/cart';
import Backdrop from '../utils/Backdrop'
import Sidebar from '../utils/Sidebar';
import Cart from '../cart/Cart';

const SidebarContainer = () => {
    const dispatch = useDispatch()

    const openCartSidebar = useSelector(state => state.cart.cartSidebarOpen)
    const cart = useSelector(state => state.cart.cart)

    const openCartSidebarHandler = () => {
        if (cart) {
            dispatch(cartActions.setCartSidebarStep("order"))
        }
        dispatch(cartActions.setCartSidebarState())
    }
    return <>
        {openCartSidebar && <>
            {ReactDOM.createPortal(
            <Backdrop hide={openCartSidebarHandler}/>,
                document.getElementById("backdrop-root")
            )}
        </>}
        {ReactDOM.createPortal(
            <Sidebar click={openCartSidebarHandler} activeState={openCartSidebar}>
                <Cart />
            </Sidebar>,
            document.getElementById("overlay-root")
        )}
    </>

}

export default SidebarContainer