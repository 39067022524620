import { useEffect, useState } from 'react';
import { getItemFromStorage } from '../../../scripts/localStorage';
import classes from './ProductOptions.module.css'
import { useDispatch, useSelector } from 'react-redux';
import { productDetailsActions } from '../../../store/productDetails';
import ProductContainer from './ProductContainer';
import { useLocation } from 'react-router-dom';

const removeAllSelectedItems = (arr) => {
    arr.forEach(item => {
        if (item.options) {
            item.options.forEach(option => {
                if (!option.itemSelected) {
                    if (option.modifiers) {
                        option.modifiers.forEach(mod => {
                            mod.options.forEach(suboption => {suboption.itemSelected = false})                            
                        })
                        removeAllSelectedItems(option.modifiers)
                    }
                }
            })
        }
    })
}
const selectTheFirstItem = (arr, value, skip ) => {
    arr.forEach(item => item.itemSelected = false)
    

    if (!skip) {
        arr[0] = {...arr[0], itemSelected: value}
    }
    
    if (arr[0].modifiers) {
        arr[0].modifiers.forEach(mod => {
            if (mod.mandatory) {
                selectTheFirstItem(mod.options, true)
            } else {
                selectTheFirstItem(mod.options, true, true)
            }               
        })
    }

    // need to add the option  itemSelected 
    let restOfOptions = arr.slice(1)
    restOfOptions.forEach(r => {
        if (r.modifiers) {
            r.modifiers.forEach(mod => {
                if (mod.mandatory) {
                    selectTheFirstItem(mod.options, false, false)
                } else {
                    selectTheFirstItem(mod.options, false, true)
                }               
            })
        }
    })
}

const isEditEnabled = (data, choicesToEdit) => {
    // data.forEach(item => item.itemSelected = false)
    choicesToEdit.forEach((editChoice => {
        // data.forEach(item => {
            if (data.options) {
                data.options.forEach(opt => {
                    if (opt.id === editChoice.optionid) {
                        data.itemSelected = true
                        opt.itemSelected= true
                    }
                    if (opt.modifiers) {
                        opt.modifiers.forEach(mod => {
                            isEditEnabled(mod, choicesToEdit)
                        })
                    }
                })
            }
        // })
    }))
}

const ProductOptions = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const product = getItemFromStorage('foundProduct')?.product
    const productDetails = useSelector(state => state.productDetails.data)
    const editOptions = useSelector(state => state.productDetails.edit)
    const pageReload = useSelector(state => state.productDetails.pageReload)

    const [specialinstructionsText, setSpecialinstructionsText] = useState({
        message: null,
        design: null
    })


    useEffect(() => {

        // START GTM DATA
        if  (product) {            
            // addDataLayersSingle("view_item", product)
            window.dataLayer = window.dataLayer || [];            
            window.dataLayer.push({
                event: "view_item",
                ecommerce: {
                    currency: "USD", // e.g. "USD",
                    value: product.cost, // e.g. 7.77,
                    test: `this is the view_item event - ${Math.random()}`,
                    items: [{
                        item_id: product.id, 
                        item_name: product.name, 
                        price: product.cost, // e.g. 9.99,
                        quantity: 1, // e.g. 1
                    }]
                }
            });
        }
        // END GTM DATA

        if (product && product.modifiers) {
            if (location.state && location.state.type) {
                let data = JSON.parse(JSON.stringify(product.modifiers))

                data.forEach(element => {
                    // isEditEnabled(element.options, editOptions.choices)
                    isEditEnabled(element, editOptions.choices)
                });

                if (editOptions.specialinstructions) {
                    let cM = editOptions.specialinstructions.split(" | ")[0]
                    let cD = editOptions.specialinstructions.split(" | ")[1]
                    let finalmessage = null
                    let finalDesing = null
                    
                    if (cM) {
                        let message = cM.split("Custom Message: ")[1]
                        finalmessage = message ? message : null
                    }
                    if (cD) {
                        let message = cD.split("Custom Design: ")[1]
                        finalDesing = message ? message : null
                    }

                    setSpecialinstructionsText({
                        message: finalmessage,
                        design: finalDesing
                    })
                }
                dispatch(productDetailsActions.createProduct(data))

                
            } else {
                dispatch(productDetailsActions.createProduct(null))
                let data = JSON.parse(JSON.stringify(product.modifiers))
                data.forEach(element => {
                    if (element.mandatory || element.description === "Add a soda?") {
                        selectTheFirstItem(element.options, true, true)
                    } else {
                        selectTheFirstItem(element.options, false, true)
                    }
                });
                dispatch(productDetailsActions.createProduct(data))
            }
            
        }

    }, [pageReload, location])


    return <div className={classes.product_container}>
        {product && productDetails && <ProductContainer specialinstructionsText={specialinstructionsText}/> }
    </div>
}

export default ProductOptions