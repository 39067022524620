import { useDispatch, useSelector } from "react-redux"
import { getItemFromStorage } from "../../scripts/localStorage"
import classes from './CartInfo.module.css'
import { formatPhone } from "../../scripts/helpers"
import { useNavigate } from "react-router-dom"
import { cartActions } from "../../store/cart"

const ContactInformation = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const user = useSelector(state => state.auth.user)

    const guest = getItemFromStorage('guestUser')

    const info = {
        name: user?.name,
        email: user?.email,
        phone: user?.phone
    }

    const guestInfo = {
        name: guest?.firstName + ' ' + guest?.lastName,
        email: guest?.email,
        phone: guest?.phone
    }

    const editAccountHandler = () => {
        dispatch(cartActions.setCartSidebarState())
        navigate('/account/edit')
    }

    const editInfoHandler = () => {
        dispatch(cartActions.setCartSidebarStep("info"))
    }

    return <div className={classes.card}>
        {user && <>
            <h4 className={classes.card_header}>
                Contact Information
                <button onClick={editAccountHandler} className="btn-simple">Edit account</button>
            </h4>
            <div className={classes.card_row}>{info.name}</div>
            <div className={classes.card_row}>{info.email}</div>
            <div className={classes.card_row}>{formatPhone(info.phone)}</div>
        </>}
        {!user && guest && <>
            <h4 className={classes.card_header}>
                Guest Information
                <button onClick={editInfoHandler} className="btn-simple">Edit Info</button>
            </h4>
            <div className={classes.card_row}>{guestInfo.name}</div>
            <div className={classes.card_row}>{guestInfo.email}</div>
            <div className={classes.card_row}>{formatPhone(guestInfo.phone)}</div>
        </>}
    </div>
}

export default ContactInformation