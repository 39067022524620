import { useDispatch } from "react-redux";
import { locationActions } from "../../store/location";

const LocationWrapper = ({children, data}) => {
    const dispatch = useDispatch();
    

    const locationMouseEnterHandler = () => {
        dispatch(locationActions.setMapSelectedLocation({
            id: data.id,
            scroll: false
        }))
    }

    

    return <div onMouseEnter={locationMouseEnterHandler}>{children}</div>
}

export default LocationWrapper