import classes from './MockAccount.module.css'

const MockAccount = () => {
    return <div className='container-full'>
        <div className={classes.container}>
            <div className={classes.mock_title}></div>
            <div className={classes.mock_content}>
                <div className={classes.container_left}></div>
                <div className={classes.container_right}></div>
            </div>
        </div>
    </div>
}

export default MockAccount