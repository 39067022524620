import { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"

import classes from './Location.module.css'

const LocationWrapperInner = ({children, data}) => {
    const elementRef = useRef(null)
    const [activeClass, setActiveClass] = useState(false)

    const activelocation = useSelector(state => state.location.mapSelectedLocation) 

    useEffect(() => {
        
        if (activelocation && elementRef) {

            // if (activelocation.id !== data.id) {
            //     setActiveClass(false)
            // }
            
            if (activelocation.id === data.id) {
                setActiveClass(true)     
               
                if (activelocation.scroll) {
                    elementRef.current.scrollIntoView({behavior: "smooth", block: "center"})
                }
                
            } else {
                setActiveClass(false)
            }

            
        }

        
    }, [activelocation, data.id, activeClass])


    return <div ref={elementRef} className={`${classes.location} ${activeClass ? classes.location_active : classes.location_inactive}`} >
        {children}
    </div>
}

export default LocationWrapperInner