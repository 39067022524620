import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { formatNumberDecimal } from '../../../scripts/helpers';
import { getItemFromStorage } from '../../../scripts/localStorage';


function getSelectedOptions(data) {
    const selectedOptions =[]
    function getSelectedOptions(data, flag) {
        data.forEach(element => {
            element.options.forEach(option => {
                if (option.itemSelected) {
                    selectedOptions.push(option)                    
                }
                if (option.modifiers) {
                    getSelectedOptions(option.modifiers)
                }
            })
        });
    }

    getSelectedOptions(data)

    return selectedOptions
}
const TotalPrice = ({productQuantity}) => {
    const [totalPrice, setTotalPrice] = useState(0)
    const product = getItemFromStorage('foundProduct').product
    const productDetails = useSelector(state => state.productDetails.data)

    useEffect(() => {
        const choices = getSelectedOptions(productDetails)

        let price = 0
        choices.forEach(choice => {
            price = price + choice.cost
        })
        setTotalPrice(price + product.cost)
    }, [productDetails])

    return <div>${formatNumberDecimal(productQuantity*totalPrice)}</div>
}

export default TotalPrice