import classes from './PresentationBoxMobile.module.css'
import { getItemFromStorage } from '../../../scripts/localStorage'
import { useSelector } from 'react-redux'
import BuilderImage from './BuilderImage'
import { useEffect, useState } from 'react'
import BuilderImageNoCanvas from './builder_image/BuilderImageNoCanvas'
// import BuilerImageWrapper from './BuilerImageWrapper'


function getSelectedOptions(arr, finalArr) {
    
    if (arr) {
        arr.forEach(element => {
            if (element.itemSelected) {
                // console.log('element.itemSelected:::: ', element)
                const index = finalArr.findIndex(object => {
                    return object.id == element.id
                })
                if (index === -1) {
                    finalArr.push({
                        modName: element.description,
                        id: element.id,
                        selectedoptions: element.options?.filter(item => item.itemSelected)
                    })
                }
                
                if (element.options) {
                    element.options.forEach(opt => {                        
                        if (opt.modifiers) {
                            getSelectedOptions(opt.modifiers, finalArr)
                        }
                    })
                }
            } else {
                // console.log('element.itemSelected:::: ', element)
                if (element.options) {
                    element.options.forEach(opt => {
                        if (opt.itemSelected) {
                            const index = finalArr.findIndex(object => {
                                return object.id == element.id
                            })

                            if (index === -1) {
                                finalArr.push({
                                    modName: element.description,
                                    id: element.id,
                                    selectedoptions: element.options?.filter(item => item.itemSelected)
                                })
                            }
                            if (opt.modifiers) {
                                getSelectedOptions(opt.modifiers, finalArr)
                            }
                        } 
                    })
                }
            }
        })
    }
}


const PresentationBoxMobile = () => {
    const product = getItemFromStorage('foundProduct')?.product

    const options = useSelector(state => state.product.product)  
    const [selectedOptions, setSelectedOptions] = useState(null)

    const productDetails = useSelector(state => state.productDetails.data)
    
    useEffect(() => {
        let setOptions = []
        getSelectedOptions(productDetails, setOptions)
        setSelectedOptions(setOptions)
    }, [productDetails])

    // const [texttttttttttttttt, setTextttttttttt] = useState(null)
    // const setCanvasText = (e) => {
    //     setTextttttttttt(e)
    // }

    return <div className={`${classes.presentation_box} presentation-box-create`}>
        {product && <>    
            {product.image_large_url && <div className={classes.composite_image}>
                {!product.name.toLowerCase().includes("cookie") && <div className={classes.composite_image_inner}>
                    <img src={product.image_large_url} alt={product.name}/>
                </div>}
                {/* {product.name.toLowerCase().includes("cookie") && <BuilderImage options={options} selectedOptions={selectedOptions}/>}                 */}
                {product.name.toLowerCase().includes("cookie") && <BuilderImageNoCanvas options={options} selectedOptions={selectedOptions}/>}
                {/* {product.name.toLowerCase().includes("cookie") && <BuilerImageWrapper selectedOptions={selectedOptions} setCanvasText={setCanvasText}>
                    <BuilderImage options={options} selectedOptions={selectedOptions} t={texttttttttttttttt}/>    
                </BuilerImageWrapper>} */}
            </div>}             
        </>}
    </div>
}

export default PresentationBoxMobile