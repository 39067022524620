import { useState, useEffect } from "react"
import AddressInput from "../locations/AddressInput"
import SearchIcon from "../../icons/searchicon"
import { getLocationsByLatLng } from "../../scripts/fetchApi"
import { useDispatch, useSelector } from "react-redux"
import { variousActions } from "../../store/various"

import classes from './DeliverySelection.module.css'

const DeliverySetAddress = ({selectedDate, selectedTime, setShowDetails, setSearchedLoc}) => {
    const dispatch = useDispatch()

    const location = useSelector(state => state.location.location) 
    const [noLocation, setNoLocation] = useState(false)

    const [inputValue, setInputValue] = useState('')
    const [searchedLocations, setSearchedLocation] = useState('')
    const [loading, setLoading] = useState(false)
    const [scheduled, setScheduled] = useState(false) 

    const [foundLocations, setFoundLocations] = useState(null)
    const [locationBlocked, setLocationBlocked] = useState(false)

    let orderType = useSelector(state => state.location.order)

    // const [selectedDate, setSelectedDate] = useState(null)
    // const [selectedTime, setSelectedTime] = useState(null)

    const [inputError, setInputError] = useState({
        hasErrors: false,
        errorMessage: null,
    })

    const setErrorAddress = (data) => {

        if (data) {
            setInputError({
                hasErrors: true,
                errorMessage: data,
                disableButton: true
            })      
        } else {
            setInputError({
                hasErrors: false,
                errorMessage: null,
                disableButton: false
            })
        }
    }


    const [isButtonDisabled, setIsButtonDisabled] = useState(true)
    useEffect(() => {
        if (selectedDate && selectedTime && !inputError.disableButton) {
            setIsButtonDisabled(false)
        } else {
            setIsButtonDisabled(true)
        }  
 
    }, [selectedDate, selectedTime, inputError.disableButton])

 
    const selectedResultHandler = async (address) => {
        setLoading(true)

        // setSearchedLocation(searchedLocations)
        const loc = await getLocationsByLatLng({
            lat: address.latitude,
            lng: address.longitude
        }, 'pickup')

        if (loc.message) {
            dispatch(variousActions.setError({message: 'Delivery currently not available to this address, please place a pickup order or try another location'}))
            setShowDetails(false)
            setNoLocation(true)
            setFoundLocations(null)
        } else {
            const addressIsInRange = loc.find(item => item.id === location.id)


            if (addressIsInRange) {
                setShowDetails(true)
                    
            } else {
                setShowDetails(false)
                dispatch(variousActions.setError({message: 'Delivery currently not available to this address, please place a pickup order or try another location'}))
                setLoading(false)
            }

            setFoundLocations(loc)
            setNoLocation(false)
        }

        setLoading(false)
    }
    useEffect(() => {
        if (orderType.type === 'schedule') {

            if (orderType.date !== null && orderType.time !== null) {
                setLocationBlocked(false)
            } else {
                setLocationBlocked(true)
            }
            
        } else {
            setLocationBlocked(false)
        }
    }, [orderType]) 

    return <>
        <div className={`form-controller ${locationBlocked && 'invalid'}`}>
            {/* <label className='label' htmlFor='delivery_address'>Enter your address</label>   */}
            <div className={classes.form_input_container}>
                <AddressInput selectedResult={selectedResultHandler} loading={loading} foundLocations={(loc) => {setFoundLocations(loc)}} inputError={inputError.hasErrors} setError={setErrorAddress} setValue={(value) => {setInputValue(value)}} setAddressDetails={(data) => {setSearchedLoc(data)}}/>
        
                {/* <div className={classes.btn_container}>
                    {!loading && <>
                        {!scheduled && <button type="submit" className={classes.btn} disabled={inputError.disableButton} onClick={submitFormHandler}>
                            <SearchIcon />
                            <span className='sr-only'>Search location</span>    
                        </button>}
                        {scheduled && <button type="submit" className={classes.btn} disabled={isButtonDisabled} onClick={submitFormHandler}>
                            <SearchIcon />
                            <span className='sr-only'>Search location</span>    
                        </button>}
                    </>}
                    {loading && <div className={classes.btn}><span className="loader"></span></div>}
                </div> */}
            </div>                  
            
        </div>
        {inputError.hasErrors && <div className='error-message'>{inputError.errorMessage}</div>}   
    </>
}

export default DeliverySetAddress