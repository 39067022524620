import { createSlice } from "@reduxjs/toolkit";

const initialVariousState = {
    isInfoContainerOpen: false,   
    infoContainer: null, 
    errors: []
}

const variousSlice = createSlice({
    name: 'various',
    initialState: initialVariousState,
    reducers: {
        setIsInfoContainerOpened(state) {
            state.isInfoContainerOpen = !state.isInfoContainerOpen
        },
        setIsInfoContainer(state, data) {
            state.infoContainer = data.payload
        },
        setError(state, data) {
            let dataBody = data.payload
            dataBody.id = parseInt(Math.random()*10000000)
            state.errors.push(data.payload)
        },
        removeError(state, data) {
            const index = state.errors.findIndex(object => {
                return object.id == data.payload.id
            })
            state.errors.splice(index, 1)
        }
    }
})

export const variousActions = variousSlice.actions

export default variousSlice.reducer