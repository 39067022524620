import CartItem from "./CartItem"
import CartUpsells from "./CartUpsells"
import classes from './Cart.module.css'
import { cartSetDeliveryMethod, validateCart } from "../../scripts/fetchApi"
import { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { variousActions } from "../../store/various"
import { formatNumberDecimal, formatOLODate, formatOLOTime, formatOLOTimePLusMinutes } from "../../scripts/helpers"
import { cartActions } from "../../store/cart"
import { setSessionStorageItem } from "../../scripts/localStorage"

const ActiveCart = ({items, cartId, totalPrice}) => {
    const dispatch = useDispatch()
    const [isLoading, setisLoading] = useState(false)

    let cart = useSelector(state => state.cart.cart)

    let locationStore = useSelector(state => state.location.location)

    const validateCartHere = async (cart) => {
        const validate = await validateCart(cart.id)  
        if (validate.id) {
            // advance()
            dispatch(cartActions.addCart(validate))
            
        } else {
            console.log('error::::: ', validate)
        }
    }

    useEffect(() => {
        validateCartHere(cart)
    }, [])
    
    const infoHandler = async () => {

        if (locationStore) {

            setisLoading(true)  
            
            
            if (cart.timemode ==="advance" && !cart.timewanted) { // if there is a scheduled delivery but no time, it means that the desired time is in the past or before the order is ready
                const deliveryCartInfo = {
                    delivery_mode: cart.deliverymode,
                    time: 'asap',
                    schedule_date:  null,
                    schedule_time: null
                }
                if (!locationStore?.status?.open) {
                    // deliveryCartInfo.time = 'schedule'
                    deliveryCartInfo.time = 'schedule'
                    deliveryCartInfo.schedule_date = formatOLODate(cart.earliestreadytime)
                    deliveryCartInfo.schedule_time = formatOLOTime(cart.earliestreadytime)
                    // deliveryCartInfo.schedule_date = formatOLODate(formatOLOTimePLusMinutes(cart.earliestreadytime, 60))
                    // deliveryCartInfo.schedule_time = formatOLOTime(formatOLOTimePLusMinutes(cart.earliestreadytime, 60))
                } else {
                    const todaysDate = new Date()
                    const inputDate = new Date(formatOLODate(cart.earliestreadytime) + 'T' + formatOLOTime(cart.earliestreadytime))
                    if(inputDate.setHours(0,0,0,0) == todaysDate.setHours(0,0,0,0)) {
                        // Date equals today's date
                    } else {
                        deliveryCartInfo.time = 'schedule'
                        // deliveryCartInfo.schedule_date = formatOLODate(cart.earliestreadytime)
                        // deliveryCartInfo.schedule_time = formatOLOTime(cart.earliestreadytime)
                        deliveryCartInfo.schedule_date = formatOLODate(formatOLOTimePLusMinutes(cart.earliestreadytime, 60))
                        deliveryCartInfo.schedule_time = formatOLOTime(formatOLOTimePLusMinutes(cart.earliestreadytime, 60))
                    }
                }
                const cartMethod = await cartSetDeliveryMethod(cart.id, deliveryCartInfo)    
                if (cartMethod.id) {
                    setSessionStorageItem('currentCart', cartMethod)
                    dispatch(cartActions.addCart(cartMethod))

                    const validate = await validateCart(cartMethod.id)   
                    if (validate.id) {
                        // advance()
                        dispatch(cartActions.addCart(validate))
                        dispatch(cartActions.setCartSidebarStep("info"))
                    } else {
                        dispatch(variousActions.setError(validate)) 
                    }
                    setisLoading(false)
                } else {
                    dispatch(variousActions.setError(cartMethod)) 
                    setisLoading(false)
                }     
            } else if (cart.timemode ==="asap" && !locationStore.status.open) { // timemode is asap but the location is closed
                const deliveryCartInfo = {
                    delivery_mode: cart.deliverymode,
                    time: 'asap',
                    schedule_date:  null,
                    schedule_time: null
                }
                if (!cart?.status?.open) {
                    deliveryCartInfo.time = 'schedule'
                    deliveryCartInfo.schedule_date = formatOLODate(cart.earliestreadytime)
                    deliveryCartInfo.schedule_time = formatOLOTime(cart.earliestreadytime)
                }
                const cartMethod = await cartSetDeliveryMethod(cart.id, deliveryCartInfo)    
                if (cartMethod.id) {
                    setSessionStorageItem('currentCart', cartMethod)
                    dispatch(cartActions.addCart(cartMethod))

                    const validate = await validateCart(cartMethod.id)       

                    if (validate.id) {
                        // advance()
                        dispatch(cartActions.addCart(validate))
                        dispatch(cartActions.setCartSidebarStep("info"))
                    } else {
                        dispatch(variousActions.setError(validate)) 
                    }
                    setisLoading(false)
                } else {
                    dispatch(variousActions.setError(cartMethod)) 
                    setisLoading(false)
                } 
            } else {
                const validate = await validateCart(cartId)       

                if (validate.id) {
                    // advance()
                    dispatch(cartActions.addCart(validate))
                    dispatch(cartActions.setCartSidebarStep("info"))
                } else {
                    dispatch(variousActions.setError(validate)) 
                }
                setisLoading(false)
            }

            // START GTM DATA
            let prodArray = []
            cart.products.forEach(element => {
                prodArray.push({
                    item_id: element.productId,
                    item_name: element.name,
                    price: element.totalcost, // e.g. 9.99,
                    quantity: element.quantity, // e.g. 1
                })
            });
            
            window.dataLayer = window.dataLayer || [];

            
            window.dataLayer.push({
            event: "begin_checkout",
            ecommerce: {
                currency: "USD", // e.g. "USD",
                value: cart.total, // e.g. 7.77,
                items: prodArray
            }
            });
            // END GTM DATA

            
        } else { //no location
            dispatch(variousActions.setError({ message: 'Please set a location in order to continue'}))
        }
        
    }
    

    
    return <>
        {items.map(item => (
            <CartItem key={item.id} data={item} />
        ))}

        <CartUpsells cartId={cartId}/>

        <div className={classes.order_total_container}>
            <div className={classes.order_total_header}>
                <h3>Order total</h3>
                <span>${formatNumberDecimal(cart.total)}</span>
            </div>
            <div className={classes.order_total_body}>
                <div className={classes.order_total_item}>
                    <span>Subtotal</span>
                    <span>${formatNumberDecimal(cart.subtotal)}</span>
                </div>
                <div className={classes.order_total_item}>
                    <span>Taxes</span>
                    <span>${formatNumberDecimal(cart.taxes[0].tax)}</span>
                </div>
                {cart.customerhandoffcharge !== 0 &&<div className={classes.order_total_item}>
                    <span>Customer Handoff Charge</span>
                    <span>${formatNumberDecimal(cart.customerhandoffcharge)}</span>
                </div>}
                {cart.tip !== 0 &&<div className={classes.order_total_item}>
                    <span>Tip</span>
                    <span>${formatNumberDecimal(cart.tip)}</span>
                </div>}
                {cart.discount !== 0 &&<div className={classes.order_total_item}>
                    <span>Discount</span>
                    <span>-${formatNumberDecimal(cart.discount)}</span>
                </div>}
            </div>
        </div>

        <div className={classes.cart_footer}>
            {!isLoading && <button className="btn" onClick={infoHandler}>Checkout | ${formatNumberDecimal(totalPrice)}</button>}
            {isLoading && <div className="btn"><span className="loader"></span></div>}
        </div>
    </>
}

export default ActiveCart