import { useEffect, useRef, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import classes from './Cart.module.css'

import AdvanceDays from './AdvanceDays'

import { cartSetDeliveryMethod, validateCart, setVehicle } from '../../scripts/fetchApi'
import { variousActions } from '../../store/various'
import { setSessionStorageItem, setStorageItem } from '../../scripts/localStorage'
import { cartActions } from '../../store/cart'
import { locationActions } from '../../store/location'
import Popup from '../common/Popup'
import ClockIcon from '../../icons/clockicon'



const OrderDate = (s) => {
    const dispatch = useDispatch()
    const [showModal, setShowModal] = useState()
    const [loading, setLoading] = useState(false)
    const [earliestDate, setEarliestDate] = useState()
    const cart = useSelector(state => state.cart.cart)
    const location = useSelector(state => state.location.location) 
    useEffect(() => {

        let trueDate = ''
        let time = ''
        let date = ''
        if (cart.timemode === "advance") {
            date = cart.timewanted.split(' ')
        } else {
            date = cart.earliestreadytime.split(' ')
        }
        date.forEach((element, index) => {
            if (index === 0) {
                const txt2 = element.slice(0, 4) + "-" + element.slice(4,6) + "-" + element.slice(6);
                trueDate = txt2
            }
            if (index === 1) {
                time = element
            }
            
        });
        setEarliestDate(trueDate + 'T' + time)
    }, [cart])

    const showModalHandler = () => {
        setShowModal(p => p = !p)
    }



    const validateCartHandler = async (cartId, orderInfo) => {

        const validCart = await validateCart(cartId)

        if (validCart.id) {
            // setStorageItem('currentCart', validCart)
            setSessionStorageItem('currentCart', validCart)
            dispatch(cartActions.addCart(validCart))
            dispatch(locationActions.setOrderType(orderInfo.time))
            dispatch(locationActions.setOrderDate(orderInfo.schedule_date))
            dispatch(locationActions.setOrderTime(orderInfo.schedule_time))
            
            setShowModal(false)
            // return cart;
        } else {
            dispatch(variousActions.setError(validCart))
            // return null
        }
        setLoading(false)
    }

    const [advanceForm, setAdvanceForm] = useState(false)
    const showAdvanceFormHandler = () => {
        setAdvanceForm(true)
    }

    const setAsapDeliveryHandler = async () => {
        setLoading(true)

        
        const newDateCartInfo = {            
            delivery_mode: cart.deliverymode === 'dispatch' ? 'delivery' : cart.deliverymode,
            time: 'asap',
            schedule_date: null,
            schedule_time:null
        }

        if (cart.deliverymode === 'dispatch') {
            newDateCartInfo.zip_code = cart.deliveryaddress.zipcode
            newDateCartInfo.street_address = cart.deliveryaddress.streetaddress
            newDateCartInfo.city = cart.deliveryaddress.city
            newDateCartInfo.phone_number = cart.deliveryaddress.phonenumber
            newDateCartInfo.special_instructions = cart.deliveryaddress.specialinstructions
            newDateCartInfo.building = cart.deliveryaddress.building
        }

        

        
        const deliveryCart = await cartSetDeliveryMethod(cart.id, newDateCartInfo)

        if (deliveryCart.id) {
            if (cart.deliverymode === 'curbisde') {
                const vehicleInfo = {
                    vehicle_make: cart.customfields[0].value,
                    vehicle_model: cart.customfields[1].value,
                    vehicle_color: cart.customfields[2].value,
                }
                const cartVehicle = await setVehicle(deliveryCart.id, location.id, vehicleInfo)
                if (cartVehicle.id) {
                    validateCartHandler(cartVehicle.id, newDateCartInfo)
                } else {
                    dispatch(variousActions.setError(cartVehicle))
                    setLoading(false)
                }
            } else {
                validateCartHandler(deliveryCart.id, newDateCartInfo)
            }
            
        } else {
            dispatch(variousActions.setError(deliveryCart))
            setLoading(false)
        }
    }

    const nodeRef = useRef(null)

    return <>
        {location && location.services.advance && <div className={classes.checkout_date_info}>
            <div className={classes.checkout_location_date_info_title}>
                <ClockIcon dimensions={{width: 16, height: 16}}/>
                {cart.deliverymode === 'dispatch' ? 'Delivery time' : 'Pickup time'}
                
            </div>
            <div className={`${classes.checkout_location_date_info_more} ${classes.max_width_container}`}>                
                {(new Date(earliestDate)).toLocaleString('en-US', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                })}
            </div>
            <div className={classes.checkout_location_date_info_cta}>
                <button onClick={showModalHandler}>Change Time</button>
            </div>     
            {showModal && <Popup closeInfoHandler={showModalHandler} transition={showModal}>
                <h3>Select Date and Time</h3>
                <div className={classes.modal_controls}>
                    {!loading && <button className='btn btn-border-input' onClick={setAsapDeliveryHandler}>Asap</button>}
                    {loading && <div className='btn btn-border-input'><span className='loader dark'></span></div>}
                    <button className={`btn btn-border-input ${advanceForm && 'btn-active'}`} onClick={showAdvanceFormHandler}>Schedule</button>
                </div>
                {advanceForm && <AdvanceDays closeModal={showModalHandler}/>}                

            </Popup>}
        </div>}
    </>
}

export default OrderDate