import { Suspense, useEffect } from "react"
import LoadingWrapper from "../components/common/LoadingWrapper"
import { useLoaderData, Await, useParams, useNavigate, useLocation } from "react-router-dom"
import classes from './CreateCookie.module.css'

import background from '../assets/bg-details.png'

// import PresentationBoxCreate from "../components/products/builder/PresentationBox"
// import ProductOptionsCreate from "../components/products/builder/ProductOptionsCreate"

import PresentationBoxCreate from "../components/products/builder_v2/PresentationBox"
import ProductOptions from "../components/products/builder_v2/ProductOptions"
import PresentationBoxMobile from "../components/products/builder_v2/PresentationBoxMobile"


import PageWrapper from "../components/common/PageWrapper"
import { useDispatch } from "react-redux"
import { productChoicesActions } from "../store/productChoices"
import { useMediaQuery } from "../hooks/hooks"
import { useSelector } from "react-redux"
import { Link } from 'react-router-dom';
import CircleArrowLeft from "../icons/circlearrowleft"
import { locationHomeUrl } from "../scripts/helpers"

const CreateCookie = () => {
    const dispatch = useDispatch()
    const data = useLoaderData()
    const screen640andlarger = useMediaQuery('sm')
    dispatch(productChoicesActions.setOpenedoption(0))
    if (window.google_tag_manager) {
        window.google_tag_manager[`${process.env.REACT_APP_GTM}`].dataLayer.reset()
    }

    let locationStore = useSelector(state => state.location.location)


    const params = useParams()
    const navigate = useNavigate();
    const location = useLocation()

    useEffect(() => {
        
        if (location && location.pathname) {
            if (location.pathname.includes('/menu')) {
                navigate(`/gac/${locationStore.location_slug}/product/${params.productSlug}`)
            }
        }
    }, [location])

    return <>
        <Suspense fallback={<LoadingWrapper text="" productPage={true}/>}>
        {/* Baking Cookies and making icecream... */}
            <Await resolve={data.data}>
                {() => <>
                    <PageWrapper>
                        <div className="relative">
                            <div className={classes.background}>
                                <img src={background} role="presentation" alt="" className="image-cover" />
                            </div>
                            <div className={`container-full ${classes.container}`}>
                                {locationStore && <div className={classes.back_to_menu_container}>
                                    <Link to={locationHomeUrl(locationStore)}>
                                        <CircleArrowLeft /> 
                                        Back To Menu
                                    </Link>
                                </div>}
                                <div className={classes.product_details_container}>
                                    {screen640andlarger && <PresentationBoxCreate />}
                                    {!screen640andlarger && <PresentationBoxMobile />}
                                    {/* <ProductOptionsCreate /> */}
                                    <ProductOptions />
                                    {/* <PresentationBoxCreate /> 
                                    <ProductOptionsCreate /> */}
                                </div>
                            </div>
                        </div>
                    </PageWrapper>
                </>}
            </Await>
        </Suspense>
        
    </>
}

export default CreateCookie