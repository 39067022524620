import { useDispatch, useSelector } from 'react-redux'
import { useRef, useState } from 'react'

import { formatOLODate, isEmpty } from '../../scripts/helpers'
import { setVehicle, validateCart, cartSetDeliveryMethod } from '../../scripts/fetchApi'
import { variousActions } from '../../store/various'
import { setSessionStorageItem, setStorageItem } from '../../scripts/localStorage'
import { cartActions } from '../../store/cart'


import classes from './Cart.module.css'

const OrderTypeCurbsideModal = ({closeModal}) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const cart = useSelector(state => state.cart.cart)
    const location = useSelector(state => state.location.location)
  
    const vehicleMakeRef = useRef()
    const vehicleModelRef = useRef()
    const vehicleColorRef = useRef()

    const [formInputsValidity, setFormInputsValidity] = useState({
        make: true,
        model: true,
        color: true
    })

    const validateCartHandler = async (cartId, vehicleInfo) => {

        const validCart = await validateCart(cartId)

        if (validCart.id) {
            // setStorageItem('currentCart', validCart)
            setSessionStorageItem('currentCart', validCart)
            dispatch(cartActions.addCart(validCart))
            dispatch(cartActions.setVehicleInfo(vehicleInfo))
            closeModal()
            // return cart;
        } else {
            dispatch(variousActions.setError(validCart))
            // return null
        }
    }

    const changeVehicleHandler = async (event) => {
        event.preventDefault()
        setLoading(true)

        const enteredMake = vehicleMakeRef.current.value
        const enteredModel = vehicleModelRef.current.value
        const enteredColor = vehicleColorRef.current.value

        const enteredMakeIsValid = !isEmpty(enteredMake)
        const enteredModelIsValid = !isEmpty(enteredModel)
        const enteredColorIsValid = !isEmpty(enteredColor)

        setFormInputsValidity({
            make: enteredMakeIsValid,
            model: enteredModelIsValid,
            color: enteredColorIsValid
        })

        const formIsValid = enteredMakeIsValid && enteredModelIsValid && enteredColorIsValid

        if (formIsValid) {

            
            const vehicle = {
                vehicle_make: enteredMake,
                vehicle_model: enteredModel,
                vehicle_color: enteredColor,
            }

            localStorage.setItem('carInfo', JSON.stringify(vehicle))

            const cartVehicle = await setVehicle(cart.id, location.id, vehicle)

            if (cartVehicle.id) {  //success

                
                const deliveryCartInfo = {
                    delivery_mode: 'curbside',
                    time: cartVehicle.timemode === "advance" ? 'schedule' : cartVehicle.timemode,
                    schedule_date: cartVehicle.timewanted === null ? null : formatOLODate(cartVehicle.timewanted),
                    schedule_time: cartVehicle.timewanted === null ? null : cartVehicle.timewanted.split(" ")[1]
                }
        
                const deliveryCart = await cartSetDeliveryMethod(cart.id, deliveryCartInfo)
        
                if (deliveryCart.id) {
                    validateCartHandler(cartVehicle.id, {
                        vehicleMake: enteredMake,
                        vehicleModel: enteredModel,
                        vehicleColor: enteredColor   
                    })
                } else {
                    dispatch(variousActions.setError(deliveryCart))

                }
                
            } else {
                dispatch(variousActions.setError(cartVehicle))
            }
        }
        setLoading(false)
    }
    return <>
        <h3>Vehicle details</h3>
        <form onSubmit={changeVehicleHandler} className="form-fields-container">
            <div className={`form-controller-group ${classes.mobile_flex_wrap}`}>
                <div className="form-controller">
                    <label className='label' htmlFor='car_make'>Vehicle Make</label>
                    <input ref={vehicleMakeRef} type='text' id='car_make' name='car_make' placeholder='e.g. Chevrolet, Toyota' className={`${!formInputsValidity.make ? 'error' : null}`} required />
                    {!formInputsValidity.make && <div className='error-message'>Please enter car make</div>}
                </div>
                <div className="form-controller">
                    <label className='label' htmlFor='car_model'>Vehicle Model</label>
                    <input ref={vehicleModelRef}type='text' id='car_model' name='car_model' placeholder='e.g. Silverado' className={`${!formInputsValidity.model ? 'error' : null}`} required />
                    {!formInputsValidity.model && <div className='error-message'>Please enter car model</div>}
                </div>
                <div className="form-controller">
                    <label className='label' htmlFor='car_color'>Vehicle Color</label>
                    <input ref={vehicleColorRef}type='text' id='car_color' name='car_color' placeholder='e.g. Red, Blue' className={`${!formInputsValidity.color ? 'error' : null}`} required />
                    {!formInputsValidity.color && <div className='error-message'>Please enter car color</div>}
                </div>
            </div>
            <div className={`form-controller ${classes.modal_footer}`}>
                {!loading && <button type='submit' className='btn'>Set Vehicle Info</button>}
                {loading && <div className="btn"><span className="loader"></span></div>}
            </div>
        </form>
    </>
}

export default OrderTypeCurbsideModal