import { useDispatch, useSelector } from 'react-redux'
import classes from './Cart.module.css'
import { useRef, useState } from 'react'

import { isEmpty } from '../../scripts/helpers'
import { setVehicle, validateCart } from '../../scripts/fetchApi'
import { variousActions } from '../../store/various'
import { setSessionStorageItem, setStorageItem } from '../../scripts/localStorage'
import { cartActions } from '../../store/cart'
import Popup from '../common/Popup'
import CurbsideIcon from '../../icons/curbsideicon'

const OrderCarInfo = () => {
    const dispatch = useDispatch()
    const [showModal, setShowModal] = useState()
    const [loading, setLoading] = useState(false)
    const cart = useSelector(state => state.cart.cart)
    const location = useSelector(state => state.location.location)


    const vehicleMakeRef = useRef()
    const vehicleModelRef = useRef()
    const vehicleColorRef = useRef()

    const [formInputsValidity, setFormInputsValidity] = useState({
        make: true,
        model: true,
        color: true
    })

    const showModalHandler = () => {
        setShowModal(p => p = !p)
    }

    const validateCartHandler = async (cartId, vehicleInfo) => {

        const validCart = await validateCart(cartId)

        if (validCart.id) {
            // setStorageItem('currentCart', validCart)
            setSessionStorageItem('currentCart', validCart)
            dispatch(cartActions.addCart(validCart))
            dispatch(cartActions.setVehicleInfo(vehicleInfo))
            setShowModal(false)
            // return cart;
        } else {
            dispatch(variousActions.setError(validCart))
            // return null
        }
    }

    const changeVehicleHandler = async (event) => {
        event.preventDefault()
        setLoading(true)

        const enteredMake = vehicleMakeRef.current.value
        const enteredModel = vehicleModelRef.current.value
        const enteredColor = vehicleColorRef.current.value

        const enteredMakeIsValid = !isEmpty(enteredMake)
        const enteredModelIsValid = !isEmpty(enteredModel)
        const enteredColorIsValid = !isEmpty(enteredColor)

        setFormInputsValidity({
            make: enteredMakeIsValid,
            model: enteredModelIsValid,
            color: enteredColorIsValid
        })

        const formIsValid = enteredMakeIsValid && enteredModelIsValid && enteredColorIsValid

        if (formIsValid) {
            const vehicle = {
                vehicle_make: enteredMake,
                vehicle_model: enteredModel,
                vehicle_color: enteredColor,
            }

            localStorage.setItem('carInfo', JSON.stringify(vehicle))

            const cartVehicle = await setVehicle(cart.id, location.id, vehicle)

            if (cartVehicle.id) {  //success
                validateCartHandler(cartVehicle.id, {
                    vehicleMake: enteredMake,
                    vehicleModel: enteredModel,
                    vehicleColor: enteredColor   
                })
            } else {
                dispatch(variousActions.setError(cartVehicle))
            }
        }
        setLoading(false)
    }
    
    return <>
        {cart.deliverymode === 'curbside' && <div className={classes.checkout_location_info}>
            <div className={classes.checkout_location_date_info_title}>
                <CurbsideIcon />
                Vehicle
            </div>
            <div className={classes.checkout_location_date_info_more}>
                {/* {cart.customfields[0].value}, {cart.customfields[1].value}, {cart.customfields[2].value} */}
                {cart.customfields && cart.customfields.map(customField => {
                    if (customField.label === 'Make') {
                        return customField.value
                    }
                })}
                {cart.customfields && cart.customfields.map(customField => {
                    if (customField.label === 'Model') {
                        return ' ' + customField.value + ', '
                    }
                })} 
                {cart.customfields && cart.customfields.map(customField => {
                    if (customField.label === 'Color') {
                        return customField.value
                    }
                })}
                {/* {carInfo && <>
                    {carInfo.vehicleMake}, {carInfo.vehicleModel}, {carInfo.vehicleColor}
                </>} */}
            </div>
            <div className={classes.checkout_location_date_info_cta}>
                <button onClick={showModalHandler}>Change Vehicle</button>
            </div>
            {showModal && <Popup closeInfoHandler={showModalHandler} transition={showModal}>
                <h3>Change vehicle informations</h3>
                <form onSubmit={changeVehicleHandler} className="form-fields-container">
                    <div className={`form-controller-group ${classes.mobile_flex_wrap}`}>
                        <div className="form-controller">
                            <label className='label' htmlFor='car_make'>Vehicle Make</label>
                            <input ref={vehicleMakeRef} type='text' id='car_make' name='car_make' placeholder='e.g. Chevrolet, Toyota' className={`${!formInputsValidity.make ? 'error' : null}`} required />
                            {!formInputsValidity.make && <div className='error-message'>Please enter car make</div>}
                        </div>
                        <div className="form-controller">
                            <label className='label' htmlFor='car_model'>Vehicle Model</label>
                            <input ref={vehicleModelRef}type='text' id='car_model' name='car_model' placeholder='e.g. Silverado' className={`${!formInputsValidity.model ? 'error' : null}`} required />
                            {!formInputsValidity.model && <div className='error-message'>Please enter car model</div>}
                        </div>
                        <div className="form-controller">
                            <label className='label' htmlFor='car_color'>Vehicle Color</label>
                            <input ref={vehicleColorRef}type='text' id='car_color' name='car_color' placeholder='e.g. Red, Blue' className={`${!formInputsValidity.color ? 'error' : null}`} required />
                            {!formInputsValidity.model && <div className='error-message'>Please enter car model</div>}
                        </div>
                    </div>
                    <div className={`form-controller ${classes.modal_footer}`}>
                        {!loading && <button className="btn" type='submit'>Set Vehicle Info</button>}
                        {loading && <div className="btn"><span className="loader"></span></div>}
                    </div>
                </form>
            </Popup>}
        </div>}
    </>
}

export default OrderCarInfo